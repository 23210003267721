import React, { useEffect, useState } from 'react'
import 'materialize-css';
import './style.css';

const MobileNumber = (props) => {
  //const [isDirty, setIsDirty] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({countryCode: "+1", mobileNumber: ""})

  // const handleChange = (e) => {
  //   const key = e.target.name;
  //   const val = e.target.value;
    
  //   if(key.indexOf('.') === -1){
  //     setMobileNumber({ ...mobileNumber, [key]: val });
  //   }
  //   else{
  //     let setter = key.split('.')[0]
  //     let name = key.split('.')[1]
    
  //     if(setter==="zipcodes"){
        
  //       setMobileNumber({...mobileNumber, [name]: val });
  //     }
  //   }
  // }

  // const handleOnBlur = async (e) =>{
  //   const key = e.target.name;
  //   const val = e.target.value;
    
  //   if(key.indexOf('.') === -1){
      
  //   }else{
  //     let setter = key.split('.')[0]
  //     let name = key.split('.')[1]

  //     if(setter==="zipcodes" && name==="contact_zip"){
        
  //     }
  //   }

  // }


  // function isNumberKey(evt) {
  //   var theEvent = evt || window.event;
  //   var key = theEvent.keyCode || theEvent.which;
  //   setIsDirty(true);
  //   if ((key < 48 || key > 57) && !(key === 8 || key === 9 || key === 13 || key === 37 || key === 39 || key === 46) ){
  //       theEvent.returnValue = false;
  //   if (theEvent.preventDefault) theEvent.preventDefault();
  //   }
  // }

  useEffect(() => {

    setMobileNumber({...mobileNumber, countryCode:props.countryCode, mobileNumber:props.mobileNumber});

  },[])

  const flagClick = (e) =>{

  }

  return (
    <div className="main">
      
        <label>Mobile Number</label>
        <div className="container1">
          
        <div>
          <img className = "flag" src="https://www.countryflags.io/US/flat/64.png" onClick={(e)=>{flagClick(e)}}></img>
        </div>
        
        <div>
        <input value="Test value" noLayout={true}/>
        </div>

        </div>
        
       
        <div className="gfg"> 
        <img className="first-txt flag" src="https://www.countryflags.io/US/flat/64.png" /> 
        <input value="         +1 Test value" noLayout={true} className="second-txt"/>
        </div> 
                
    </div>
  )
}
export default MobileNumber
