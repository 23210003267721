import 'materialize-css';
import "../../css/materialize.css";
import { Modal,Row,Col,Preloader } from 'react-materialize';
import hygeialogo from '../../assets/imgs/logo_64.png';
import React,{useEffect,useState} from "react";

const ShowLoader = (props) => {
  const [show, setShow] = useState(false);

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  
  useEffect(()=>{

    if(props.show === true){
      setShow(true);
    }else{
      delay(1000).then(() => 
        setShow(false)
      );
    }

  },[props.show]);

  //
  return (
      <Modal
        bottomSheet={false}
        actions={null}
        id={props.id}
        open={show}
        options={{
          dismissible: false,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
      >
        <Row>
          <Col s={12} m={12}>
            <Preloader
              active
              color="green"
              flashing={false}
            />
          </Col>
        </Row>
        <Row>
          <Col s={12} m={12} className="center-align">
            {props.message}
          </Col>
        </Row>

      </Modal>
  )
}

export default ShowLoader

