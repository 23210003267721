
import React, { useEffect, useContext, useState } from 'react'
import clientimg from '../../assets/imgs/virtual.jpg';
import medicalimg from '../../assets/imgs/medical_record.jpg';
import labresultsimg from '../../assets/imgs/lab_results.jpg';
import homeimg from '../../assets/imgs/home.jpg';
import 'materialize-css';
import './style.css';
import AppContext from '../../AppContext';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import _ from 'lodash';


const ClientWaitingRoom = () => {
    const context = useContext(AppContext);
    const history = useHistory();

    const [patientSessions, setPatientSessions] = useState([]);

    // DataStore.configure({
    //   syncExpressions: [syncExpression(SessionQueues, s => s.client_id('eq', context.clientInfo.id))]
    // })
    
    // const querySongs = useCallback(async () => { 
    //   setPatientSessions(await DataStore.query(SessionQueues))
    // }, [])

    useEffect(()=>{
      console.log("PATIENT sessions", patientSessions);
    },[patientSessions]);

    useEffect(()=>{
      console.log(context.clientInfo.id);
     
      const getInitialData = async() =>{
          let sessions = await API.graphql(graphqlOperation(queries.querySessionQueuesByClientId, {filter: {status: {ne: "end"}}, client_id: context.clientInfo.id}));
          setPatientSessions(sessions.data.querySessionQueuesByClientId.items);
      }
      
      getInitialData();
      

    },[])

    const getData = async (p_item) =>{
      const location = {
        pathname: `/chime/${p_item.client_id}/${p_item.id}/${p_item.meeting_id}`,
          state: { from: '/client'}
        }
      history.push(location);
    }

    const goto = async(p_path)=>{


      const location = {
        pathname: p_path,
        state: { from: '/mainclient'}
      }
      history.push(location);

    }

  return (
    <React.Fragment>
      <div className="row">
      
          <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={clientimg}/>
            </div>
            <div className="card-content">
              <p className="font1"><a href="#" className="green-text" onClick = {()=> goto('/client')}>Go to meeting room</a></p> 
            </div>
          
          </div>
          </div>
          <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={medicalimg}/>
            </div>
            <div className="card-content">
              <p className="font1"><a href="#" className="green-text" onClick = {()=> goto('/meddetails')}>Medical Records</a></p> 
            </div>
          
          </div>
          </div>
          
          <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={labresultsimg}/>
            </div>
            <div className="card-content">
              <p className="font1"><a href="#" className="green-text" onClick = {()=> alert("under construction")}>Lab Results</a></p> 
            </div>
          
          </div>
          </div>


          <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={homeimg}/>
            </div>
            <div className="card-content">
              <p className="font1"><a href="#" className="green-text" onClick = {()=> goto('/')}>Home</a></p> 
            </div>
          
          </div>
          </div>

          <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={homeimg}/>
            </div>
            <div className="card-content">
              <p className="font1"><a href="#" className="green-text" onClick = {()=> goto('/')}>Home</a></p> 
            </div>
          
          </div>
          </div>
      </div>
    </React.Fragment>
  )
}

export default ClientWaitingRoom
