import React,{ useState, useEffect, useContext } from 'react';
import 'materialize-css';

import logo from '../../assets/imgs/logo_64.png';

import { Modal, Preloader, Select } from 'react-materialize';
import './style.css';
import {initialLoginDataState, checkZip1} from '../../utils/initialstate.js';
import usa_state_codes from '../../utils/usa_state_codes.json';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import M from 'materialize-css';
//import Cryptr from 'cryptr';

import * as mutations from '../../graphql/mutations';
import AppContext from '../../AppContext'
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { storeItem, getItem } from '../../utils/initialstate';

import _ from 'lodash';

import {
  useStripe,
  useElements,PaymentRequestButtonElement, 
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';


// const CARD_OPTIONS ={
//   iconStyle: "solid",
//   style:{

//     base:{
//       iconColor: "#c4f0ff",
//       color: "#fff",
//       fontWeight: 500,
//       fontFamily: "Roboto, OPen Sans, Segoe UI, sans-serif",
//       fontSize: "16px",
//       fontSmoothing: "antialiased",
//       ":webkit-autofill": { color: "fce883"},
//       "::placeholder": {color: "#87bbfd"}
//     },invalid:{
//       iconColor: "#ffc7ee",
//       color: "#ffc7ee"
//     }

//   }
// }

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const Payment = (props) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [buttonLabel, setButtonLabel] = useState("Cancel");
  const [payButtonDisable, setPayButtonDisable] = useState(false);
  const [errMessage, setErrMessage] = useState({ message: '' });
  const [transaction, setTransaction] = useState({
    id:''
    ,client_id:''
    ,amount:59.00
    ,description:'GlobalHealthWay: Telehealth Consultation'
    ,status:''
    ,card_type:'prepaid'
    ,last_name: ''
    ,first_name:''
    ,country:''
    ,state:''
    ,city:''
    ,address:''
    ,zip_code:''
  });

  const [clientInfo, setClientInfo] = useState({
    client_id:''
    , first_name:''
    , last_name:''
    , address:''
    , state:''
    , zip_code:''
    , country:''
    , fetched:false
    , city:''
    , pcp_id: ''
    , other_physicians: ''
  })
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [loggedUser, setLoggedUser] = useState( initialLoginDataState );

  const [error, setError] = useState(null);
  
  const [sessionAttibutes, setSessionAttributes] = useState({});
  const [amountStr, setAmountStr] = useState();
  const [medicalRecordValues, setMedicalRecordValues] = useState({});
  const [newMedicalRecordId, setNewMedicalRecordId] = useState();

  function getNow() {
    var date = new Date();
    var dateStr =
    ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getDate()).slice(-2) + "/" + date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2)
    return dateStr
  }

  // useEffect(() => {
  //   const getCreds = async() => {
  //     console.log("CHECK CREDENTIALS FROM PAYMENT:")
  //     let ret = await userCredentials('FROM PAYMENT');
  //     setLoggedUser({...ret});
  //   }
  //   getCreds();
  // }, []);


  useEffect(()=>{
    // if(context.userState.clientid && clientInfo.client_id.length === 0){
    //   if(context.userState.clientid.length > 0 && sessionStorage.getItem("chatLogsHistory")){
    //     loadUserInfo(context.userState.clientid);
    //     //const cryptr = new Cryptr(process.env.REACT_APP_KEY || 'T3stKey');
    //     //let res = cryptr.decrypt(sessionStorage.getItem("chatLogsHistory"));
    //     let res = sessionStorage.getItem("chatLogsHistory");
    //     let userSessionAttributes = JSON.parse(res);
    //     setSessionAttributes(userSessionAttributes);
    //   }
    // }

    const callThis = async () =>{
      if(context.clientInfo.id){
        await loadUserInfo(context.clientInfo.id);
        await fetchList();
        let chatHistory = getItem(context.clientInfo.id);
    
        if(!_.isEmpty(chatHistory)){
          setSessionAttributes(chatHistory);
        }
      }
    }
    

    callThis();

  },[context.clientInfo])

  useEffect(() => {

    const callThis = async() =>{
      if(!sessionStorage.getItem("chatLogsHistory"))return;
      console.log("LOGS",sessionStorage.getItem("chatLogsHistory"));
      // const cryptr = new Cryptr(process.env.REACT_APP_KEY || 'T3stKey');
      // let userSessionAttributes = cryptr.decrypt(sessionStorage.getItem("chatLogsHistory"));
      
      let userSessionAttributes = sessionStorage.getItem("chatLogsHistory");
  
      let parsedJSON = JSON.parse(userSessionAttributes)
      console.log(parsedJSON.classification)
       
      setAmountStr("$" + Number(transaction.amount).toFixed(2));
  
      await fetchList();
    }
    callThis();

  },[])

  useEffect(async() => {

    if(transaction.card_type.toLowerCase() ==='credit' || transaction.card_type.toLowerCase() ==='debit')
      setDefaultsInfo();

    
 
  },[transaction.card_type])

  const trimValue = (p_value) => {
    if(p_value){
      return p_value.replace(/(\r\n|\n|\r|\t)/gm," ").trim();
    }else{
      return p_value;
    }
  }

  useEffect(() => {

    const callThis = async() =>{
      let l_date = getNow();

      if(transaction.status==='Transaction Successful'){
  
          
          const gen_session_id = uuidv4();     
          const chatHistory = getItem(context.clientInfo.id);
          console.log("CHAT LOG HISTORY CAPTURED FROM PAYMENT", chatHistory);
  
          const chatSteps = getItem(context.clientInfo.id + 'steps') ;
          
          let queueInfo = {
            id: gen_session_id
            ,client_id: trimValue(clientInfo.client_id)
            ,provider_id: clientInfo.pcp_id
            ,date_queued: l_date
            ,session_start: ''
            ,session_end: ''
            ,session_total_time: 0
            ,main_concern: sessionAttibutes.main_concern
            ,symptoms: sessionAttibutes.main_symptom
            ,classification: sessionAttibutes.classification
            ,specialization_a: sessionAttibutes.specialization_a
            ,specialization_b: sessionAttibutes.specialization_b
            ,state: clientInfo.state
            ,city: clientInfo.city
            ,payment_process: transaction.card_type
            ,amount: transaction.amount
            ,payment_transaction_number: transaction.id
            ,payment_session_id: transaction.id
            ,payment_status: 'authorized'
            ,steps_history: JSON.stringify(chatSteps)
            ,chat_history: JSON.stringify(chatHistory)
            ,status: 'queue'
            ,created_date: l_date
            ,created_by: clientInfo.client_id
            ,last_updated_date: l_date
            ,last_updated_by: clientInfo.client_id
            ,user_account_type: context.userState.provider
           
          }
          console.log("QUEUE",queueInfo)
          let resp = await API.graphql(
             graphqlOperation(mutations.createSessionQueues, {input:{...queueInfo}})
          );
          
          saveMedicalRecord(gen_session_id);
  
          /*Add code here for pre-creating the Medical Record*/
          
          if (typeof(Storage) !== "undefined") {
            console.log("SET AUHTORIZATION")
            // Store
            const logs = sessionStorage.getItem("chatLogs");
            if(logs !== null){
              
              let chatLogs = JSON.parse(logs);
              setPayButtonDisable(false);
              chatLogs = {...chatLogs, authorization: true}
              sessionStorage.setItem("chatLogs",JSON.stringify(chatLogs));
              
              storeItem(context.clientInfo.id + 'session', gen_session_id);
  
              setButtonLabel("Connect");
  
            }  
          }
      }
    }
    callThis();


  },[transaction.status])


  const saveMedicalRecord = async (p_session_id) => {
    let date = new Date();
    const gen_id = uuidv4();
    
    let postData;
    
    postData = {
        id: gen_id,
        client_id: context.clientInfo.id,
        provider_id: '',
        session_id: p_session_id,
        revision: medicalRecordValues.revision || 0,
        signed: 'no sign',
        data: medicalRecordValues.data,
        created_date: date,
        created_by: context.clientInfo.id,
        last_updated_date: date,
        last_updated_by: context.clientInfo.id
      }

      let response_data_save = await API.graphql(
        graphqlOperation(mutations.createMedicalHistory, { input: { ...postData } })
      );

      setNewMedicalRecordId(gen_id);
      storeItem(context.clientInfo.id + 'session', p_session_id);
  } 



  


  useEffect(() => {
    if(props.showStripe === true){
      let el = document.getElementById(props.id); 
      let instance = M.Modal.getInstance(el);  
      instance.open()
    }
  },[props.showStripe])

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: transaction.description,
          amount: transaction.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
     

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  //Initialize Medical Record
  const fetchList = async () => {

    if(context.clientInfo.id){
      let response_medical_records = await API.graphql(
        graphqlOperation(queries.queryMedicalRecordsByClientId, { client_id: context.clientInfo.id })
      );
  
      let items = response_medical_records.data.queryMedicalRecordsByClientId.items;
  
      let maxRevision = items.reduce((max, item) => Number(max.revision || '0') > Number(item.revision || '0') ? max : item);
  
      setMedicalRecordValues({ ...maxRevision });
      console.log("MAX REVISION", maxRevision);
    }
    
  
  }

  const handle_Submit = async (e) =>{
      e.preventDefault();
      setPayButtonDisable(true);

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });
      
      if(!error){
        try {
            const {id} = paymentMethod
            
            if (typeof(Storage) !== "undefined") {
              // Store
              const logs = sessionStorage.getItem("chatLogs");
              if(logs !== null){
                const chatLogs = JSON.parse(logs);
                
                if(chatLogs.gathering === true){
                  //Disabled line below. Too long 
                  //setPayButtonDisable(true);
                  const resp = await sendPayment(id);
                  console.log(resp);
                }
                
              }  
            }
        } catch (err) {
            setError(error);
            console.log(err)
        }
    }
  }

  const isValidFormatZipCode = (p_zipcode,p_country = "US") => {
    let postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
    switch (p_country) {
        case "US":
            postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
            break;
        default:
            postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    }
    return postalCodeRegex.test(p_zipcode);
  }


  const checkZipCode = async (p_country="US", p_zipcode, p_state_code) => {

    let resp = false
    let isValidZipFormat = isValidFormatZipCode(p_zipcode,p_country)
    if(isValidZipFormat===true){
      resp = await checkZip1(p_state_code, p_zipcode.substr(0,5))
      return resp
    }else{
      return resp
    }
    

  }

  const loadUserInfo = async (p_clientid) => {
    if(!p_clientid) return;
    if(!p_clientid === undefined) return;
    let client = await API.graphql(
      graphqlOperation(queries.getClients, {id: p_clientid})
    );
    

    let contactInfo = await API.graphql(
      graphqlOperation(queries.queryClientsProfileByClientId, {client_id: p_clientid})
    );

   

    if(client.data.getClients.id && contactInfo.data.queryClientsProfileByClientId.items[0].id){
    
      setClientInfo({...clientInfo, 
          client_id: client.data.getClients.id
        , first_name: client.data.getClients.first_name
        , last_name: client.data.getClients.last_name
        , pcp_id: client.data.getClients.pcp_id
        , address: contactInfo.data.queryClientsProfileByClientId.items[0].street_address_line_1
        , state: contactInfo.data.queryClientsProfileByClientId.items[0].state_code
        , zip_code: contactInfo.data.queryClientsProfileByClientId.items[0].zip_code_1
        , country: contactInfo.data.queryClientsProfileByClientId.items[0].country_code
        , city: contactInfo.data.queryClientsProfileByClientId.items[0].city
        , other_physicians: client.data.getClients.other_physicians
        , fetched:true
      })
      setTransaction({...transaction
        , first_name: client.data.getClients.first_name
        , last_name: client.data.getClients.last_name
        , address: contactInfo.data.queryClientsProfileByClientId.items[0].street_address_line_1
        , state: contactInfo.data.queryClientsProfileByClientId.items[0].state_code
        , zip_code: contactInfo.data.queryClientsProfileByClientId.items[0].zip_code_1
        , country: contactInfo.data.queryClientsProfileByClientId.items[0].country_code
        , city: contactInfo.data.queryClientsProfileByClientId.items[0].city
        , client_id: client.data.getClients.id
        , pcp_id: clientInfo.pcp_id
      })
     
    }
    
  }

  const setDefaultsInfo = () =>{
    setTransaction({...transaction
      , first_name: clientInfo.first_name
      , last_name: clientInfo.last_name
      , address: clientInfo.address
      , state: clientInfo.state
      , zip_code: clientInfo.zip_code
      , country: clientInfo.country
      , city: clientInfo.city
      , client_id: clientInfo.client_id
      , pcp_id: clientInfo.pcp_id
    })
    
  }

  const sendPayment =  async (id) =>{
    setLoader(true);
    let eMessage = [];
    let input_is_valid = true
    setErrMessage({ message: '' });

    if(transaction.card_type.toLowerCase() === 'credit' || transaction.card_type === 'debit'){
        let isValidZip = await checkZipCode("US", transaction.zip_code, transaction.state)
        
        input_is_valid = isValidZip
        
        if(transaction.state.trim().length === 0){
          input_is_valid = false
          eMessage.push("Invalid State Code: Please verify and input your state code.");
        }
        if(transaction.city.trim().length === 0){
          input_is_valid = false
          eMessage.push("Invalid City: Please verify and input your country code.");
        }

        if(transaction.last_name.trim().length === 0 || transaction.first_name.trim().length === 0){
          input_is_valid = false
          eMessage.push("Invalid last name / first name: Please input your last name / first name.");
        }

        if(transaction.address.trim().length === 0 || transaction.address.trim().length === 0){
          input_is_valid = false
          eMessage.push("Invalid address: Please input a valid address.");
        }

        let myArrCreatedFromMap

        if (eMessage.length > 0) {
          myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));

          //showMessage(myArrCreatedFromMap,"User input checking / verification:")
        }
        if (input_is_valid === false) {
          setPayButtonDisable(false);
          setErrMessage({ message: myArrCreatedFromMap });
          return
        }
        setLoader(false);
       
    }
    
    //if(transaction.amount > 0 && transaction.description.length>0 && input_is_valid===true){
    if(transaction.id.length === 0 && transaction.amount > 0 && transaction.description.length>0 && input_is_valid===true){
        // const cryptr = new Cryptr(process.env.REACT_APP_KEY || 'T3stKey');
        // let res = cryptr.decrypt(sessionStorage.getItem("chatLogsHistory"));
        
        let res = sessionStorage.getItem("chatLogsHistory");

        let userSessionAttributes = JSON.parse(res);
        setSessionAttributes(userSessionAttributes);
        
        console.log("push to aws")
        API.get('HygeiaInternalAPIs','/internal/payment/',{
          queryStringParameters:{
            amount: transaction.amount * 100,
            description: transaction.description,
            client_id: transaction.client_id,
            card_type: transaction.card_type,
            id: id,
            last_name: transaction.last_name,
            first_name: transaction.first_name,
            state: transaction.state,
            city: transaction.city,
            zip_code: transaction.zip_code,
            address: transaction.address,
            country: transaction.country,
            pcp_id: transaction.pcp_id,
            user_session_attributes: userSessionAttributes
          }
        
        }).then((results)=>{
          //props.setChatLogs({...props.chatLogs, authorize: true});

          setLoader(false);
          setTransaction({...transaction, id, status:"Transaction Successful"});


          notifyProvider();
        
    
        }).catch(err => { 
          setLoader(false); 
          setTransaction({...transaction, id, status:"Transaction failed"})
          console.log("Error",err);
          return false;
        }); 
      
    }else{
      setPayButtonDisable(false);
      return false;
    } 
    
    
    
  }

  const notifyProvider = async () =>{
    let useOtherPhysicians = false;
    let npi_id;
    const t = clientInfo.other_physicians;

    const items = JSON.parse(t);

    for(const item of items){

      if(item.npi_id.length > 0){ 
        npi_id = item.npi_id;

        if(item.email.length > 5){
          useOtherPhysicians = true;
        }
        if(item.phone_no.length > 5){
          useOtherPhysicians = true;
        }

      }
    }
 
    if(useOtherPhysicians){
      console.log("USE OTHER PHYSICIANS INFO")
      API.get('HygeiaInternalAPIs','/internal/notifyproviders/',{
        queryStringParameters:{
          client: clientInfo.last_name + ' ' + clientInfo.first_name,
          other_physicians: clientInfo.other_physicians,
          state: clientInfo.state,
          classification: sessionAttibutes.specialization_a || 'Internal Medicine',
        }
      
      }).then((results)=>{
        
        return true;
  
        
      }).catch(err => { 
        
        return false;
      }); 

    }else{
      console.log("USE PROFILE INFO")
      
      let p_id;
      let provider;
      if(npi_id){
        let respProviderInfo = await API.graphql(graphqlOperation(queries.queryProvidersInfoByNpi, {npi_id: npi_id}));
        
        if(respProviderInfo.data.queryProvidersInfoByNpi.items.length){
          p_id = respProviderInfo.data.queryProvidersInfoByNpi.items[0].client_id;
          provider = respProviderInfo.data.queryProvidersInfoByNpi.items[0];
        }  
      }
      
      if(p_id){
        
        let respProviderContactInfo = await API.graphql(graphqlOperation(queries.queryClientsProfileByClientId, {client_id: p_id}));
        let contactInfo = respProviderContactInfo.data.queryClientsProfileByClientId.items[0];
        console.log(contactInfo)
        const other_physicians = [];
        other_physicians.push({
          npi_id: npi_id
          , primary: true
          , full_name: provider.client_info.last_name + ', ' + provider.client_info.first_name
          , other_names: ""
          , email: contactInfo.email
          , invited: ""
          , taxonomy: provider.taxonomy
          , phone_no: contactInfo.mobile_country_code + contactInfo.mobile_phone_number
          , show: false
        })

        API.get('HygeiaInternalAPIs','/internal/notifyproviders/',{
          queryStringParameters:{
            client: clientInfo.last_name + ' ' + clientInfo.first_name,
            other_physicians: other_physicians,
            state: clientInfo.state,
            classification: sessionAttibutes.specialization_a || 'Internal Medicine',
          }
        
        }).then((results)=>{
          
          return true;
    
          
        }).catch(err => { 
          
          return false;
        }); 
      }
  
    }
  }

  const close = (e) =>{
    if (typeof(Storage) !== "undefined") {
      // Store
      const logs = sessionStorage.getItem("chatLogs");
      if(logs !== null){
        const chatLogs = JSON.parse(logs);
        chatLogs.queue = true;
        sessionStorage.setItem("chatLogs", JSON.stringify(chatLogs))

      }
      
    }

    props.setShowStripe(false);
    
    let el = document.getElementById(props.id); 
    let instance = M.Modal.getInstance(el);  
    instance.close()
    if(buttonLabel === 'Connect'){

      let redirect = '/medrec/load';

      if(!_.isEmpty(newMedicalRecordId)){
          redirect = '/medrec/' + newMedicalRecordId;
      }
      
      history.push({
        pathname: redirect
      });

    }else{
      //sessionStorage.setItem("chatLogs","");
      //sessionStorage.setItem("chatLogsHistory","");
      history.push({
        pathname: '/redirect'
      })
    }
    

    
  }
  
  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setTransaction({ ...transaction, [key]: val });
    
  }

  const checkLen = (e) => {
    console.log(e);
    
     
  }

    return (
    
      
      <Modal
          actions={[
            <a href = "#" className="smallbutton green" style={{"marginRight":"10px"}} onClick = {(e) => close(e)}>{buttonLabel}</a>            
          ]}
          bottomSheet={false}
          fixedFooter={false}
          id={props.id}


          options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '4%'
          }}
        
         
          >
          
        <div>
         <img src={logo}/>
         {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}

         {paymentRequest ?
            <PaymentRequestButtonElement options={{paymentRequest}} />
            : ''
         }
          <h6>
              Pre-authorization will not be charged until the visit is completed.
          </h6>
          <h6>
              You may cancel at any time by telling the provider or click [Cancel] below.
          </h6>
          <h6>
              It is no longer refundable once completed.
          </h6>

         <div style={{"marginTop":"12px","marginBottom":"12px","paddingTop":"4px"}}>
            <div style={{"marginBottom":"4px"}}>
              <label>Card Type:</label>
            </div>
            
            <div className="col m4 s12">
                <label>
                  <input id="radCredit"  name="gender" type="checkbox" className="filled-in" value="credit" checked={transaction.card_type === "credit"} onChange={(e) => { setTransaction({...transaction, card_type: "credit"});  }} />
                  <span  style={{ 'marginRight': '1rem'}}>Credit</span>
                </label>
               
                <label>
                  <input id="radDebit"  name="gender" type="checkbox" className="filled-in" value="debit" checked={transaction.card_type === "debit"} onChange={(e) => { setTransaction({...transaction, card_type: "debit"});  }} />
                  <span style={{ marginRight: '1rem' }}>Debit</span>
                </label>

                <label>
                  <input id="radPrepaid"  name="gender" type="checkbox" className="filled-in" value="prepaid" checked={transaction.card_type === "prepaid"} onChange={(e) => { setTransaction({...transaction, card_type: "prepaid"})  }} />
                  <span style={{ marginRight: '1rem' }}>Prepaid</span>
                </label>      
            </div>
         </div>
         
         {
           transaction.card_type.toLocaleLowerCase() === 'credit' || transaction.card_type.toLocaleLowerCase() === 'debit'
           ?
            <div>
              <div className="row">
                <div className="col m6 s12">
                <label>First Name</label>
                <input id="txtFirstName" name="first_name" 
                  value={transaction.first_name}  onChange={(e) => { handleChange(e) }} maxLength="50" />
                </div>
                <div className="col m6 s12">
                <label>Last Name</label>
                <input id="txtLastname" name="last_name" 
                  value={transaction.last_name}  onChange={(e) => { handleChange(e) }} maxLength="50" />
                </div>
              </div>

              
              <div className="row">
                <div className="col m12 s12">
                  
                  <Select
                    required
                    error="Required field"
                    validate
                    id="selState"
                      label="State"
                      multiple={false}
                      options={{
                      classes: '',
                      dropdownOptions: {
                      alignment: 'left',
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250
                      }
                      }}
                      value={ transaction.state === '' ? clientInfo.state : transaction.state }
                      
                      onChange={(e) => { setTransaction({ ...transaction, state: e.target.value }); }}
                      noLayout={true}
                    >
                      <option disabled value="">Choose your option</option>
                      {
                        usa_state_codes.map((data, key) => {
                        let arr = [];
                        if(data.Status==='ACTIVE')
                        arr.push(<option key={data.StateCode} value={data.StateCode}>{data.State}</option>) 

                        return (
                            arr
                        );
                        })
                        
                      }
                    </Select>
                </div>
              </div>

              <div className="row">
                <div className="col m6 s12">
                  <label>City</label>
                  <input id="txtCity" name="city" 
                    value={transaction.city}  onChange={(e) => { handleChange(e) }} maxLength="50" />
                </div>
                <div className="col m6 s12">
                  <label>Zip Code</label>
                  <input id="txtZipCode" name="zip_code" 
                    value={transaction.zip_code}  onChange={(e) => { handleChange(e) }} maxLength="50" />
                </div>
              </div>

              <div className="col m6 s12">
                <label>Address</label>
                <input id="txtAddress" name="address" 
                  value={transaction.address}  onChange={(e) => { handleChange(e) }} maxLength="50" />
              </div>
            </div>

           :
            ''
         }
         <div className="row">
            <div className="col m6 s12">
                <label>Description</label>
                <input id="txtDescription" name="description" 
                  value={transaction.description}  onChange={(e) => { handleChange(e) }} maxLength="50" />
            </div>
            <div className="col m6 s12">
                <label>Amount</label>
                
                <input  id="txtAmount" name="amount" 
                  value = { amountStr }
                  readOnly={true} />

            </div>
            
            
            
         </div>
         
         <div className="row">
            <div className="col m6 s12">
                <label>Transaction ID</label>
                <input readOnly={true} id="txtID"  name="id"
                  value={transaction.id}  maxLength="50" />
            </div>
            <div className="col m6 s12">
                <label>Transactio Status</label>
                <input readOnly={true} id="txtStatus"  name="status" 
                  value={transaction.status} maxLength="50" />
            </div>
         </div>
         

         {
           !success ? 
           <form onSubmit = {handle_Submit}>
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
              <div>
                <div className="row center FormGroup">        
                  <div className="col s12 m6">
                    <div>Card Number</div>
                      <CardNumberElement id="cardNumber"
                        onChange = {(e) => {setError(e.error); checkLen(e);}} 
                        className = "FormElement"
                        
                      
                      />
                    
                  </div>
                  <div className="col s12 m3">
                    <div>Expiry Date(MM/YY)</div>
                    <CardExpiryElement onChange={(e) => {setError(e.error);}} id="cardExpiry"
                      className="FormElement"
                      
                    />

                  </div>
                  <div className="col s12 m3">
                    <div>CVC Number</div>
                    <CardCvcElement onChange={(e) => {setError(e.error);}} id="cardCVC"
                      className="FormElement"
                      
                    />

                  </div>
                  
                </div>
              </div>
             <button className="payButton" disabled={payButtonDisable}>Pay</button>
             
           </form>
           :
           <div>
             <h2>Payment successfully submitted.</h2>
           </div>
         }

        <ul>{errMessage.message}</ul>
        </div>
      </Modal>
      
    )
}

export default Payment
