import React,{useState,useEffect} from 'react'
import 'materialize-css';
import { Icon,Button,Modal, TextInput,Preloader } from 'react-materialize';
import logo from '../../assets/imgs/logo_64.png';
import * as EmailValidator from 'email-validator';

//import g from '../../assets/imgs/btn_google_light_pressed_ios.svg';
import f from '../../assets/imgs/f_logo_RGB-Hex-Blue_512.png';
import { Auth } from 'aws-amplify';
import M from 'materialize-css';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";



const Confirm1 = (props) => {
    
    //const [readAgreement, setReadAgreement] = useState(false);
    const [modal0, setModal0] = useState(false);    
    return (
    
      
      <Modal
          actions={[
            <Button type="button"  modal="close" node="button" className="smallbutton green" onClick={()=>{props.proceedWithTheUpdate(false)}}style={{"marginRight":"10px", "marginBottom":"10px"}}>Wait. Let me check...</Button>,            
            <Button type="button"  modal="close" node="button" className="smallbutton green" onClick={()=>{props.proceedWithTheUpdate(true)}} style={{"marginRight":"10px", "marginBottom":"10px"}}>Verified my data. Please proceed...</Button>
          ]}
          bottomSheet={false}
          fixedFooter={false}
          id="modal0"
          open={modal0}
          options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '4%'
          }}
        
          trigger={<a href="#!" className="smallbutton green" style={{"minWidth":"150px","maxWidth":"150px"}}>Submit</a>}
          >
          
          <div>
          <img alt="GlobalHealthWay" src={logo}/>
          {
          props.userProfile.id!=="" ? 
            <div>
              <h5>NOTE!</h5>
              <p className="red-text">
              If you need to change your Legal Last Name
              , Legal First Name
              , Date of Birth and City of Birth
              , please send an email with identification to this email address:
              UpdateMyProfile@globalhealthway.com
              </p>
              
              <div className="row">
             
              <div className="col s12 m4">
              <span>&nbsp;	</span>
              </div>
              </div>

             
            </div>
          : 
            <div>
              <h5>WARNING!</h5>
              <p>
              Note that your Legal Last Name, Legal First Name, Date of Birth and City of Birth cannot be changed online once saved in our database. 
              Before proceeding, please make sure to check the correctness of these entries.
              </p>
              
              <div className="row">
              <div className="col s12 m4">
              <label>Legal Last Name:</label> <span>{props.userProfile.last_name}</span>
              </div>
              <div className="col s12 m4">
              <label>Legal First Name:</label> <span>{props.userProfile.first_name}</span>  
              </div>
              <div className="col s12 m4">
              <span>&nbsp;	</span>
              </div>
              <div className="col s12 m4">
              <label>Date of Birth:</label> <span>{props.userProfile.date_of_birth}</span>  
              </div>
              <div className="col s12 m4">
              <label>City of birth:</label> <span>{props.userProfile.city_of_birth}</span>  
              </div>
              <div className="col s12 m4">
              <span>&nbsp;	</span>
              </div>
              </div>

              <p className="red-text">
                NOTE: Future changes will require you to send an email and identification to this email address: UpdateMyProfile@globalhealthway.com
              </p>
            </div>
          }
          
          
          </div>

      </Modal>
      
    )
}

export default Confirm1


export const ShowMessage = () =>{
  
  const children =[];
  children.push(<Modal key={Math.floor(Math.random() * 100)}
    actions={[
      <Button type="button"  modal="close" node="button" className="smallbutton green" style={{"marginRight":"10px"}} >Close</Button>
    ]}
    bottomSheet={false}
    fixedFooter={false}
    open={false}
    options={{
      dismissible: true,
      endingTop: '10%',
      inDuration: 250,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      opacity: 0.5,
      outDuration: 250,
      preventScrolling: true,
      startingTop: '4%'
    }}
>
    
    <div>
    <img alt="GlobalHealthWay" src={logo}/>
    
    <h1>Test</h1>

    </div>

</Modal>);
  
  return(
    <div>
      {children}
    </div>
  )

}

export const ShowMessageModal = (props) => {
  
  return (
    <Modal
        actions={[
          <Button type="button"  modal="close" node="button" className="smallbutton green" style={{"marginRight":"10px"}} onClick={() =>{
            if(props.close)props.close();
          }}>Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        id = {props.id}
        open={false}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
    >
        
        <div>
        <img alt="GlobalHealthWay" src={logo}/>
        
        <div className="hygeiafontH1">{props.header}</div>
        <h6> {props.msg} </h6>

        </div>

    </Modal>
    
  )
}

export const CustomSignUp = (props) => {
  const [submitLabel, setSubmitLabel] = useState("Sign Up");
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState('');

  const [userStep, setUserStep] = useState(1);

  const [userSignUp,setUserSignUp] = useState({
    username:''
    , email:''
    , password:''
    , confirmPassword:''
    , passwordStrength:'weak'
    , message:''
    , matched:false
    , step:1
    , code:''
  })

  const [userSignIn, setUserSignIn] = useState({signin_username: '', signin_password:''});

  let eMessage = [];
  

  const signIn = async () =>{
    setLoader(true);
    eMessage = [];

    if(userSignIn.signin_username.length === 0 || userSignIn.signin_password.length === 0){
      eMessage.push("User name and password required.");
    }

    if(userSignIn.signin_username.length > 0 && userSignIn.signin_password.length > 0){
      
      try {
        const user = await Auth.signIn(userSignIn.signin_username, userSignIn.signin_password);
        setLoader(true);
        clear();
      } catch (error) {

        setLoader(true);
        console.log('error signing in', error);
        eMessage.push(error.message);
      }

    }

    if(eMessage.length > 0){
      let myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));
      setMsg(myArrCreatedFromMap);
    }
    
    
  }

  const showMessage = () =>{
    let el = document.getElementById("idSignUpMsg"); 
    let instance = M.Modal.getInstance(el);  
    instance.open();
  }

  const signUp = async (e) =>{
    //e.preventDefault();
    setLoader(true);
    eMessage = [];
    
    let myArrCreatedFromMap = '';

    if (EmailValidator.validate(userSignUp.email) === false) {
      eMessage.push("Email: Invalid email.");  
    }

    if(userSignUp.username.length === 0 || userSignUp.username === undefined){
      eMessage.push("User Name: Invalid User Name.");  
    }
    
    if(eMessage.length === 0 && userStep ===1 && userSignUp.username.length>1 && userSignUp.email.length>1 && userSignUp.passwordStrength === "Strong" && userSignUp.matched===true){

        if(userSignUp.step === 1){
          try {
            const { user } = await Auth.signUp({
                username: userSignUp.username,
                password: userSignUp.password,
                attributes: {
                    email: userSignUp.email,         
                }
            });

            setUserStep(2)
            setLoader(false);
          } catch (error) {
              console.log('error signing up:', error.message);
              eMessage.push(error["message"]);
              setLoader(false);
          }finally{
            console.log('');
          }
        }

        if(eMessage.length>0){
          myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));
          setMsg(myArrCreatedFromMap)
        }
        
    }

    if(userStep === 2){
      try {
        await Auth.confirmSignUp(userSignUp.username, userSignUp.code);
        setLoader(false);
        setUserStep(4);
      } catch (error) {
        console.log('error confirming sign up', error);
        eMessage.push(error["message"]);
        setLoader(false);
      }
      if(eMessage.length>0){
        myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));
        setMsg(myArrCreatedFromMap)
      }
    }

    if(userStep === 3){
      signIn();
    }

    if(userStep === 4){
      clear();
    }

    if(eMessage.length>0 && userStep ===1){
      myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));
      setMsg(myArrCreatedFromMap)
    }

    
  }

  const clear = () =>{
    //if(userSignUp.step === 2){

      setUserSignUp({ ...userSignUp, username:'', email:'', password:'', confirmPassword:'', passwordStrength:'weak', message:'',matched:false,step:1});
      setUserSignIn({ ...userSignIn, signin_username:'', signin_password:''});
      setUserStep(1);
      setMsg('');

      setSubmitLabel("Sign Up");

      let el = document.getElementById(props.id); 
      let instance = M.Modal.getInstance(el);  
      instance.close()
    // }else{
    //   let el = document.getElementById(props.id); 
    //   let instance = M.Modal.getInstance(el);  
    //   instance.close()
    // }
  }
  
  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;
  
    setUserSignUp({ ...userSignUp, [key]: val });
  }

  const handleChangeSignIn = (e) => {
    const key = e.target.name;
    const val = e.target.value;
  
    setUserSignIn({ ...userSignIn, [key]: val });
  }

  const checkPassword = (password) => {
    var strength = 0;
    eMessage = [];

    if (password.match(/[a-z]+/)) {
      strength += 1;
    }else{
      eMessage.push("Password: must contain at least one character in lowercase. [a-z]");  
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }else{
      eMessage.push("Password: must contain at least one character in uppercase. [A-Z]");  
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }else{
      eMessage.push("Password: must contain at least one numeric. [0-9]");  
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    }else{
      eMessage.push("Password: must contain at least one special character numeric. [$@#&!]");  
    }

    
    if (password.length < 6) {
      eMessage.push("Password: minimum number of characters is 6.");  
    }
  
    
    let strengthValue = "Weak";
   
    switch (strength) {
      case 0:
        strengthValue = "Weak";
        break;
  
      case 1:
        strengthValue = "Weak";
        
        break;
  
      case 2:
        strengthValue = "Weak";
        
        break;
  
      case 3:
        if(password.length>=8){
          strengthValue = "Medium"; 
        }else{
          strengthValue = "Weak";
        }
        break;
  
      case 4:
        if(password.length>=8){
          strengthValue = "Strong";
        }else{
          strengthValue = "Medium";
        }
        break;

      default:

    }
    let matched = false;

    if(userSignUp.password === userSignUp.confirmPassword){
      matched = true;
    }else{
      matched = false;
      eMessage.push("Password: did not match.");  
    }
    if (EmailValidator.validate(userSignUp.email) === false) {
      eMessage.push("Email: Invalid email.");  
    }

    if(userSignUp.username.length === 0 || userSignUp.username === undefined){
      eMessage.push("User Name: Invalid User Name.");  
    }

    
    let myArrCreatedFromMap = '';

    if (eMessage.length > 0) {
      myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));
    }
    setUserSignUp({ ...userSignUp, passwordStrength:  strengthValue,message: myArrCreatedFromMap, matched: matched});
    setMsg(myArrCreatedFromMap);
  }

  useEffect(() => {
    setMsg('');
    switch(userStep){
      case 1:
        setSubmitLabel("Sign Up");
        break;

      case 2:
        setSubmitLabel("Verify");
        break;

      case 3:
        setSubmitLabel("Sign In");
        break;

      case 4:
        setSubmitLabel("Okay");
        break;
    }


  },[userStep])

  useEffect(() => {
    
    if((userSignUp.password.length>0 || userSignUp.username.length>0) && userStep===1)
    checkPassword(userSignUp.password);
    
  },[userSignUp.password,userSignUp.confirmPassword])

  useEffect(() => {
    setUserSignUp({ ...userSignUp, username:'', email:'', password:'', confirmPassword:'', passwordStrength:'', message:''});
    setMsg('');
  },[])

  return (
    <React.Fragment>
    <Modal
        actions={[
          <Button type="button"  className="smallbutton green" style={{"marginRight":"10px"}} onClick={() =>{clear()}} >Cancel</Button>
          ,<Button type="button"  className="smallbutton green" style={{"marginRight":"10px"}} onClick={(e)=>{signUp(e)}}>{submitLabel}</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        id = {props.id}
        open={false}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: function(){clear()},
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
    >
        
        <div>
        <img alt="GlobalHealthWay" src={logo}/>
          {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}

          {userStep === 1 ? 
                  <div className="row" id="step1">
          
                  <div className="col m6 s12" style={{"borderTop": "1px solid","padding":"8px"}}>
                    <h6>Sign-up with your social media account</h6>
                    <div>  
                      <div>
                          <div className="row valign-wrapper" style={{"border": "1px solid #2b2fab","marginBottom":"8px","marginRight":"20px","padding":"5px","width":"255px","cursor":"pointer"}} 
                          onClick={()=>{Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})}}>
                            <div className="col s2">
                              {/* <img alt="Google" src={g} style={{"height": "40px","marginRight":"5px"}} /> */}
                            </div>
                            <div className="col s10" style={{"marginLeft":"8px"}}>
                              <span style={{"color":"#2b2fab"}} className="h6">
                                Continue with Google
                              </span>
                            </div>
                          </div>
                      
                          <div className="row valign-wrapper" style={{"border": "1px solid #2b2fab","marginBottom":"8px","marginRight":"20px","padding":"5px","width":"255px","cursor":"pointer"}} 
                          onClick={()=>{Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook})}}>
                            <div className="col s2">
                              <img alt="Facebook" src={f} style={{"height": "40px","marginRight":"5px"}} />
                            </div>
                            <div className="col s10" style={{"marginLeft":"8px"}}>
                              <span  style={{"color":"#2b2fab"}} className="h6">
                                Continue with Facebook
                              </span>
                            </div>
                          </div>
                      </div>
                      
                    </div>
                    
                    <div>
                      <label>We won't post to any of your accounts without asking first</label>
                    </div>
                  </div>
    
                  <div className="col m6 s12" style={{"borderTop": "1px solid","padding":"8px"}}>
                    <h6>or Sign-up by creating your own internal Global Health Way account</h6>
                    <div>
                    
                      <TextInput noLayout={true} id="txtUserName" value={userSignUp.username} name = "username" label="User Name" onChange={(e) => { handleChange(e) }} />
                      <TextInput noLayout={true} id="txtEmail" value={userSignUp.email} name = "email" label="Email" onChange={(e) => { handleChange(e) }} />
                      <TextInput noLayout={true} id="txtPassword" type="password" value={userSignUp.password} name="password" label="Password" onChange={(e) => { handleChange(e) }}/>
                      <TextInput noLayout={true} id="txtConfirmPassword" type="password" value={userSignUp.confirmPassword} name="confirmPassword" label="Confirm Password" onChange={(e) => { handleChange(e) }}/>
                      
                    </div>
                    <div>
                      <label>Already have an account? <span className="blue-text" onClick={()=>setUserStep(3)} >[Sign In]</span></label>
                    </div>
                    <div>
                      <label>{msg}</label>
                    </div>
                  </div>
    
                </div>
          :
          ''      
          }
          { userStep === 2 ? 
                  <div className="row" id="step2">
                    <h6>Sent a verification code to your email.</h6>
                    <TextInput noLayout={true} id="txtVerificationCode" value={userSignUp.code} name = "code" 
                    label="Input your Verification Code here" onChange={(e) => { handleChange(e) }}/>
                    
                    <label>{msg}</label>
                  </div>
          :
          ''
          }

          { userStep === 3 ? 
                   <div className="row" id="step1">
          
                   <div className="col m6 s12" style={{"borderTop": "1px solid","padding":"8px"}}>
                     <h6>Sign-up with your social media account</h6>
                     <div>  
                       <div>
                           <div className="row valign-wrapper" style={{"border": "1px solid #2b2fab","marginBottom":"8px","marginRight":"20px","padding":"5px","width":"255px","cursor":"pointer"}} 
                           onClick={()=>{Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})}}>
                             <div className="col s2">
                               {/* <img alt="Google" src={g} style={{"height": "40px","marginRight":"5px"}} /> */}
                             </div>
                             <div className="col s10" style={{"marginLeft":"8px"}}>
                               <span style={{"color":"#2b2fab"}} className="h6">
                                 Continue with Google
                               </span>
                             </div>
                           </div>
                       
                           <div className="row valign-wrapper" style={{"border": "1px solid #2b2fab","marginBottom":"8px","marginRight":"20px","padding":"5px","width":"255px","cursor":"pointer"}} 
                           onClick={()=>{Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook})}}>
                             <div className="col s2">
                               <img alt="Facebook" src={f} style={{"height": "40px","marginRight":"5px"}} />
                             </div>
                             <div className="col s10" style={{"marginLeft":"8px"}}>
                               <span  style={{"color":"#2b2fab"}} className="h6">
                                 Continue with Facebook
                               </span>
                             </div>
                           </div>
                       </div>
                       
                     </div>
                     
                     <div>
                       <label>We won't post to any of your accounts without asking first</label>
                     </div>
                   </div>
     
                   <div className="col m6 s12" style={{"borderTop": "1px solid","padding":"8px"}}>
                     <h6>or Sign-up by creating your own internal Global Health Way account</h6>
                     <div className="row" id="step2">
                        <TextInput noLayout={true} id="txtSignInUserName" value={userSignIn.signin_username} name = "signin_username" 
                        label="User Name" onChange={(e) => { handleChangeSignIn(e) }}/>
                        <TextInput noLayout={true} type="password" id="txtSignInUserPassword" value={userSignIn.signin_password} name = "signin_password" 
                        label="Password" onChange={(e) => { handleChangeSignIn(e) }}/>
                     </div>
                     <div>
                       <label>Need an account? <span className="blue-text" onClick={()=>setUserStep(1)} >[Sign Up]</span></label>
                     </div>
                     <div>
                       <label>{msg}</label>
                     </div>
                   </div>
     
                 </div>
          :
          ''
          }

          { userStep === 4 ? 
                  <div className="row" id="step2">
                    <h6>Signup successful! You can now signin with your internal account.</h6>
                  </div>
          :
          ''
          }
        

        

        </div>

    </Modal>
    <Modal
        actions={[
          <Button type="button"  modal="close" node="button" className="smallbutton green" style={{"marginRight":"10px"}} >Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        id = "idSignUpMsg"
        open={false}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
    >
        
        <div>
        <img alt="GlobalHealthWay" src={logo}/>
        
        <div className="hygeiafontH1">User Sign Up / Sign In</div>
        <h6> {msg} </h6>

        </div>

    </Modal>
    </React.Fragment>
  )
}







