
import React, { useState, useEffect, useContext } from 'react';
import hygeia from '../../assets/imgs/hygeia_pinay_with_mask.png';
import { Auth } from 'aws-amplify';
import ChatBotUI1 from '../../components/chatbotui/chatbotui1';
import Agreements1 from '../../components/agreements'
import 'materialize-css';
import { useHistory } from "react-router-dom";


import { initialLoginDataState
  , userCredentials, titleCase
} from '../../utils/initialstate.js';
import { ShowMessageModal } from '../../components/confirm/';
import AppContext from '../../AppContext';
import _ from 'lodash';
import './landingpage.css';

function LandingPage(props) {
  const context = useContext(AppContext)  
  const [userLogState, setUserState] = useState(initialLoginDataState);
  const [message, setMessage] = useState({header: '', msg: ''});
  const [showChatBot, setShowChatbot] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  
  const chatbot_setshowChatBot = () =>{
    //set lang sa false kasi ang magtatawag lang dito ay ung nasa chatbot.js
    setShowChatbot(false);
  }
  
  // useEffect(async() =>{

  //   if(!_.isEmpty(context.clientInfo)){
  //     console.log("LANDING PAGE, LOGGED IN CLIENT:", context.clientInfo);
  //     console.log("LANDING PAGE, LOGGED IN CLIENT:", context.providerInfo);
  //     console.log("LANDING PAGE, LOGGED IN CLIENT:", context.userState);
  //   }

  // },[context.clientInfo]);

  
  useEffect(() => {

    const getCreds = async () => {
      let loggedUser = await userCredentials('FROM PROFILE');

      if(props.userData.authenticated==='yes'){
        setUserState({ ...props.userData });
      }else{
        loggedUser = await userCredentials('FROM PROFILE');
        setUserState({ ...loggedUser });
      }
      
    }

    getCreds();
    checkContext();
    
   
  },[]);

  useEffect(() => {
    if(!_.isEmpty(context.userState.clientid)){
      if (typeof(Storage) !== "undefined") {
        const client_id = localStorage.getItem("c_client_id");
        const id = localStorage.getItem("c_queue_id");
        const meeting_id = localStorage.getItem("c_meeting_id"); 
        
        if(client_id && id && meeting_id){
          const location = {
            pathname: `/chime/${client_id}/${id}/${meeting_id}`,
            state: { from: '/'}
            }
          history.push(location);  
        }
        
      }
    }
  }, [context.userState]);
  
  const checkContext = async () =>{

    if (typeof(Storage) !== "undefined") {
      // Store
      const logs = sessionStorage.getItem("chatLogs");
      if(logs !== null){
        const chatLogs = JSON.parse(logs);
        console.log("chatlogs", chatLogs);
        
        if(chatLogs.gathering === true){
          console.log("Gathering is set to true. Redirect to step processor");
          setRedirect(true);
        }
      }
      
    }

    
  }
  
  useEffect(() => {
    if(redirect === true){
      history.push({
        pathname: '/redirect'
      })
    }
  },[redirect])


  const showHygeiaWindow = () => {
    localStorage.removeItem("undefined");
    localStorage.removeItem("undefinedsession");
    localStorage.removeItem("undefinedsteps");

    if(context.clientInfo.id){
      localStorage.removeItem(context.clientInfo.id);
      localStorage.removeItem(context.clientInfo.id + "session");
      localStorage.removeItem(context.clientInfo.id + "steps");
    }
    setShowChatbot(true); 
  }

  return (
  <div>  
  

  <div className="row container center" style={{"height":"100px"}}>
    { ' ' }
  </div>

  <div className="row container center">      
    {
    userLogState.authenticated==='yes' ? 
      <div className="col s12 hygeiafont hygeiafontH2">
        Welcome back { ' ' } {context.clientInfo.nickname==='' || context.clientInfo.nickname===null ? titleCase(context.clientInfo.first_name) : context.clientInfo.nickname}! 
      </div>
    :
      <div className="col s12 hygeiafont hygeiafontH2">
        Welcome!
      </div>
    }  
    
  </div>
  
   
  <div className="row center">        
    <div className="col s12">
    
      <img src={hygeia} style={{"height": "150px"}} />
    
    </div>
    
  </div>
  
  <div className="row center">        
    <div className="col s12 hygeiafont hygeiafontH2">
      I'm Hygeia, your healthcare assistant.
    </div>
  </div>
  
  <div className="row container center">    
    
    
      <div className="flex-container3">
        <div className="smallbutton green">
         
          <a onClick={(e)=>showHygeiaWindow(e) }>Get Started</a>  
        </div> 
      </div>
    
  
  </div>

  
  <div className="row container center">

    <div style={{"paddingLeft":"15px"}}>
      <hr></hr>
      <div className="flex-container3">
        { 
          userLogState.authenticated==='yes' ? 
              userLogState.clientid !== null ? 
              <React.Fragment>
                <div className="smallbutton green">
                  <a onClick={()=>{Auth.signOut()}}>Sign Out</a>     
                </div>
                <div className="smallbutton gray">
                  <a href="#">Sign In</a>          
                </div>
              </React.Fragment>
              :
              null
          :
          <React.Fragment>
              <div className="smallbutton green">
                <a onClick={() => {Auth.federatedSignIn();}}>Sign In / Sign Up</a>     
              </div>
          
          </React.Fragment>
        }  
      </div>
              
    </div>
   
  </div>
  
  <div className="row container center" style={{"height":"100px"}}>
    { ' ' }
  </div>
  <div className="row container center" style={{"marginBottom":"100px"}}>
    <h6>By using this application I <Agreements1 trigger = { <a href="#" className="green-text">agree</a> }/> to Global Health Way's Terms of Use, Privacy Policy, and Telehealth consultation</h6>
  </div> 
        
  <ShowMessageModal id="idShowMessageModalLandingPage" msg={message.msg} header={message.header}/>
  
  <ChatBotUI1 showChatBot={showChatBot} cbSetShowChatbot = {chatbot_setshowChatBot} userData = {props.userData}/>
  </div>

  );

}

export default LandingPage;
