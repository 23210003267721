import React from 'react'
import MobileNumber from '../../components/inputfields/'

import { TextInput } from 'react-materialize';

const ForTesting = () => {
  return (
    <React.Fragment>
       <div>For Testing</div>
       <div className="row">
         <div className="col m2">
          <TextInput label="Test Label" value="Test value" noLayout={true}/>
         </div>
         <div className="col m6">
          <MobileNumber id="idMobileNumber"/>  
         </div>
       
       </div>
    </React.Fragment>
  )
}

export default ForTesting
