import React, { useEffect, useState, useContext } from 'react';

import Typography from '@mui/material/Typography';
import { Document, Page } from 'react-pdf';
import { Button,Modal } from 'react-materialize';
import ViewMedical from './viewmedical';

import ShowLoader from '../../components/showloader/showloader';


//import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { genKey } from '../../utils/initialstate';


import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import AppContext from '../../AppContext'
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Storage } from 'aws-amplify';
import { useHistory } from "react-router-dom";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));




function MedicalRecords() {

  const context = useContext(AppContext);
  const [rows, setRows] = useState([]);
  //const [pdfList, setPdfList] = useState([]);
  const [pdf, setPdf] = useState();
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [showPdf, setShowPdf] = useState(false);

  const [loader, setLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("Please wait while I am processing your request...");
  const [numPages, setNumPages] = useState(3);
  const [pageNumber, setPageNumber] = useState(1);
  const [buttonLabel, setButtonLabel] = useState('Fill out Medical Record');
  const [recordId, setRecordId] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    if(numPages){
      setNumPages(numPages);
    }
  }

  // const getListOfMedicalRecords = () =>{
  //   setLoader(true);
  //   let list = [];
  //   Storage.list('medical/') // for listing ALL files without prefix, pass '' instead
  //   .then(result => {
  //     for(const item of result){
  //       list.push({file: (item.key.split('/'))[1], last_modified_date: item.lastModified.toDateString()})
  //     }
  //     if(list.length > 0) setButtonLabel("Update Medical Record");
  //     setRows(list);
  //     setLoader(false);
  //   })
  //   .catch(err => {
  //     setLoader(false);
  //     console.log(err);
  //   });
  // }

  const fetchList = async () => {
    if (!_.isEmpty(context.clientInfo)) {
      if (context.clientInfo.id) {
        let response_medical_records = await API.graphql(
          graphqlOperation(queries.queryMedicalRecordsByClientId, { client_id: context.clientInfo.id })
        );
        
        let items = [];
        let itemsNoSign = [];

        for(const item of response_medical_records.data.queryMedicalRecordsByClientId.items){
          if(item.signed !== 'no sign'){
            items.push({version: parseInt(item.revision || '0'), id: item.id, last_modified_date: (new Date(item.created_date)).toString(), session_id: item.session_id});
          }else{
            itemsNoSign.push({version: parseInt(item.revision || '0'), id: item.id, last_modified_date: (new Date(item.created_date)).toString(), session_id: item.session_id})
          }
        }
        
        let sortedItems = _.orderBy(items, (a) => a.version, ['desc'])
        let sortedItemsNoSign = _.orderBy(itemsNoSign, (a) => a.last_modified_date, ['desc']);

        let NoSignDate;
        let SignedDate;

        if(sortedItemsNoSign.length > 0){
          //setRecordId(sortedItemsNoSign[0].id);
          NoSignDate = new Date(sortedItemsNoSign[0].last_modified_date);
        }
        
        if(sortedItems.length > 0){
          //setRecordId(sortedItems[0].id); 
          SignedDate = new Date(sortedItems[0].last_modified_date);
        }
      
        if(NoSignDate > SignedDate){
          setRecordId(sortedItemsNoSign[0].id);
        }else{
          setRecordId(sortedItems[0].id); 
        }
        //console.log("No Sign", sortedItemsNoSign);

        if(items.length >0){
          setButtonLabel("Update Medical Record");
          setRows(sortedItems);
        }
        
        
        
        //console.log(response_medical_records.data.queryMedicalRecordsByClientId.items);
      }

    }
  }

  const downloadFile = async (key) => {
    
    //setLoader(true);
    let filename = 'medical/' + key + '.pdf' ;
    try {
        const result = await Storage.get( filename , { download: true, level: 'public', contentType: "applicaion/pdf" });  
        setPdf(result.Body);
    } catch (error) {
        if(error.name === 'NoSuchKey'){
          alert("Record not found");
        }else{
          alert("Encountered an error while getting your file. Please try again later.");
        }
    }
    

    
    
  }
 
  useEffect(()=>{
    //setLoader(false);
    if(pdf){
      //setOpenModal(true);
      setShowPdf(true);
    }
  },[pdf]);

  useEffect(() => {
    fetchList();
    //getListOfMedicalRecords();
  }, [context.clientInfo])

  const nextPage = (e) =>{
    e.preventDefault();
    if(numPages > pageNumber){
      setPageNumber(pageNumber + 1);
    }
  }

  const previousPage = (e) =>{
    e.preventDefault();
    if(pageNumber > 1){
      setPageNumber(pageNumber - 1);
    }
  }
  //button component={Link} to={`/medrec/load`}>Fill out Medical Record

  const updateMedicalRecord = (e) =>{
    let rec = recordId === '' ? 'load' : recordId;

    history.push('/medrec/' + recordId, { from: "/meddetails" });

  }

  return (

    <React.Fragment>

      <ScopedCssBaseline>
        <Paper variant="outlined">
          <ShowLoader id="medicalHistoryLoader" show={loader} message={loaderMessage}/>

          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
                <Typography >&nbsp;</Typography>
                  <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { updateMedicalRecord(e) }}>{buttonLabel}</a>
                <Typography >&nbsp;</Typography>
            </Grid>
            <Grid item xs={12}>
             
                  <div style={{ display: 'flex', height: '100%', overflow:'auto', padding: '10px'}}>
                    <div style={{ flexGrow: 1 }}>

                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{'overflowX':'auto'}}>

                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Version</TableCell>
                              <TableCell align="center">Action</TableCell>
                              <TableCell align="center">Last Modified Date</TableCell>
                              <TableCell align="center">Session ID</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, index) => (
                              <TableRow
                                key={`${genKey()}_${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                
                                <TableCell align="center" style={{"border-right": "1px solid #9cc99c","border-left": "1px solid #9cc99c", "width":"100px"}}>{row.version}</TableCell>
                                
                                
                                <TableCell align="center" style={{"border-right": "1px solid #9cc99c","border-left": "1px solid #9cc99c", "width":"150px"}}>

                                    <a className="tooltipped green-text" data-position="top" data-tooltip="I am a tooltip" style={{cursor:"pointer",marginLeft:"10px", marginRight:"10px"}} onClick={(e)=>downloadFile(row.id)}>
                                      <i className="small material-icons">open_in_new</i>
                                    </a>
                                    <a className="tooltipped green-text" data-position="top" data-tooltip="I am a tooltip" style={{cursor:"pointer",marginLeft:"10px", marginRight:"10px"}} onClick={(e)=>downloadFile(row.id)}>
                                      <i className="small material-icons">share</i>  
                                    </a>
                                </TableCell>

                                
                                <TableCell align="left">{row.last_modified_date}</TableCell>
                                <TableCell align="left">{row.session_id}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      
                    </div>
                  </div>
            
            </Grid>
            
          </Grid>
        </Paper>

        <ViewMedical show={showPdf} pdf={pdf} id="PDF_SHOW_ID_MEDICA_RECORD" setShow={setShowPdf} modal={true}/>

        <Modal
        actions={[
          <Button flat modal="close" node="button" waves="green" onClick={(e)=>{setOpenModal(false)}}>Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter
     
        id="Modal-PDFviewer1"
        open={openModal}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
        
        >
        
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} style={{overflow:"scroll"}}>
            <Page pageNumber={pageNumber}  height={1403} width={992}/>
          </Document>
         
         
          <p>
            Page {pageNumber} of {numPages}
          </p>
          {/* <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { nextPage(e) }}>Next</a>
          <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { previousPage(e) }}>Previous</a> */}
          <Button flat modal="close" node="button" waves="green" onClick={(e)=>{nextPage(e)}}>Next</Button>
          <Button flat modal="close" node="button" waves="green" onClick={(e)=>{previousPage(e)}}>Prev</Button>
        </Modal>


      </ScopedCssBaseline>

    </React.Fragment>
  );
}

export default MedicalRecords;
