import React, { useState, useEffect, useRef, useContext } from 'react'
import 'materialize-css';

import { API, graphqlOperation } from 'aws-amplify';

import { useHistory, useParams } from "react-router-dom";
//import { DataStore, syncExpression } from 'aws-amplify';

import { getItem, getHygeiaStoreItem } from '../../utils/initialstate.js';

//import { Clients } from '../../models';
import AppContext from '../../AppContext'
import * as queries from '../../graphql/queries';
import { Button, Modal } from 'react-materialize';
import ViewMedical from '.././medical-records/viewmedical';

import ShowLoader from '../../components/showloader/showloader';
import { Storage } from 'aws-amplify';

import _, { fill } from 'lodash'



const Medical = (props) => {
  const context = useContext(AppContext);
  
  const params = useParams();
  const [patientId, setPatientId] = useState();

  const [clientInfo, setClientInfo] = useState({});
  const [modalPromptForAccess, setModalPromptForAccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState('Loading....');
  const [contactInfo, setContactInfo] = useState({});
  const [pdf, setPdf] = useState();
  const history = useHistory();
  const [medicalShareRecordHistory, setMedicalRecordShareHistory] = useState({});
  const fromRef = useRef();

  /*
  * GO BACK
  * 
  */
  const goBack = () => {
    console.log("Ref", fromRef);
    
    if(fromRef.current){
      const location = {
        pathname: '/providerwaitingroom',
        state: { from: '/medical'}
        }
      history.push(location);
      
    }else{
      if(typeof history.location.state !== 'undefined'){
        if(typeof history.location.state.from !== 'undefined')
          history.push(history.location.state.from, { from: "/user" });
        else
          goHome();
      }else{
        goHome();
      }
    }
  }

  const goHome = () =>{
    history.push("/", { from: "/medical" });
  }

  
  useEffect(() => {
    let client_id = context.userState.clientid;

    if(params.client_id){
      client_id = params.client_id;
    }

    //const getCreds = async () => {

      // let client = await API.graphql(
      //   graphqlOperation(queries.getClients, {id: client_id})
      // );
      
      // setClientInfo(client.data.getClients);

      // let profiles = await API.graphql(
      //   graphqlOperation(queries.queryClientsProfileByClientId, {client_id: client_id})
      // );
      
      // if(profiles.data.queryClientsProfileByClientId.items){

      //   for(const item of profiles.data.queryClientsProfileByClientId.items){

      //     if(context.userState.provider === item.internal_account_type){
      //       setContactInfo(item);
      //     }

      //   }

      // }
      
    //}

    //getCreds();
     
   
  }, []);

  console.log("TRIGGERED");

  useEffect(()=>{
    try{ 
      
      
      
      let chatHistory = JSON.parse(localStorage.getItem(context.clientInfo.id));
      

      console.log("CHAT HISTORY DATA", chatHistory.user_role);

      
    }catch(error){
      console.log(error)

    }
    

  },[context.clientInfo]);

  useEffect(() => {

   
    

    if(context.providerInfo.npi_id && context.clientInfo.id) {

      setLoader(true);
      const link1 = `/internal/provider/medicalrecord/access`;
  
      API.get('HygeiaInternalAPIs',link1,{
        queryStringParameters:{
        id: params.id,
        client_id: params.client_id,
        npi_id: context.providerInfo.npi_id,
        provider_id: context.clientInfo.id,
        session_id: params.id
        }
      }).then(async(results)=>{
        
        if(results.message === 'no data found' && results.success === true){
          setModalPromptForAccess(true);
          setLoader(false);
        }else{
          console.log("WITH ACCESS");
          
            setLoader(true);
            
            let res = await API.graphql(
              graphqlOperation(queries.queryMedicalShareHistoryByNpiAndSession, { npi_id: context.providerInfo.npi_id, beginsWith: {session_id: params.id}  })
            );
    
            if(res.data.queryMedicalShareHistoryByNpiAndSession.items.length > 0){
  
              for(const item of res.data.queryMedicalShareHistoryByNpiAndSession.items){
  
                if(item.granted === 'true'){
                  let filename = 'medical/' + item.medical_record_id + '.pdf';
                  const result = await Storage.get( filename , { download: true, level: 'public', contentType: "applicaion/pdf" });
                  setPdf(result.Body);
                  setLoader(false);
                }
                
              }
              
            }else{
              setLoader(false);
            }
            //setMedicalRecordShareHistory
  
  
            
            
        
        }
        
      }).catch(error => { 
        //setLoader(false); 
        console.log("Error",error);
        setLoader(false);
      });   

    }
  }, [context.clientInfo, context.providerInfo])


    //, context.contactInfo, context.providerInfo
  

  const gotoChatRoom = () =>{
    
    let client_id = context.userState.clientid;

    if(params.client_id){
      client_id = params.client_id;
      const location = {
        pathname: `/chime/${client_id}/${params.id}/${params.meeting_id}`,
        state: { from: '/medical'}
      }
      history.push(location);
    }else{
      const location = {
        pathname: `/client`,
        state: { from: '/medical'}
      }
      history.push(location);
    }
    //history.push("/chime");
  }

  const requestAccess = () =>{
    setMessage('Notifying client now...')
    setLoader(true);

    const link1 = `/internal/provider/medicalrecord/request`;
 
    API.get('HygeiaInternalAPIs',link1,{
      queryStringParameters:{
        id: params.id,
        client_id: params.client_id,
        npi_id: context.providerInfo.npi_id,
        provider_id: context.clientInfo.id,
        session_id: params.id,
        user_account_type: params.user_account_type,
        last_name: context.clientInfo.last_name,
        first_name: context.clientInfo.first_name,
        client_name: params.name,
        provider_email_address: context.contactInfo.email_address,
        provider_phone_number: context.contactInfo.mobile_country_code + context.contactInfo.mobile_phone_number
      }
    }).then(results=>{
      console.log(results);
      if(results.message === 'data found' && results.success === true){
        setModalPromptForAccess(false);
      }

      setLoader(false);
    }).catch(error => { 
      //setLoader(false); 
      console.log("Error",error);
      setLoader(false);
    });   

  }

  return (
    <div>
    <div className="white">
      <nav>
        <div className="nav-wrapper hygeiabackground">
        <div>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
            <a href="#!"  className="breadcrumb" style={{"paddingLeft": "10px"}}>Medical History</a>
        </div>
        </div>
      </nav>
    </div>

    <div className="col s12 container" style={{"margin":"20px"}}>
    
    <div className="row">
      <div className="col s12 m8 offset-m3">
        <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px","marginTop":"30px", "marginLeft":"20px" }} onClick={(e) => { gotoChatRoom() }}>Go to meeting room</a>,

      </div>
    </div>
    {
      pdf && pdf !== undefined ? 
        <ViewMedical show={true} pdf={pdf} id="PDF_SHOW_ID_SUMMARY"  modal={false}/> : null

    }  
    </div>

    <ShowLoader id="medicalHistoryLoader" show={loader} message={message}/>
 

    <Modal
        actions={[
          <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px","marginTop":"30px" }} onClick={(e) => {requestAccess(e)}}>Yes</a>,
          <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px","marginTop":"30px" }} onClick={(e) => {setModalPromptForAccess(false)}}>No</a>
        ]}
        

        bottomSheet={false}
        fixedFooter={true}

        id="Modal-Request-For-Access"
        open={modalPromptForAccess}
        options={{
          dismissible: false,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
        
        
      > 
        <h6>View Access</h6>
        
        <h6>Would you like to request for the signed Medical Record of the Patient</h6>
        
    </Modal>
    </div>
)
}

export default Medical

