
import React, { useEffect, useContext, useState } from 'react'
import clientimg from '../../assets/imgs/client.jpg';
import 'materialize-css';
import './style.css';
import AppContext from '../../AppContext';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import _ from 'lodash';


const ClientWaitingRoom = () => {
    const context = useContext(AppContext);
    const history = useHistory();

    const [patientSessions, setPatientSessions] = useState([]);

    // DataStore.configure({
    //   syncExpressions: [syncExpression(SessionQueues, s => s.client_id('eq', context.clientInfo.id))]
    // })
    
    // const querySongs = useCallback(async () => { 
    //   setPatientSessions(await DataStore.query(SessionQueues))
    // }, [])

    useEffect(()=>{
      console.log("PATIENT sessions", patientSessions);
    },[patientSessions]);

    useEffect(()=>{
      console.log(context.clientInfo.id);
     
      const getInitialData = async() =>{
          let sessions = await API.graphql(graphqlOperation(queries.querySessionQueuesByClientId, {filter: {status: {ne: "end"}}, client_id: context.clientInfo.id.toString().trim()}));
         
          //let sessions = await API.graphql(graphqlOperation(queries.querySessionQueuesByClientId, {filter: {status: {ne: "end"}}, client_id: '521dd04f-d8ff-414e-b314-ccd90d227596	'}));
          setPatientSessions(sessions.data.querySessionQueuesByClientId.items);
      }
      
      getInitialData();
      

    },[])

    const getData = async (p_item) =>{
      const location = {
        pathname: `/chime/${p_item.client_id}/${p_item.id}/${p_item.meeting_id}`,
          state: { from: '/client'}
        }
      history.push(location);
    }

  return (
    <React.Fragment>
      <div className="row">
      {

        patientSessions.map((item, index)=>{
          return(
          <div className="col m3 s12" key={`sessions${index}${item.id}`}>
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={clientimg}/>
            </div>
            <div className="card-content">
              <span className="card-title activator grey-text text-darken-4">Date Queued {item.date_queued}<i className="material-icons right">more_vert</i></span>
              {
                item.status === 'online' ? <p className="font1 green-text">Status: Your provider is online</p> : <p className="font1">Status: Your provider is offline</p>
              }
              
              <p className="font1">
                {item.status === 'online' ? <a href="#" onClick = {()=> getData(item)}>Go to meeting room</a> : <span>Please stand-by</span>}
              </p>
            </div>
            <div className="card-reveal">
              <span className="card-title grey-text text-darken-4">Details<i className="material-icons right">close</i></span>
              <p>
                <span className="green-text">Main Concern:</span> {item.main_concern.toString().replace(new RegExp("_","g")," ")}
              </p>
              <p>
                <span className="green-text">Classification:</span> {item.classification}
              </p>
              <p>
                <span className="green-text">Symptoms:</span> {item.symptoms.toString().replace(new RegExp("_","g")," ")}
              </p>
              <p>
                <span className="green-text">Date Queued:</span> {item.date_queued}
              </p>
            </div>
          </div>
          </div>
          )
        })

      }
      

      </div>
    </React.Fragment>
  )
}

export default ClientWaitingRoom
