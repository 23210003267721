import React, { useEffect, useState, useContext } from 'react'
import 'materialize-css';
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Switch, Table, Collapsible, CollapsibleItem } from 'react-materialize';
import AppContext from '../../AppContext'

const ProviderWaitingroom = (props) => {
	
  //let users = props.users;
  const context = useContext(AppContext);
  const history = useHistory();
  const [queItems, setQueItems] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [view, setView] = useState(true)
  //const location = useLocation()
  /*
  * GO BACK
  * 
  */
 const goBack = () => {
	if(typeof history.location.state !== 'undefined'){
		if(typeof history.location.state.from !== 'undefined')
		  history.push(history.location.state.from, { from: "/user" });
		else
		  goHome();
	}else{
		goHome();
	}
  }

  const goHome = () =>{
    history.push("/", { from: "providerwaitingroom" });
  }

  const goMedicalRecords = (item) =>{
	  
	  if(!item.meeting_id){
		const location = {
			pathname: `/medical/${item.client_id}/${item.id}/${item.user_account_type}/${item.client_info.first_name}`,
			state: { from: '/providerwaitingroom'}
	  	}
    	history.push(location);
	  }
		
  }
 
  useEffect(() => {
    var collaps = document.querySelectorAll(".collapsible");
    M.Collapsible.init(collaps, {accordion: false});
   
	getDataWaitingPatients();

  }, []);
  
 
  useEffect(()=>{
	  if( queItems ){
		  if( queItems ){
			if( queItems.length > 0 ){
				
				const map = new Map();
				const result = [];
		
				
												 
				for (const item of queItems) {
					if(!map.has(item['classification'])){
						map.set(item['classification'], true);    // set any value to Map
						result.push({
							name: item['classification']
						});
					}
				}
				setClassifications(result)

			}
		  }
		
	  }
	  
		
  },[queItems])

  const getDataWaitingPatients = async () =>{

	let itemsOnQueue = await API.graphql(
	 	graphqlOperation(queries.querySessionQueuesByStatus, {status: "queue"})	
	);

	const sortedItems = itemsOnQueue.data.querySessionQueuesByStatus.items.sort(function(a, b) {
		var c = new Date(a.date_queued);
		var d = new Date(b.date_queued);
		return c-d;
	});
    console.log(sortedItems);

	setQueItems(sortedItems);

	// querySessionQueuesByMainConcern(main_concern: "Vision_Change", statusCreated_dateClient_id: {beginsWith: {status: "queue"}})

  
	//   let contactInfo = await API.graphql(
	// 	graphqlOperation(queries.queryClientsProfileByClientId, {client_id: p_clientid})
	//   );
  
	//   if(client.data.getClients.id && contactInfo.data.queryClientsProfileByClientId.items[0].id){
	  
	// 	setClientInfo({...clientInfo, 
	// 		client_id: client.data.getClients.id
	// 	  , first_name: client.data.getClients.first_name
	// 	  , last_name: client.data.getClients.last_name
	// 	  , pcp_id: client.data.getClients.pcp_id
	// 	  , address: contactInfo.data.queryClientsProfileByClientId.items[0].street_address_line_1
	// 	  , state: contactInfo.data.queryClientsProfileByClientId.items[0].state_code
	// 	  , zip_code: contactInfo.data.queryClientsProfileByClientId.items[0].zip_code_1
	// 	  , country: contactInfo.data.queryClientsProfileByClientId.items[0].country_code
	// 	  , city: contactInfo.data.queryClientsProfileByClientId.items[0].city
	// 	  , fetched:true
	// 	})
	// 	setTransaction({...transaction
	// 	  , first_name: client.data.getClients.first_name
	// 	  , last_name: client.data.getClients.last_name
	// 	  , address: contactInfo.data.queryClientsProfileByClientId.items[0].street_address_line_1
	// 	  , state: contactInfo.data.queryClientsProfileByClientId.items[0].state_code
	// 	  , zip_code: contactInfo.data.queryClientsProfileByClientId.items[0].zip_code_1
	// 	  , country: contactInfo.data.queryClientsProfileByClientId.items[0].country_code
	// 	  , city: contactInfo.data.queryClientsProfileByClientId.items[0].city
	// 	  , client_id: client.data.getClients.id
	// 	  , pcp_id: clientInfo.pcp_id
	// 	})
	   
	//   }
  }

  const toggleView = (e) =>{
	
	console.log(e.target.value)
	setView(!view);
  }
  
  const getTime = () =>{
	  const d = new Date();
	  return d.getTime();

  }
return (
<div>
    <div className="white ">
	<nav>
	<div className="nav-wrapper hygeiabackground">
	<div>
		<a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
		<a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
		<a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}}>Provider</a>
	</div>
	</div>
	</nav>

	<div >
	<h5 style={{"textAlign": "center", "color": "#226e93"}}>Provider: Waiting Room View</h5>
  	<Switch id="Switch-11" offLabel="View Per Date Queue" value={view} checked={view === true} onChange={(e)=>{toggleView(e)}} onLabel="View per classification"/>

	{view === true ?
		<div className="col s12" style={{"margin":"20px"}}>
			
				{
					classifications.map((item, index)=>{

					const tmpItemsU = queItems.filter(dat => dat.classification === item.name);

					const tmpItems = tmpItemsU.sort(function(a, b) {
						var c = new Date(a.date_queued);
						var d = new Date(b.date_queued);
						return c-d;
					});

					return(
						<Collapsible accordion key = {`classification_${index}${getTime()}`}>
						<CollapsibleItem
							expanded={false}
							header={`(${tmpItems.length}) - ${item.name}`}
							node="div"
						>
						<Table responsive={true}>
							<thead>
								<tr>
								<th data-field="name">
									Name
								</th>
								<th data-field="date_queued">
									Queue Date
								</th>
								<th data-field="classification">
									Classification
								</th>
								<th data-field="symptoms">
									Symptom(s)
								</th>
								</tr>
							</thead>
							<tbody>
								
									{
										tmpItems.map((itm, idx)=>{
											console.log(itm)
											return(
												<tr key={`test_row_${idx}`}>
													<td>
														<a href="#!" onClick={()=>goMedicalRecords(itm)}>{itm.client_info.last_name +  ' ' + itm.client_info.first_name}</a>
													</td>
													<td>
														{itm.date_queued}
													</td>
													<td>
														{itm.classification}
													</td>
													<td>
														{itm.symptoms.replace(/_/g,' ')}
													</td>
												</tr>
											)
										})
									}
								
							</tbody>
						</Table>



						</CollapsibleItem>
						</Collapsible>			
					)		  
					})
				}
			
		</div>
	: 
		<div >
			<Table responsive={true}>
				<thead>
					<tr>
					<th data-field="name">
						Name
					</th>
					<th data-field="date_queued">
						Queue Date
					</th>
					<th data-field="classification">
						Classification
					</th>
					<th data-field="symptoms">
						Symptom(s)
					</th>
					</tr>
				</thead>
				<tbody>
					
						{
							queItems.map((itm, idx)=>{
								console.log(itm)
								return(
									<tr key={`test_row_${idx}`}>
										<td>
											
											<a href="#!" onClick={()=>goMedicalRecords(itm)}>{itm.client_info.last_name +  ' ' + itm.client_info.first_name}</a>
										</td>
										<td>
											{itm.date_queued}
										</td>
										<td>
											{itm.classification}
										</td>
										<td>
											{itm.symptoms.replace(/_/g,' ')}
										</td>
									</tr>
								)
							})
						}
					
				</tbody>
			</Table>
		</div>
	}
	</div>



    </div>
</div>)
}

export default ProviderWaitingroom

