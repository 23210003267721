import { Auth } from 'aws-amplify';

import csv from './zipcode.csv'
import Papa from 'papaparse';


export const client_info = {
    id: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    gender: '',
    date_of_birth: '',
};

export const initialLoginDataState = { 
    username : ''
    , email : ''
    , provider : ''
    , role : ''
    , authenticated : ''
    ,group : ''
    , clientid : ''
    , sub: ''
    , given_name: ''
    , email_verified: ''
    , phone_verified: '' 
};

export const initialGuardianGraphQL = { id: 'na', last_name: '', first_name: '', middle_name: '', contact_number: '', read_only: false}

export const initialClientsInfoGraphQL = {
    id: ''
    ,first_name:  ''
    ,last_name:  ''
    ,date_of_birth:  ''
    ,city_of_birth:  ''
    ,nickname:  ''
    ,middle_name:  ''
    ,gender:  'M'
    ,guardian_id_1:  'na'
    ,guardian_id_2:  'na'
    ,pcp_id:  'na'
    ,ocp_id: 'na'
    ,other_physicians: ''
    ,agree_terms_of_use:  'false'
    ,date_agreed_terms_of_use:  ''
    ,agree_privacy_policy:  'false'
    ,date_agreed_privacy_policy:  ''
    ,consent_of_telehealth:  'false'
    ,date_consented_of_telehealth:  ''
    ,is_provider:  'false'
    ,unique_identifier: ''
    ,user_action: ''
    ,created_date:  ''
    ,created_by:  ''
    ,last_update_date:  ''
    ,last_updated_by:  ''
    ,_version:0
    ,_deleted:''
    ,_lastChangedAt:''
    ,createdAt:''
    ,updatedAt:''
}

export const initialClientsProfileInfoGraphQL = {
    id: ''
    ,client_id: ''
    ,sub: ''
    ,email_address: ''
    ,mobile_country_code: '+1'
    ,mobile_phone_number: ''
    ,street_address_line_1: ''
    ,street_address_line_2: ''
    ,city: ''
    ,state_code: ''
    ,zip_code_1: ''
    ,zip_code_2: ''
    ,country_code: 'US'
    ,country: 'United States'
    ,user_name:''
    ,internal_account_type:''
    ,created_date: ''
    ,created_by: ''
    ,last_updated_date: ''
    ,last_updated_by: ''
    ,_version:0
    ,_deleted:''
    ,_lastChangedAt:''
    ,createdAt:''
    ,updatedAt:''
}

export const initialClientsProviderInfoGraphQL = {
    id: ''
    ,client_id: ''
    ,work_street_address_line_1: ''
    ,work_street_address_line_2: ''
    ,work_city: ''
    ,work_state_code: ''
    ,work_zip_code_first5: ''
    ,work_zip_code_last4: ''
    ,work_country_code: 'US'
    ,npi_id: ''
    ,npi_status: ''
    ,credential_status: ''
    ,status: ''
    ,taxonomy: ''
    ,created_date: ''
    ,created_by:''
    ,last_updated_date: ''
    ,last_updated_by: ''
    ,_version:0
    ,_deleted:''
    ,_lastChangedAt:''
    ,createdAt:''
    ,updatedAt:''
}


export const initUserProfileGraphQL ={
    id: ''
    ,last_name: ''
    ,first_name: ''
    ,middle_name: ''
    ,nick_name: ''
    ,gender: 'M'
    ,status: 'S'
    ,email: ''
    ,role: 'client'
    ,country: 'United States'
    ,country_code: 'US'
    ,state: ''
    ,city: ''
    ,street_address_1: ''
    ,street_address_2: ''
    ,zip_code1: ''
    ,zip_code2: ''
    ,mobile_country_code: '1'
    ,mobile_number: ''
    ,birthdate: ''
    ,city_of_birth: ''
    ,uniqueIdentifier: ''
    ,guardian1: 'na'
    ,guardian2: 'na'
    ,guardian3: 'na'
    ,guardian4: 'na'
    ,agreed_consent: 'false'
    ,agreed_consent_date: ''
    ,agreed_date_policy: ''
    ,agreed_date_terms: ''
    ,agreed_policy: 'false'
    ,agreed_terms: 'false'
    ,is_provider: 'false'
    ,created_by: ''
    ,creation_date: ''
    ,last_updated_by: ''
    ,last_modified_date: ''
    ,user_action: ''
}


export const initUserProfile ={
    pk: ''
    , sk: ''
    , role: 'client'
    , uniqueIdentifier : ''
    , lastName : ''
    , firstName : ''
    , middleName : ''
    , nickName : ''
    , status : 'S'
    , birthdate : ''
    , gender : 'M'
    , email : ''
    , country : 'United States'
    , countryCode : 'US'
    , state : ''
    , city : ''
    , streetAddress1 : ''
    , streetAddress2 : ''
    , zipCode1 : ''
    , zipCode2 : ''
    , mobile_country_code : ''
    , mobile_number : ''
    , cityOfBirth : ''
    
    , guardian1_lastName: ''
	, guardian1_firstName: ''
	, guardian1_middleName: ''
	, guardian1_contact: ''
    , guardian1_clientId: ''
    
    , guardian2_lastName: ''
	, guardian2_firstName: ''
	, guardian2_middleName: ''
    , guardian2_contact: ''
    , guardian2_clientId: ''
    
    , guardian3_lastName: ''
	, guardian3_firstName: ''
	, guardian3_middleName: ''
	, guardian3_contact: ''
    , guardian3_clientId: ''

    , guardian4_lastName: ''
	, guardian4_firstName: ''
	, guardian4_middleName: ''
	, guardian4_contact: ''
    , guardian4_clientId: ''
    
	, provider: ''
    , agreedTerms: 'false'
    , agreedDateTerms: ''
		
	, agreedPolicy: 'false'
	, agreedDatePolicy: ''
		
	, agreedConsent: 'false'
	, agreedConsentDate: ''
	, isProvider: 'false'

    , npiId : ''
    , npiStatus : ''
    , credentialStatus : ''
    , providerStatus : ''
    , providerProcessingStatus: ''
    , attachments : []

    , createdBy : ''
    , creationDate : ''
    , lastUpdatedBy : ''
    , lastModifiedDate : ''

    , userAction: ''
}

export const storeItem = (p_name, p_data) =>{
    try{
        localStorage.setItem(p_name, JSON.stringify(p_data));
        return true;
    }catch(error){
        return false;
    }
}

export const getItem = (p_name) =>{
    try{
        let items = localStorage.getItem(p_name);
        let parsedData = JSON.parse(items);
        return parsedData;
    }catch(error){
        return '';
    }
}

export const getHygeiaStoreItem = (p_name) =>{
    try{
        let items = JSON.parse(localStorage.getItem(p_name));
       
        return items;
    }catch(error){
        return '';
    }
}



export const genKey = () => {
	var d = new Date();
	var n =  d.getTime();

	let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	let str = '';
	for (let i = 0; i < 4; i++) {
		str += chars.charAt(Math.floor(Math.random() * chars.length));
	}

	str = str + n.toString();
	return str;
}


export const initUserList = {
    
    Username : '',
    Enabled : '',
    UserCreatedDate: '',
    UserStatus: '',
    Attributes: []
    
};

export function convertDateStringToDate(dateStr) {
    let d = new Date(dateStr);
    let month = (d.getMonth() + 1).toString().length === 1 ? '0' + (d.getMonth() + 1).toString() : d.getMonth() + 1;
    let day = d.getDate().toString().length === 1 ? '0' + d.getDate().toString() : d.getDate().toString();
    let str =  month + '/' + day + '/' + d.getFullYear();
    return str;
}


function findValueByValue(array, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i] === value) {
            return 'admin';
        }
    }
    return "";
}
  
export async function userCredentials (fromLocation) {
    let userCred = initialLoginDataState;
    
    



    await Auth.currentAuthenticatedUser()
      .then(
          
        user => {
            
          let identities = [];
          let prov = '';
          
          if(user.attributes.identities) {
            identities = JSON.parse(user.attributes.identities);
            prov = identities[0].providerName.toUpperCase(); 
            if(prov==='')prov = 'COGNITO';
          }else{
            prov = 'COGNITO';
          }

          let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
          
          let crole = findValueByValue(groups, "admins")
          if(crole === "") crole = user.attributes["custom:role"]
          
          let nickname = titleCase(user.attributes["given_name"])

          if(user.attributes["nickname"]){
            if(user.attributes["nickname"]!==null && user.attributes["nickname"]!==undefined && user.attributes["nickname"]!==''){
                nickname = titleCase(user.attributes["nickname"])
            }
          }

          let email_verified = 'false';
          let phone_verified = 'false';

        
          if(user.attributes["phone_number_verified"]===true){
            phone_verified = 'true';
          }
          

          if(user.attributes["email_verified"]===true){
            email_verified = 'true';
          }

          userCred = { username: user.username
            , email: user.attributes.email
            , provider: prov
            , role: crole
            , authenticated: 'yes' 
            , group: groups
            , clientid : user.attributes["custom:clientid"]
            , sub : user.attributes["sub"]
            , given_name: nickname
            , email_verified: email_verified
            , phone_verified: phone_verified
            
          };
         
          
    }
    ).catch(err => {
      return userCred;  
    });
    return userCred;
}

export const getNow = () => {
    var date = new Date();
    var dateStr =
    ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getDate()).slice(-2) + "/" + date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2)
    return dateStr
}

export const checkDate = (p_date) =>{
 
    let d = new Date(p_date);
    let sysDate = new Date();

    if(!d.getTime()){
      return ""
    }else{
      if( d > sysDate ) return ""
      
      let idx = d.getMonth() + 1;

      if(d.getFullYear() > new Date().getFullYear()){
        return ""
      }else{
        const mm = "0" + idx.toString();
        const dd = "0" + d.getDate();
        return mm.substring(mm.length-2,mm.length) + "/" + dd.substring(dd.length-2,dd.length) + "/" + d.getFullYear()
        //return mm.substr(mm.length-2,2) + "/" + dd.substr(dd.length-2,2) + "/" + d.getFullYear()
      }
    }
  }

  
export const titleCase = (str) =>  {
    if(str === null)return '';
    if(str === undefined)return '';
    if(str === '')return '';
    
    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
}

async function fetchCsv() {

    if(!localStorage.getItem("zipcodes")){
        let zipcodes_main = [];
        let from = 0;
        let prev = 0;
        let prev_state = '';

        const isNumber = (p_value) => /^\d+$/.test(p_value);

        Papa.parse(csv, {
        download: true,
        complete: function (input) {
            const records = input.data;
            prev_state = records[0][2];
            let zipcodes = [];

            records.forEach(async(item, i)=>{
                
                if(prev_state !== item[2]){
                    zipcodes_main.push({state: prev_state, zip: zipcodes});
                    zipcodes = [];
                    prev_state = item[2];
                }
                if(from === 0) {
                    from = item[3];
                    prev = item[3];
                }

                if (isNumber(item[3])){
                    if( i > 0 && i % 2 === 1 ){
                       
                        if( item[3] - prev > 1 ){
                            zipcodes.push({
                                from,
                                to: prev
                            })
                            from = item[3]
                        }
                    }
                }else{
                    zipcodes.push({
                        from,
                        to: prev
                    })
                    zipcodes.push({
                        from: item[3],
                        to: item[3]
                    })
                    prev = 0;
                    from = 0;
                }

                prev = item[3]

                
            })
            zipcodes_main.push({state: prev_state, zip: zipcodes});
            localStorage.setItem("zipcodes",JSON.stringify(zipcodes_main));
        }
        
        });
    }
    
    
   
    
}
export const checkZip1 = async (state, zip) =>{
    if(!localStorage.getItem("zipcodes")){
        await fetchCsv();
    }

    let valid = false;
    
    if(isNaN(zip))return false;

    if(state.length === 0){
        if(zip.length<5 || parseInt(zip)===0){
            valid=false;
        }else{
            valid=true;
        }
    }else{
    
        let zipcodes = JSON.parse(localStorage.getItem("zipcodes"));
        // let zipcodes = [
        //     {state: "NV", zip: 
        //         [
        //          {from: 88901, to:88901}
        //         ,{from: 88905, to:88905}
        //         ,{from: 89001, to:89049}

        //         ,{from: 89052, to:89053}
        //         ,{from: 89060, to:89061}
        //         ,{from: 89067, to:89067}
        //         ,{from: 89070, to:89070}
        //         ,{from: 89074, to:89074}
        //         ,{from: 89077, to:89077}
        //         ,{from: 89081, to:89087}
        //         ,{from: 89101, to:89199}
        //         ,{from: 89301, to:89301}
        //         ,{from: 89301, to:89301}
        //         ,{from: 89310, to:89319}
        //         ,{from: 89402, to:89415}
        //         ,{from: 89418, to:89452}
        //         ,{from: 89460, to:89460}
        //         ,{from: 89496, to:89496}
        //         ,{from: 89501, to:89513}
        //         ,{from: 89515, to:89515}
        //         ,{from: 89519, to:89523}
        //         ,{from: 89519, to:89523}
        //         ,{from: 89533, to:89533}
        //         ,{from: 89555, to:89557}
        //         ,{from: 89570, to:89570}
        //         ,{from: 89595, to:89595}
        //         ,{from: 89599, to:89599}
        //         ,{from: 89701, to:89706}
        //         ,{from: 89711, to:89714}
        //         ,{from: 89721, to:89721}
        //         ,{from: 89801, to:89802}
        //         ,{from: 89820, to:89826}
        //         ,{from: 89828, to:89828}
        //         ,{from: 89830, to:89835}
        //         ,{from: 89883, to:89883}
        //         ]
        //     },
        //     {state: "CA", zip: [{from: 94203, to:94209},{from: 90001, to:90089},{from:90209 , to:90213}]},
        //     {state: "IL", zip: [{from: 62701, to:62709}]},
        // ]
        for(const i in zipcodes){
           
            if(zipcodes[i].state === state){
                let z = parseInt(zip)
                
                for(const idx in zipcodes[i].zip){
                    
                    if(z >= zipcodes[i].zip[idx].from && z <= zipcodes[i].zip[idx].to){
                        console.log(z,zipcodes[i].zip[idx].from,zipcodes[i].zip[idx].to)  
                        valid = true;
                    }
                }
            }
        }
    }
    return valid;
}