import React,{useState, useEffect} from 'react'
import 'materialize-css';

import logo from '../../assets/imgs/logo_64.png'

import classification_codes from '../../utils/classifications.json'
import { API } from 'aws-amplify';
import { TextInput, Select, Tabs, Tab,Modal, Table, Preloader, Icon, Button } from 'react-materialize';
import * as EmailValidator from 'email-validator';
import { getNow } from '../../utils/initialstate'
import M from 'materialize-css';
import  CountryCode  from '../../components/formfields';
import { ShowMessageModal } from '../../components/confirm/';
import parsePhoneNumber from 'libphonenumber-js';
import './style.css';
import _ from 'lodash';

const PhysicianDetails = (props) => {
    
    
    const [message, setMessage] = useState({header: '', msg: ''})

    //const [providersList, setProvidersList] = useState([]);
    const [loader, setLoader] = useState(false);
    
    const [providerSearch, setProviderSearch] = useState({last_name: '',first_name:'',state_code:'',classification:'', skip:0, limit: 10, page: 1})
        
    const [physicianInfo, setPhysicianInfo] = useState({npi_id:'', primary_name:'', other_names:'', email:'', phone_no:'', invited:'', taxonomy:'', show:false})
    const [phone, setPhone] = useState({country_code:'+1', phone_no:''})
    
    const showMessage = (header, message) =>{
      let el = document.getElementById("idShowMessageModalLandingPage"); 
  
      setMessage({header: header, msg: message})
  
      let instance = M.Modal.getInstance(el);  
      instance.open();
    }

    const removePhysician = () =>{
      props.removePhysician(physicianInfo.npi_id);
    }

    const validatePhoneNumber = (phone) => {
      if (phone.length < 2) { return false }
      
      const phoneNumber = parsePhoneNumber(phone, "US");
      //if (phoneNumber.isPossible() && phoneNumber.isValid()) {
      if (phoneNumber.isPossible()) {
        return true;
      }
  
      return false;
    }


    const close = () =>{
      updatePhysician(physicianInfo.primary);
    }

    const sendInvite = () =>{

      setLoader(true);
      let tmpPhone = phone.country_code + phone.phone_no;

      if (EmailValidator.validate(physicianInfo.email) === true && validatePhoneNumber(tmpPhone) === true) {
          API.get('HygeiaInternalAPIs','/internal/sendinvite/',{
            
            queryStringParameters:{
              emailAddress: physicianInfo.email.trim(),
              phoneNumber: tmpPhone.trim(), 
              provider: physicianInfo.primary_name.trim(),
              client: props.clientDetails.last_name + ', ' + props.clientDetails.first_name
            }
          
          }).then((results)=>{
            showMessage("Send Invite:","Invite sent to " + physicianInfo.primary_name)
            setPhysicianInfo({...physicianInfo, invited: getNow(), show: false, phone_no: tmpPhone})
            setLoader(false);

          }).catch(err => { setLoader(false); });     

      }else{
        setLoader(false);
        showMessage("Send Invite:","Please input a valid email address / phone number before proceeding with the invite.")
      }

    }
  
    const updatePhysician = (primary = false) =>{  
      let tmpPhone = phone.country_code + phone.phone_no;

      props.pickedPrimaryPhysician(
        physicianInfo.npi_id
        , primary
        , physicianInfo.primary_name
        , physicianInfo.other_name
        , physicianInfo.taxonomy
        , physicianInfo.email
        , tmpPhone
        , physicianInfo.invited
        , false
      );
      let elem = document.getElementById(props.id);
      let instance = M.Modal.getInstance(elem);
      instance.close();
    }

    const handleChange = (e) => {
      const key = e.target.name;
      const val = e.target.value;
      setPhysicianInfo({...physicianInfo, [key]: val})
    }

    /*NOTE: useEffect below is causing re-rendering of other providers.*/
    // useEffect(() =>{

    //   updatePhysician(physicianInfo.primary)

    // },[physicianInfo.invited])

    useEffect( () => {
      
      setPhysicianInfo({...physicianInfo
        , npi_id : props.physicianDetails.npi_id
        , primary_name : props.physicianDetails.full_name
        , other_names : props.physicianDetails.other_names
        , invited: props.physicianDetails.invited
        , email: props.physicianDetails.email
        , phone_no: props.phone_no
        , taxonomy: props.physicianDetails.taxonomy
        , primary: props.physicianDetails.primary
      })

      if(props.physicianDetails.phone_no){
        if(props.physicianDetails.phone_no.length > 10){
          let tmpPhone = props.physicianDetails.phone_no.substring(
            props.physicianDetails.phone_no.length - 10, props.physicianDetails.phone_no.length)
  
          let tmpCountry = props.physicianDetails.phone_no.substring(0, props.physicianDetails.phone_no.length - 10)
          setPhone({...phone, phone_no: tmpPhone, country_code: tmpCountry})
        }
        
        if(props.physicianDetails.phone_no.length <= 10){
          setPhone({...phone, phone_no: props.physicianDetails.phone_no, country_code: "+1"})
        }
      }
      

      if(props.physicianDetails.show === true){
        let elem = document.getElementById(props.id);
        let instance = M.Modal.getInstance(elem);
        instance.open();
      }
    },[props.physicianDetails.npi_id, props.physicianDetails.primary])



    const clickedHideShow = () =>{
      props.hideShowPhysician(props.physicianDetails.npi_id);
    }
    
    const setAsPrimaryPhysician = (primary) => {
    
      updatePhysician(primary);
    }

    const getDays = (p_date) =>{

      if(p_date === undefined) return 15;

      if(p_date === '') return 15;
      
      let dateVariable = new Date(p_date);

      if (dateVariable === 'Invalid Date') return 15;

      let date1 = new Date(p_date);
      let date2 = new Date();
    
      let Difference_In_Time = date2.getTime() - date1.getTime();

      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;

    }

    return (
    
      
      <Modal
          actions={[
            <Button type="button"  modal="close" node="button" className="smallbutton green" style={{"marginRight":"10px"}} onClick={clickedHideShow}>Close</Button>            
          ]}
          bottomSheet={false}
          fixedFooter={false}
          id={props.id}
          open = {props.show}
          options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: false,
            startingTop: '4%'
          }}
        
          trigger={<a href="#!" className="secondary-content"><i className="material-icons green-text" onClick={clickedHideShow}>assignment_ind</i></a>}
          >
          
          <div>
          <img src={logo}/>
            {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}
          <div>
            <div>
                <div className="col s12">
                  <TextInput
                    icon={<Icon style={{"color":"#3e9462d3"}}>account_circle</Icon>}
                    id = {"id1" + props.id}
                    label="Name (First Name, Last Name)"
                    name="full_name" value={props.physicianDetails.full_name}
                    readOnly={true}
                    noLayout={true}
                  />
                </div>
                <div className="col s12">
                  <TextInput
                    icon={<Icon style={{"color":"#3e9462d3"}}>account_circle</Icon>}
                    id = {"id2" + props.id}
                    label="Other Name"
                    name="props.physicianDetails.other_name" value={props.physicianDetails.other_name}
                    readOnly = {true}
                    noLayout={true}
                  />
                </div>
                <div className="col s12">
                  <TextInput
                    icon={<Icon style={{"color":"#3e9462d3"}}>email</Icon>}
                    id = {"id3" + props.id}
                    label="E-mail"
                    name="email" 
                    value={physicianInfo.email} name="email" onChange={(e)=>{handleChange(e)}}
                    noLayout={true}
                  />
                  
                </div>
                <div className="col s12">
                 
                  <CountryCode id={"id5" + props.id}
                          mobile_number={phone.phone_no} 
                          country_code={phone.country_code} 
                          setValue={(e)=>{setPhone({...phone, country_code: e})}} 
                          setMobileNumber={(e)=>{setPhone({...phone, phone_no: e})}} 
                          />

                </div>
                <div className="col s12">
                  <label>Date Invited: {physicianInfo.invited}</label>
                </div>
                
                <div className="flex-container">
                    <div className="sm-button green"  onClick={(e) => updatePhysician(physicianInfo.primary)}>Update</div>  

                    {
                      getDays(physicianInfo.invited) > 14 ? 
                      <div className="sm-button green"  onClick={() => sendInvite()}>Send Invite</div>  
                      :
                      ''
                    }

                    {
                      physicianInfo.primary !== true ?
                      <div className="sm-button green"  onClick={() => setAsPrimaryPhysician(true)}>
                        Set as Primary Physician
                      </div> 
                      :
                      ''
                    }
                    
                </div>

                <div className="flex-container" style={{marginTop:"15px"}}>
                    <div className="sm-button green"  onClick={() => removePhysician()}>Remove from my list</div>  
                </div>
                
            </div>
                
          </div>
          </div>
          <ShowMessageModal id="idShowMessageModalLandingPage" msg={message.msg} header={message.header} close={close}/>
      </Modal>
      
    )
}

export default PhysicianDetails

