import React, { useEffect, useState, useContext, useRef } from 'react'
import 'materialize-css';
import M from 'materialize-css';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { Icon, TextInput, Select, Checkbox, Preloader, Collapsible, CollapsibleItem, Modal} from 'react-materialize';
import parsePhoneNumber from 'libphonenumber-js'
import * as EmailValidator from 'email-validator';
import Agreements1 from '../../components/agreements'
import { v4 as uuidv4 } from 'uuid';
import Confirm1 from '../../components/confirm';
import { useHistory } from "react-router-dom";
import country from '../../utils/country_codes.json'
import usa_state_codes from '../../utils/usa_state_codes.json'
//import { DataStore } from '@aws-amplify/datastore';
import logo from '../../assets/imgs/logo_64.png'
//import { Clients,ClientsProfile,ProvidersInfo } from '../../models';
import  CountryCode  from '../../components/formfields';
import PhysicianDetails from '../../components/physician';


//import { getClient } from '../../graphql/customqueries';

//import { createClient } from '../../graphql/custommutations';

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import NpiQuery from '../../components/npiquery';
import AppContext from '../../AppContext'
import { checkDate } from '../../utils/initialstate';
import './profile.css';


import { initialLoginDataState
  , userCredentials
  , initialGuardianGraphQL
  , convertDateStringToDate
  , initialClientsInfoGraphQL
  , initialClientsProfileInfoGraphQL
  , initialClientsProviderInfoGraphQL 
  , checkZip1
} from '../../utils/initialstate.js'


const Profile = (props) => {
  let history = useHistory();
  //let tempDates = '';

  const context = useContext(AppContext);
  const inputDateRef = useRef();
  const [clientPersonalInfo, setClientPersonalInfo] = useState(initialClientsInfoGraphQL);
  const [clientProfileInfo, setClientProfileInfo] = useState(initialClientsProfileInfoGraphQL);
  const [clientProviderInfo, setClientProviderInfo] = useState(initialClientsProviderInfoGraphQL);
 
  const [userLogState, setUserState] = useState(initialLoginDataState);
  //const [prevState, setPrevState] = useState({ email_address: '', mobile_country_code: '', mobile_phone_number: '' });
  const [guardianToVerify, setGuardianToVerify] = useState({id: '', last_name: '', first_name: '', contact_number: '', order: 1})
  
  const [openModal1,setModal1] = useState(false);
  const [openModal2,setModal2] = useState(false);
  const [openModal3,setModal3] = useState(false);
  const [openModal4,setModal4] = useState(false);
  const [openModal5,setModal5] = useState(false);
  
  const [verifyContactInformation, setVerifyContactInformation] = useState(false);

  const [verifyCodeItem, setVerifyCodeItem] = useState("phone_number");

  const [userPhone, setPhoneNumber] = useState({ contactNo: '' });
  const [errMessage, setErrMessage] = useState({ message: '' });
  const [loader, setLoader] = useState(false);
  const [isReadOnly, setReadOnly] = useState(true);
  
  const [isDirty, setIsDirty] = useState({personalInfo: false, profileInfo: false, providerInfo: false, guardian1: false, guardian2: false,physician: false})
  
  const [proxyId, setProxy] = useState("");
  const [subToken, setSubToken] = useState();

  const [listClientProfiles, setListClientProfiles] = useState({myList:[], myIndex:0});

  const [guardian1Profile, setGuardian1Profile] = useState(initialGuardianGraphQL);
  const [guardian2Profile, setGuardian2Profile] = useState(initialGuardianGraphQL);
  const [primaryPhysician, setPrimaryPhysician] = useState({last_name: '', first_name: '', contact_number: '', read_only: false});
  const [otherPhysician, setOtherPhysician] = useState({primary_name: '', other_names: '', contact_number: '', email:'', read_only: false});

  const [msgBox, setMsgBox] = useState({msg: "",header: "", show:false});
  
  const [activeTab, setActiveTab] = useState();

  //const location = useLocation();

  const [zipcodes, setZipCodes] = useState({contact_zip:'', provider_zip:''})

  const [physicians, setPhysicians] = useState({list: [{npi_id: '', full_name:'',other_name:'', email:'', invited:'', taxonomy: '', phone_no: '', show: false}], content:'', listCount:0})
  
  const [dateLength, setDateLength] = useState(0);

  const showMessage = (p_message,p_header) =>{
    
      setMsgBox({msg: p_message,header: p_header, show: true})
    
  }

  const removePhysician = (p_npi_id) =>{
    //  const [physicians, setPhysicians] = useState({list: [{npi_id: '', full_name:'',other_name:'', email:'', invited:'', taxonomy: '', phone_no: '', show: false}], content:'', listCount:0})

    let tmpPhysicians = JSON.parse(JSON.stringify(physicians.list));

    let tempNewList = [];

    
    
    for(const item of tmpPhysicians){
      if(item.npi_id !== p_npi_id && item.npi_id !== ''){
        tempNewList.push(item);
      }
    }
    console.log("WENT HERE: REMOVE PHYSICIAN", tempNewList);
    setPhysicians({...physicians,list: tempNewList, listCount: tempNewList.length, content:''});

    // for (const [key, value]  of Object.entries(tmpPhysicians)) {
    //    if(value.npi_id !== p_npi_id) {
    //      tmpPhysicians.splice(key, 1);
    //      let newList = JSON.parse(JSON.stringify(tmpPhysicians));
    //      setPhysicians({...physicians,list: newList, listCount: newList.length, content:''});
    //    }
    // }

  }

  const hideShowPhysician = (p_npi_id) =>{

    let tmpPhysicians = physicians.list;
    
    for(let i=0; i < tmpPhysicians.length; i++){
      if(tmpPhysicians[i].npi_id === p_npi_id){
        tmpPhysicians[i].show = false;
        break;
      }
    }

  }

  const pickedPrimaryPhysician = (p_npi_id, p_primary, p_fullname, p_other_names, p_taxonomy, p_email, p_phone_no, p_invited, p_show) => {
    setIsDirty({...isDirty, physician: true});
    console.log("pickedPrimaryPhysician Triggered", p_npi_id, p_primary, p_fullname)
    // if(p_primary===true){
    //   setClientPersonalInfo({...clientPersonalInfo, pcp_id: p_npi_id});  
    // }
    let tmpPhysicians = physicians.list;
    let tmpInsert = true;

    for (const [key, value] of Object.entries(tmpPhysicians)) {
     
      if(p_primary === true && p_npi_id !== value.npi_id) value.primary = false;
      
      if(value.npi_id === p_npi_id) {
        tmpInsert = false;
        value.email = p_email;
        value.taxonomy = p_taxonomy;
        value.phone_no = p_phone_no;
        value.show = p_show;
        value.full_name = p_fullname;
        value.primary = p_primary;
        value.invited = p_invited;
      }
    }

    if(tmpInsert){
      let tmpPhysician = {
        npi_id: p_npi_id
        , primary: p_primary
        , full_name: p_fullname
        , other_names: p_other_names
        , email: p_email
        , invited:p_invited
        , taxonomy: p_taxonomy
        , phone_no: p_phone_no
        , show: p_show
      }
      console.log("CAPTURED PROVIDER:", tmpPhysician)
      tmpPhysicians.push(tmpPhysician);

      setPhysicians({...physicians,list: tmpPhysicians, listCount: tmpPhysicians.length});
    }else{
      if(physicians.listCount > 0){
        let d = new Date();
        let k1 = d.getTime();
        
        let my_content = physicians.list.map((physician, index) => {
          if(physician.npi_id!==""){    
            return (
              <li className="collection-item" key={"i" + k1 + physician.npi_id + index}>
                <div>{physician.full_name} {physician.primary === true ? <label className="green-text">[ Primary Physician ]</label> : ''}
                  <PhysicianDetails key ={"physician" + k1 + physician.npi_id}  id={"id" + k1 + physician.npi_id} 
                    clientDetails = {clientPersonalInfo} 
                    physicianDetails = {physician} 
                    removePhysician = {removePhysician} 
                    hideShowPhysician ={hideShowPhysician}
                    pickedPrimaryPhysician = {pickedPrimaryPhysician}
                  />
                </div>
              </li>
            )
          }else{
            return(<React.Fragment></React.Fragment>);
          }
          
        })
        
        setPhysicians({...physicians, content: my_content})
  
      }
    }
  }
  

  const verifyCode = () =>{
    setModal4(false);

    let v_code = document.getElementById("txtVerifyContactInfo").value

    Auth.verifyCurrentUserAttributeSubmit(verifyCodeItem, v_code)
      .then(() => {
          showMessage("Verification successful. Thank you.")
          console.log('Verified');
      }).catch((e) => {
          showMessage("Verification failed. Please try again later.")
          console.log('failed with error', e);
    });
    document.getElementById("txtVerifyContactInfo").value = "";
    document.getElementById("idPsentVerificationAgain").innerHTML=""
  }

  /*
  * HANDLE CHANGE
  * 
  */
  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;
    
    if(key.indexOf('.') === -1){
      setClientPersonalInfo({ ...clientPersonalInfo, [key]: val });
    }
    else{
      let setter = key.split('.')[0]
      let name = key.split('.')[1]
      if(setter==="clientPersonalInfo"){
        setIsDirty({...isDirty, personalInfo: true});
        setClientPersonalInfo({ ...clientPersonalInfo, [name]: val });
      }
      if(setter==="clientProfileInfo"){
        setIsDirty({...isDirty, profileInfo: true});
        setClientProfileInfo({...clientProfileInfo, [name]: val})
      }
      if(setter==="clientProviderInfo"){
        setIsDirty({...isDirty, providerInfo: true});
        setClientProviderInfo({...clientProviderInfo, [name]: val})
      }
      if(setter==="guardian1Profile"){
        setIsDirty({...isDirty, personalInfo: true, guardian1: true});
        setGuardian1Profile({...guardian1Profile, [name]: val});
      }
      if(setter==="guardian2Profile"){
        setIsDirty({...isDirty, personalInfo: true, guardian2: true});
        setGuardian2Profile({...guardian2Profile, [name]: val});
      }
      if(setter === "primaryPhysician"){
        setIsDirty({...isDirty, personalInfo: true,  physician: true});
        setPrimaryPhysician({...primaryPhysician, [name]: val});
      }
      if(setter==="zipcodes"){
        setIsDirty({...isDirty, profileInfo: true});
        setZipCodes({...zipcodes, [name]: val });
      }
    }
  }

  const handleOnBlur = async (e) =>{
    const key = e.target.name;
    const val = e.target.value;
    
    if(key.indexOf('.') === -1){
      
    }else{
      let setter = key.split('.')[0]
      let name = key.split('.')[1]

      if(setter==="zipcodes" && name==="contact_zip"){
        
        if(clientProfileInfo.state_code===''){
          showMessage("Please pick a state first before inputting a value for zip code", "Zip code");
          return;
        }

        let zip = formatZip(val);
        let valid = await checkZip1(clientProfileInfo.state_code, zip.substr(0,5))
        
        let zip1 = "";
        let zip2 = "";

        if(valid){
          console.log("Valid zipcode", valid)
          zip1 = zip.substr(0,5);
          if(zip.length>5) zip2 = pad(zip.substr(5,zip.length),4);
          setZipCodes({...zipcodes, contact_zip: zip});
          setClientProfileInfo({...clientProfileInfo, zip_code_1: zip1, zip_code_2: zip2});
        }else{
          showMessage("Please check zip code", "Zip code")
        }
      }
      if(setter==="zipcodes" && name==="provider_zip"){
        let zip = formatZip(val);
        let valid = await checkZip1(clientProviderInfo.work_state_code, zip.substr(0,5))
        
        let zip1 = "";
        let zip2 = "";
        if(valid){
          zip1 = zip.substr(0,5);
          if(zip.length>5) zip2 = pad(zip.substr(5,zip.length),4);
          setZipCodes({...zipcodes, provider_zip: zip});
          setClientProviderInfo({...clientProviderInfo, work_zip_code_first5: zip1, work_zip_code_last4:zip2});
        }else{
          showMessage("Please check zip code", "Zip code")
        }
      }
    }

  }

  const formatZip = (zip) => {
    if(zip===undefined)return;

    let res = zip.replace(/-/g, "");
  
    if(res.length>5){
      res = res.substr(0,5)  + "-" + pad(res.substr(5,res.length),4)
      
    }
    
    if(res.length<=5){
      res = pad(zip,5)

    }

    return res;
  }

  const pad = (value, size) =>  {
    var s = "0000" + value;
    return s.substr(s.length-size);
  }

  /*
  * GO BACK
  * 
  */
  const goBack = () => {
    
    if(typeof history.location.state !== 'undefined'){
      if(typeof history.location.state.frompath !== 'undefined')
        history.push(history.location.state.frompath, { from: "/profile" });
    }

    if(typeof history.location.state !== 'undefined'){
      if(typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    }else{
      goHome();
    }
  }

  

  const goHome = () =>{
    
    history.push("/", { from: "/profile" });
  }

  /*
  * VERIFY GUARDIAN
  * 
  */
  const verifyGuardian = async () =>{
    let tmpid = document.getElementById("txtVerifyGuardianID").value;
    setModal2(false);
    const result = await API.graphql(
      graphqlOperation(queries.getClients, {id: tmpid})
    );

    if(clientPersonalInfo.id === tmpid){
      alert("Not allowed. Current profile is the same as the guardian's profile.");
      return;
    }

    if(result.data.getClients!==null){
      console.log("matched");
      let age = getAge(result.data.getClients.date_of_birth);

      if(age < 18){
        alert("Guardian is under 18. Please enter another guardian.");
        return;
      }

      if(guardianToVerify.order === 1){
        if(result.data.getClients.last_name === guardianToVerify.last_name.toUpperCase().trim() && result.data.getClients.first_name === guardianToVerify.first_name.toUpperCase().trim()){
          setClientPersonalInfo({...clientPersonalInfo, guardian_id_1: tmpid});
        }
      }
      if(guardianToVerify.order === 2){
        if(result.data.getClients.last_name === guardianToVerify.last_name.toUpperCase().trim() && result.data.getClients.first_name === guardianToVerify.first_name.toUpperCase().trim()){
          setClientPersonalInfo({...clientPersonalInfo, guardian_id_2: tmpid});
        }
      }
    }  
  }
  
  /*
  * CHECK DATE OF BIRTH 
  * 
  */
  // const checkDateOfBirth = (e) => {
  //   let dateVariable = new Date(e.target.value);

  //   console.log(dateVariable);
  //    if (dateVariable.toString().includes('Invalid Date')) {
  //      console.log("Invalid date: date of birth")
  //    } else {
  //      tempDates = convertDateStringToDate(dateVariable.toString());
  //      sessionStorage.bd = tempDates;
  //    }
  // }

  /*
  * VERIFY PHYSICIAN
  * 
  */
  const verifyPhysician = async () =>{
    let tmpid = document.getElementById("txtVerifyPhysicianNpiId").value;
    setModal3(false);

    
    const result = await API.graphql(
      graphqlOperation(queries.queryProvidersInfoByNpi, {npi_id: tmpid})
    );
    if(result.data.queryProvidersInfoByNpi.items.length > 0){
      result.data.queryProvidersInfoByNpi.items.forEach( async (item) =>{
        
        const tmp = await API.graphql(
          graphqlOperation(queries.getClients, {id: item.client_id})
        );
       
        if(tmp.data.getClients.last_name === primaryPhysician.last_name.toUpperCase().trim() && tmp.data.getClients.first_name === primaryPhysician.first_name.toUpperCase().trim()){
          setClientPersonalInfo({...clientPersonalInfo, pcp_id: tmp.data.getClients.id});
          console.log("Physician set");
         
        }
      })
    }
  }

  /*
  * SYNC NEW ACCOUNT WITH EXISTING ACCOUNT
  * 
  */
  const syncNewAccountWithExistingAccount = async() =>{
    
    let tmpid = document.getElementById("txtVerifyClientID").value;
    setModal1(false);
    if(tmpid.length>0){
      const result1 = await API.graphql(
        graphqlOperation(queries.queryClientsByUniqueIdentifier, {unique_identifier: clientPersonalInfo.unique_identifier})
      );

      if(result1.data.queryClientsByUniqueIdentifier.items.length===1){
        const result2 = await API.graphql(
          graphqlOperation(queries.getClients, {id: tmpid})
        );
        if(result1.data.queryClientsByUniqueIdentifier.items[0].id === result2.data.getClients.id){
          postToGraphQL("Sync Accounts",tmpid);
        }
       
      }
    }else{
      return;
    }
  }

  /*
  * POST TO GRAPHQL
  * Post data to DynamoDB via GraphQL 
  */
  const postToGraphQL = async (p_action, p_id = '') => {
    let primaryAccountExists = true;  
    let user_role = "client";

    const result = await API.graphql(
      graphqlOperation(queries.queryClientsByUniqueIdentifier, {unique_identifier: clientPersonalInfo.unique_identifier})
    );

    if( result.data.queryClientsByUniqueIdentifier.items.length === 0 ){
      primaryAccountExists = false; 
    }

    if(p_action === 'Create Record' && primaryAccountExists === false){  
      const gen_client_id = uuidv4();     
      
      let tmp_data = {...clientPersonalInfo, created_by: gen_client_id, user_action: "Create Record", id: gen_client_id};

      delete tmp_data._version;
      delete tmp_data._deleted;
      delete tmp_data._lastChangedAt;
      delete tmp_data.createdAt;
      delete tmp_data.updatedAt;
      

      console.log(tmp_data)
      const respClients = await API.graphql(
        graphqlOperation(mutations.createClients, {input:{...tmp_data}})
      );
      setClientPersonalInfo({...clientPersonalInfo, ...respClients.data.createClients});
      console.log(respClients.data.createClients);
      
      /*Add Client Profile Information: contact information*/
      const uuid = uuidv4();      
      let clientProfile = { ...clientProfileInfo, client_id: respClients.data.createClients.id, sub: userLogState.sub, id: uuid }
      delete clientProfile._version;
      delete clientProfile._deleted;
      delete clientProfile._lastChangedAt;
      delete clientProfile.createdAt;
      delete clientProfile.updatedAt;

      let respClientsProfile = await API.graphql(
        graphqlOperation(mutations.createClientsProfile, {input:{...clientProfile}})
      );
      setClientProfileInfo({...clientProfileInfo, ...respClientsProfile.data.createClientsProfile})
      console.log(respClientsProfile.data.createClientsProfile);
    
      if(clientPersonalInfo.is_provider === "true"){
        user_role = "provider";
        const uuid = uuidv4();      
        let providerInfo = {...clientProviderInfo, client_id: respClients.data.createClients.id, id: uuid, credential_status: 'NEW', status: 'NEW'}
        delete providerInfo._version;
        delete providerInfo._deleted;
        delete providerInfo._lastChangedAt;
        delete providerInfo.createdAt;
        delete providerInfo.updatedAt;
        delete providerInfo.client_info;

        let respClientsProviderProfile = await API.graphql(
          graphqlOperation(mutations.createProvidersInfo, {input:{...providerInfo}})
        );
        setClientProviderInfo({ ...clientProviderInfo,...respClientsProviderProfile.data.createProvidersInfo})
        console.log(respClientsProviderProfile.data.createProvidersInfo);
      }
      
      //setPrevState({ email_address: clientProfileInfo.email_address, mobile_country_code: clientProfileInfo.mobile_country_code, mobile_phone_number: clientProfileInfo.mobile_phone_number });
      updateCognitoProfile(respClients.data.createClients.id
        ,user_role
        ,respClients.data.createClients.first_name
        ,respClients.data.createClients.middle_name
        ,respClients.data.createClients.last_name
        ,respClients.data.createClients.date_of_birth
        ,respClientsProfile.data.createClientsProfile.email_address
        ,userPhone.contactNo
        ,respClientsProfile.data.createClientsProfile.sub
        ,respClients.data.createClients.nickname
        );
      setIsDirty({...isDirty, personalInfo: false, profileInfo: false, providerInfo: false, guardian1: false, guardian2: false, physician: false})
      showMessage("Successfully created your profile.","Client Profile")
      console.log('Done posting data');
    }
    
    if(p_action === 'Create Record' && primaryAccountExists === true){  
      /*Check Client Profile Information: contact information*/
      setModal1(true);
    }

    if(p_action ==="Sync Accounts"){

      const result =  await API.graphql(
        graphqlOperation(queries.getClients, {id: p_id})
      );
      setClientPersonalInfo({...clientPersonalInfo,...result.data.getClients});
      
      const result1 = await API.graphql(
         graphqlOperation(queries.queryClientsProfileByClientIdAndSub, {client_id: p_id, sub: { eq: userLogState.sub }})
      );

       if(result1.data.queryClientsProfileByClientIdAndSub.items.length === 0){
         const uuid = uuidv4();      
         let clientProfile = { ...clientProfileInfo, client_id: p_id, sub: userLogState.sub, id: uuid }
         delete clientProfile._version;
         delete clientProfile._deleted;
         delete clientProfile._lastChangedAt;
         delete clientProfile.createdAt;
         delete clientProfile.updatedAt;
         let resp = await API.graphql(
           graphqlOperation(mutations.createClientsProfile, {input:{...clientProfile}})
         );
         setClientProfileInfo({...clientProfileInfo, ...resp.data.createClientsProfile})
         updateCognitoProfile(result.data.getClients.id
          ,user_role
          ,result.data.getClients.first_name
          ,result.data.getClients.middle_name
          ,result.data.getClients.last_name
          ,result.data.getClients.date_of_birth
          ,resp.data.createClientsProfile.email_address
          ,userPhone.contactNo
          ,resp.data.createClientsProfile.sub
          ,result.data.getClients.nickname
          );
         setIsDirty({...isDirty, personalInfo: false, profileInfo: false, providerInfo: false, guardian1: false, guardian2: false, physician: false})
         showMessage("Successfully linked your profile.","Client Profile")
         console.log('Done posting data'); 

      }

      
    }


    if(p_action === 'Update Record'){
      if(!isDirty.personalInfo && !isDirty.profileInfo && !isDirty.providerInfo && !isDirty.guardian1 && !isDirty.guardian2 && !isDirty.physician)return;
      if(isDirty.personalInfo || isDirty.guardian1 || isDirty.guardian2 || isDirty.physician){
        let tmp_data1 = {...clientPersonalInfo};
        tmp_data1.user_action ='Update Record';
        console.log("UPDATE RECORD", tmp_data1);
        delete tmp_data1._deleted;
        delete tmp_data1._lastChangedAt;
        delete tmp_data1.createdAt;
        delete tmp_data1.updatedAt;
        
        const resp1 = await API.graphql(
          graphqlOperation(mutations.updateClients, {input:{...tmp_data1}})
        );
        setClientPersonalInfo({...clientPersonalInfo,...resp1.data.updateClients})  
      }
      
      if(isDirty.profileInfo){
        let tmp_data2 = {...clientProfileInfo}
        delete tmp_data2._deleted;
        delete tmp_data2._lastChangedAt;
        delete tmp_data2.createdAt;
        delete tmp_data2.updatedAt;
        console.log(tmp_data2);
        const resp2 = await API.graphql(
          graphqlOperation(mutations.updateClientsProfile, {input:{...tmp_data2}})
        );
        setClientProfileInfo({...clientProfileInfo,...resp2.data.updateClientsProfile})  
      }
      
      if(isDirty.providerInfo){
        let tmp_data3 = {...clientProviderInfo}
        delete tmp_data3._deleted;
        delete tmp_data3._lastChangedAt;
        delete tmp_data3.createdAt;
        delete tmp_data3.updatedAt;
        delete tmp_data3.client_info;
        
        if(tmp_data3.id !== "" && clientPersonalInfo.is_provider === "true"){
          user_role = "provider";
          console.log("Update went here")
          const resp3 = await API.graphql(
            graphqlOperation(mutations.updateProvidersInfo, {input:{...tmp_data3}})
          );
          setClientProviderInfo({...clientProviderInfo,...resp3.data.updateProvidersInfo})    
        } 
        
        if(tmp_data3.id === "" && clientPersonalInfo.is_provider === "true"){
          console.log("clientinfo");
          console.log(clientPersonalInfo);
          console.log(clientPersonalInfo.id);

          let checkProviderInfo = await API.graphql(graphqlOperation(queries.queryProvidersInfoByClientId,{client_id: clientPersonalInfo.id}));
          
          console.log(checkProviderInfo.data.queryProvidersInfoByClientId.items);
    
          if(checkProviderInfo.data.queryProvidersInfoByClientId.items.length>0){
            setClientProviderInfo({ ...clientProviderInfo,...checkProviderInfo.data.queryProvidersInfoByClientId.items[0]});
          }else{
            user_role = "provider";
            console.log("Update went here and created the new record")
            const uuid = uuidv4();      
            tmp_data3 = { ...clientProviderInfo, client_id: clientPersonalInfo.id, id: uuid }
            delete tmp_data3._version;
            delete tmp_data3._deleted;
            delete tmp_data3._lastChangedAt;
            delete tmp_data3.createdAt;
            delete tmp_data3.updatedAt;
            delete tmp_data3.client_info;
            console.log(tmp_data3)
            let respClientsProviderProfile = await API.graphql(
              graphqlOperation(mutations.createProvidersInfo, {input:{...tmp_data3}})
            );
            setClientProviderInfo({ ...clientProviderInfo,...respClientsProviderProfile.data.createProvidersInfo})
          }
          
        }
      }
      //setPrevState({ email_address: clientProfileInfo.email_address, mobile_country_code: clientProfileInfo.mobile_country_code, mobile_phone_number: clientProfileInfo });
      updateCognitoProfile(clientPersonalInfo.id
        ,user_role
        ,clientPersonalInfo.first_name
        ,clientPersonalInfo.middle_name
        ,clientPersonalInfo.last_name
        ,clientPersonalInfo.date_of_birth
        ,clientProfileInfo.email_address
        ,userPhone.contactNo
        ,clientProfileInfo.sub
        ,clientPersonalInfo.nickname
        );
      setIsDirty({...isDirty, personalInfo: false, profileInfo: false, providerInfo: false, guardian1: false, guardian2: false, physician: false})
      showMessage("Successfully updated your profile.","Client Profile")
    }

   
    //return resp;
  }

  /*
  * GET PROFILE FROM DYNAMO DB 
  * VIA GRAPHQL QUERY
  */
  const firstProfile = async() =>{
    if (proxyId !== "" && listClientProfiles.myList.length > 0) {
      setLoader(true);

      let tmpCurIndex = listClientProfiles.myIndex;

      if(tmpCurIndex === 0){
        showMessage("You currently on the first record.","Client Profile(s)");
        setLoader(false);
        return;
      }

      if(tmpCurIndex > 0){

        let respClientsProfile = await API.graphql(
          graphqlOperation(queries.queryClientsProfileByClientIdAndSub
            , {client_id: listClientProfiles.myList[0].client_id
            , sub: { eq:  listClientProfiles.myList[0].sub}})
        );

        setClientProfileInfo({...clientProfileInfo,...respClientsProfile.data.queryClientsProfileByClientIdAndSub.items[0]});
        setListClientProfiles({...listClientProfiles,myIndex: 0});

        showMessage("Client profile loaded successfully", "Client Profile(s)");
      }

      

      setLoader(false);
      
    }
  }

  const lastProfile = async () =>{
    if (proxyId !== "" && listClientProfiles.myList.length > 0) {
      setLoader(true);
      
      let tmpIndexCount = listClientProfiles.myList.length;
      let tmpCurIndex = listClientProfiles.myIndex;

      if(tmpCurIndex === tmpIndexCount-1){
        showMessage("You currently on the last record.","Client Profile(s)");
        setLoader(false);
        return;
      }

      tmpCurIndex = tmpIndexCount-1;

      let respClientsProfile = await API.graphql(
        graphqlOperation(queries.queryClientsProfileByClientIdAndSub
          , {client_id: listClientProfiles.myList[tmpCurIndex].client_id
          , sub: { eq:  listClientProfiles.myList[tmpCurIndex].sub}})
      );

      setClientProfileInfo({...clientProfileInfo,...respClientsProfile.data.queryClientsProfileByClientIdAndSub.items[0]});
      setListClientProfiles({...listClientProfiles, myIndex: tmpCurIndex});
      showMessage("Client profile loaded successfully", "Client Profile(s)");
      
      setLoader(false);
      
    }
  }

  const nextProfile = async () =>{
    
    if (proxyId !== "" && listClientProfiles.myList.length > 0) {
      setLoader(true);
      
      let tmpIndexCount = listClientProfiles.myList.length;
      let tmpCurIndex = listClientProfiles.myIndex;

      if(tmpCurIndex < tmpIndexCount && tmpCurIndex === tmpIndexCount-1){
        showMessage("You currently on the last record.","Client Profile(s)");
        setLoader(false);
        return;
      }

      if(tmpCurIndex < tmpIndexCount && tmpCurIndex !== tmpIndexCount-1){
        tmpCurIndex++;

        let respClientsProfile = await API.graphql(
          graphqlOperation(queries.queryClientsProfileByClientIdAndSub
            , {client_id: listClientProfiles.myList[tmpCurIndex].client_id
            , sub: { eq:  listClientProfiles.myList[tmpCurIndex].sub}})
        );

        setClientProfileInfo({...clientProfileInfo,...respClientsProfile.data.queryClientsProfileByClientIdAndSub.items[0]});
        setListClientProfiles({...listClientProfiles,myIndex: tmpCurIndex});
        showMessage("Client profile loaded successfully", "Client Profile(s)");
      }

      setLoader(false);
      
    }
    
  }

  const prevProfile = async() =>{
     
    if (proxyId !== "" && listClientProfiles.myList.length > 0) {
      setLoader(true);
      
      let tmpCurIndex = listClientProfiles.myIndex;

      if(tmpCurIndex === 0){
        showMessage("You currently on the first record.","Client Profile(s)");
        setLoader(false);
        return;
      }

      if(tmpCurIndex > 0){
        tmpCurIndex--;

        let respClientsProfile = await API.graphql(
          graphqlOperation(queries.queryClientsProfileByClientIdAndSub
            , {client_id: listClientProfiles.myList[tmpCurIndex].client_id
            , sub: { eq:  listClientProfiles.myList[tmpCurIndex].sub}})
        );

        setClientProfileInfo({...clientProfileInfo,...respClientsProfile.data.queryClientsProfileByClientIdAndSub.items[0]});
        setListClientProfiles({...listClientProfiles,myIndex: tmpCurIndex});
        showMessage("Client profile loaded successfully", "Client Profile(s)");
      }

      setLoader(false);
      
    }
    
  }
  
  const getUserProfileFromGraphQL = async () => {
    setLoader(true);

    let clid = "";
    let csub = "";

    if (proxyId !== "") {
      clid = proxyId;

      let respProfiles = await API.graphql(
        graphqlOperation(queries.queryClientsProfileByClientId, {client_id: clid, limit: 10,nextToken: subToken})
      );
      
      setListClientProfiles({myList: respProfiles.data.queryClientsProfileByClientId.items, myIndex: 0});

      csub = respProfiles.data.queryClientsProfileByClientId.items[0].sub;
      setSubToken(respProfiles.data.queryClientsProfileByClientId.nextToken);  
    }

    if (proxyId === "" && (userLogState.clientid !== '' && userLogState.clientid !== undefined)){
      console.log('Load profile')
      clid = userLogState.clientid;
      csub = userLogState.sub;

      console.log(clid)
      console.log(csub)
      
    }

    if (clid === "") {
      setLoader(false);
      return
    }

    let respClients = await API.graphql(
      graphqlOperation(queries.getClients, {id: clid})
    );
    
    setClientPersonalInfo({ ...clientPersonalInfo,...respClients.data.getClients})
    
    if(userLogState.role === 'admin' && respClients.data.getClients.id !== ''){
      // let el = document.getElementById("dtpkBirthdate"); 
      
      // let instance = M.Datepicker.getInstance(el);  
      // instance.setDate(respClients.data.getClients.date_of_birth);
   
      // tempDates = respClients.data.getClients.date_of_birth;
      // el.value = tempDates;
      // el.date = tempDates;
    }

    let respClientsProfile = await API.graphql(
      graphqlOperation(queries.queryClientsProfileByClientIdAndSub, {client_id: clid, sub: { eq: csub }})
    );

    if(respClientsProfile.data.queryClientsProfileByClientIdAndSub.items.length>0){
      setClientProfileInfo({ ...clientProfileInfo,...respClientsProfile.data.queryClientsProfileByClientIdAndSub.items[0]});
      //setPrevState({ email_address: clientProfileInfo.email_address, mobile_country_code: clientProfileInfo.mobile_country_code, mobile_phone_number: clientProfileInfo });
    }

    if(respClients.data.getClients.is_provider !== null){
      
      if(respClients.data.getClients.is_provider === "true"){
        console.log('Provider info')

        let respClientsProviderProfile = await API.graphql(
          graphqlOperation(queries.queryProvidersInfoByClientId, {client_id: clid, sub: csub})
        );
        if(respClientsProviderProfile.data.queryProvidersInfoByClientId.items.length>0)
        setClientProviderInfo({ ...clientProviderInfo,...respClientsProviderProfile.data.queryProvidersInfoByClientId.items[0]});
      }  

    }

    
    console.log('Done loading profile');
    setLoader(false);
  }

  /*
  * Use effect for posting data to Dynamo DB
  * Usually triggered by the updateProfile function
  */
  useEffect(() => {
    console.log('Use Effect:');

    if (clientPersonalInfo.user_action === 'NEW' && clientPersonalInfo.id.length===0) {
      console.log('Creating New Profile');
      setClientPersonalInfo({...clientPersonalInfo, user_action: 'Create Record'})
      postToGraphQL('Create Record');
      //updateCognitoProfile(userProfile.id.replace('CLIENTID#',''),userProfile.role,userProfile.first_name,userProfile.middle_name,userProfile.last_name,userProfile.birthdate,userProfile.email,userPhone.contactNo);
      //setUserProfile({...userProfile, userAction:''})
      setLoader(false);
    }

    if (clientPersonalInfo.user_action === 'UPDATE' && clientPersonalInfo.id.length>0) {
      setClientPersonalInfo({...clientPersonalInfo, user_action: 'Update Record'})
      postToGraphQL('Update Record');
      setLoader(false);
    }

  }, [clientPersonalInfo.user_action, clientPersonalInfo.unique_identifier])

  /*
  Other Physicians list
  npi_id: '', full_name:'',other_name:'', email:'', invited:'', taxonomy: ''
  */
  useEffect(() => {
    console.log('USE EFFECT FOR PHYSICIANS')
    console.log(physicians)
    
    if(physicians.listCount > 0){
      let d = new Date();
      let k1 = d.getTime();
      
      let my_content = physicians.list.map((physician, index) => {
        if(physician.npi_id!==""){    
          return (
            <li className="collection-item" key={"i" + k1 + physician.npi_id}>
              <div>{physician.full_name} {physician.primary === true ? <label className="blue-text">[ Primary Physician ]</label> : ''}
                <PhysicianDetails key ={"physician" + k1 + physician.npi_id}  id={"id" + k1 + physician.npi_id} 
                  clientDetails = {clientPersonalInfo} 
                  physicianDetails = {physician} 
                  removePhysician = {removePhysician} 
                  hideShowPhysician ={hideShowPhysician}
                  pickedPrimaryPhysician = {pickedPrimaryPhysician}
                />
              </div>
            </li>
          )
        }
        
      })

      setPhysicians({...physicians, content: my_content})

    }
  }, [physicians.listCount])


/*
* WORKAROUND in updating select option values when navigating thru the different profile information of a user
* Profile Information: Country and state 
*/
useEffect(() => {
  

  if(clientProfileInfo.id!=="" && (clientProfileInfo.state_code!=="" && clientProfileInfo.state_code!==null) && (clientProfileInfo.country_code!=="" && clientProfileInfo.country_code!==null)){
    console.log('Setup set items');
    Array.from(document.querySelector("#selState").options).forEach(function(option_element) {
    let option_value = option_element.value;
    if(option_value === clientProviderInfo.work_state_code) {
      document.getElementById("selState").value = option_element;
      }
    });
    Array.from(document.querySelector("#selCountry").options).forEach(function(option_element) {
      let option_value = option_element.value;
      if(option_value === clientProviderInfo.work_country_code) {
        document.getElementById("selCountry").value = option_element;
      }
    });
  }
  if(clientProfileInfo.id!==""){
    setZipCodes({...zipcodes, contact_zip: clientProfileInfo.zip_code_1 + "-" + clientProfileInfo.zip_code_2})
  }

  if(clientProfileInfo.sub === userLogState.sub && clientProfileInfo.internal_account_type !== userLogState.provider){
    setClientProfileInfo({...clientProfileInfo, user_name: userLogState.username, internal_account_type: userLogState.provider})
  }

  
}, [clientProfileInfo.id])

/*
* WORKAROUND in updating select option values when navigating thru the different profile information of a user
* Provider information: Work country and work state
*/
useEffect(() => {
    


  if(clientPersonalInfo.is_provider==="false")return;

  if(clientProviderInfo.id!==""){
    setZipCodes({...zipcodes, provider_zip: clientProviderInfo.work_zip_code_first5 + clientProviderInfo.work_zip_code_last4})
  }

  if(clientProfileInfo.id!=="" && clientProviderInfo.work_state !==""){
    Array.from(document.querySelector("#selStateWork").options).forEach(function(option_element) {
    let option_value = option_element.value;
    if(option_value === clientProfileInfo.state_code) {
      document.getElementById("selStateWork").value = option_element;
      }
    });
    Array.from(document.querySelector("#selCountryWork").options).forEach(function(option_element) {
      let option_value = option_element.value;
      if(option_value === clientProfileInfo.country_code) {
        document.getElementById("selCountryWork").value = option_element;
      }
    });
  }
}, [clientProviderInfo.id])

 /*
 * For Physician
 */
useEffect(() => {
    
  const checkUpdates = async () =>{
    console.log('Checking primary care physician...');
    
    if(!clientPersonalInfo.pcp_id) return;
    
    if(clientPersonalInfo.pcp_id === 'na'){
      setPrimaryPhysician({ ...primaryPhysician
        , last_name: ''
        , first_name: ''
        , contact_number: ''
        , read_only: false })  
    }

    if (clientPersonalInfo.pcp_id.length > 10) {
      setLoader(true);
      let will_read_only = true;
      const result = await API.graphql(
        graphqlOperation(queries.getClients, {id: clientPersonalInfo.pcp_id})
      );
      
      const result1 = await API.graphql(
        graphqlOperation(queries.queryClientsProfileByClientId, {client_id: clientPersonalInfo.pcp_id})
      );
      setLoader(false);
      result1.data.queryClientsProfileByClientId.items.forEach( async (item) =>{
        setPrimaryPhysician({ ...primaryPhysician
          , last_name: result.data.getClients.last_name
          , first_name: result.data.getClients.first_name
          , contact_number: item.mobile_country_code + item.mobile_phone_number
          , read_only: will_read_only })  
      })  
    }

    if (clientPersonalInfo.pcp_id.length === 10) {
      setLoader(true);
      console.log('Checking physician from NPI DB');

      let will_read_only = true;
      
      API.get('hygeiaexternalapi','/external/npi/',{
        queryStringParameters:{
          npi_id: clientPersonalInfo.pcp_id
        }
      }).then((results)=>{
        console.log(results);
        if(results.result_count > 0){
          setPrimaryPhysician({ ...primaryPhysician
            , last_name: results.results[0].basic.last_name
            , first_name: results.results[0].basic.first_name
            , contact_number: results.results[0].addresses[0].telephone_number
            , read_only: will_read_only })  
        }
        setLoader(false);
        // consolesetUserListProvider(results);
      }).catch(err => { setLoader(false); });   
    }
  }

  checkUpdates()

}, [clientPersonalInfo.pcp_id]);

 /*
 * For other physician
 */
useEffect(() => {
    
  const checkUpdates = async () =>{
    console.log('Checking physician...');
    //primary_name: '', other_names: '', contact_number: '', email:
    if(!clientPersonalInfo.ocp_id) return;
    
    if (clientPersonalInfo.ocp_id.length > 10) {
      setLoader(true);
      let will_read_only = true;
      const result = await API.graphql(
        graphqlOperation(queries.getClients, {id: clientPersonalInfo.ocp_id})
      );
      
      const result1 = await API.graphql(
        graphqlOperation(queries.queryClientsProfileByClientId, {client_id: clientPersonalInfo.ocp_id})
      );
      setLoader(false);
      result1.data.queryClientsProfileByClientId.items.forEach( async (item) =>{
        setOtherPhysician({ ...otherPhysician
          , last_name: result.data.getClients.last_name
          , first_name: result.data.getClients.first_name
          , contact_number: item.mobile_country_code + item.mobile_phone_number
          , read_only: will_read_only })  
      })  
    }

    if (clientPersonalInfo.ocp_id.length === 10) {
      setLoader(true);
      console.log('Checking physician from NPI DB');

      let will_read_only = true;
      
      API.get('hygeiaexternalapi','/external/npi/',{
        queryStringParameters:{
          npi_id: clientPersonalInfo.ocp_id
        }
      }).then((results)=>{
        console.log(results);
        if(results.result_count > 0){
          setOtherPhysician({ ...otherPhysician
            , primary_name: results.results[0].basic.first_name + ' ' + results.results[0].basic.last_name
            , other_names: results.results[0].other_names[0].first_name + ' ' + results.results[0].other_names[0].last_name
            , contact_number: results.results[0].addresses[0].telephone_number
            , email: ''
            , read_only: will_read_only })  
        }
        setLoader(false);
        // consolesetUserListProvider(results);
      }).catch(err => { setLoader(false); });   
    }
  }

  checkUpdates()

}, [clientPersonalInfo.ocp_id]) 


  /*
  *For Guardian
  */
  useEffect(() => {
    
    const checkUpdates = async () =>{

      console.log('Checking guardian...');
      if (clientPersonalInfo.guardian_id_1 !== 'na') {
        
        let dateVariable = new Date(clientPersonalInfo.date_of_birth);
        let will_read_only = false;

        if (dateVariable.toString().includes('Invalid Date')) {
          console.log("Invalid date of birth");
          return;
        } else {
          let age = getAge(dateVariable);
          if (age < 18 || userLogState.role!=='admin') {
            will_read_only = false;
          }
        }

        const result = await API.graphql(
          graphqlOperation(queries.getClients, {id: clientPersonalInfo.guardian_id_1})
        );
        const result1 = await API.graphql(
          graphqlOperation(queries.queryClientsProfileByClientId, {client_id: clientPersonalInfo.guardian_id_1})
        );

        result1.data.queryClientsProfileByClientId.items.forEach( async (item) =>{
          setGuardian1Profile({ ...guardian1Profile
            , last_name: result.data.getClients.last_name
            , first_name: result.data.getClients.first_name
            , middle_name: result.data.getClients.middle_name
            , contact_number: item.mobile_country_code + item.mobile_phone_number
            , read_only: false })
            return;
        })  
      }

      if (clientPersonalInfo.guardian_id_2 !== 'na') {
        const result = await API.graphql(
          graphqlOperation(queries.getClients, {id: clientPersonalInfo.guardian_id_2})
        );
        const result1 = await API.graphql(
          graphqlOperation(queries.queryClientsProfileByClientId, {client_id: clientPersonalInfo.guardian_id_2})
        );

        result1.data.queryClientsProfileByClientId.items.forEach( async (item) =>{
          setGuardian2Profile({ ...guardian2Profile
            , last_name: result.data.getClients.last_name
            , first_name: result.data.getClients.first_name
            , middle_name: result.data.getClients.middle_name
            , contact_number: item.mobile_country_code + item.mobile_phone_number 
            , read_only: false })
            return;
        })  
      }
    }

    checkUpdates()

  }, [clientPersonalInfo.guardian_id_1, clientPersonalInfo.guardian_id_2])


  /*
  * useEffect: Triggered when the client id of the logged in user contains a valid client id
  */
  useEffect(() => {
    if (userLogState.clientid !== '' && (proxyId === "" || proxyId === null || proxyId === undefined)) {
      getUserProfileFromGraphQL();
    }
  }, [userLogState.clientid])

  useEffect(() => {
    if (proxyId !=='' && userLogState.authenticated === 'yes') {
      getUserProfileFromGraphQL();
    }
  }, [proxyId])

  useEffect(() => {
    
    let tmpPhysicians1 = clientPersonalInfo.other_physicians;
    
    if(tmpPhysicians1.length>0){
      try{
         let tmpPysicians2 = JSON.parse(tmpPhysicians1)
        
         setPhysicians({...physicians, list: tmpPysicians2, listCount: tmpPysicians2.length});
      }catch(err){
        
      }
    }

  }, [clientPersonalInfo.id])

  /*
  * useEffect: Triggered on first load of the page
  */
  useEffect(() => {
    //DataStore.clear();

    
    document.getElementById("checkTermsOfUse").disabled = true;
    let loggedUser = initialLoginDataState;
    
    
    const getCreds = async () => {

      loggedUser = await userCredentials('FROM PROFILE');
      
      setUserState({ ...loggedUser });
      
      if(loggedUser.email_verified ==='false' || loggedUser.phone_verified === 'false'){
        setVerifyContactInformation(true);
      }

      if(props.location.state) {
        if (props.location.state.proxy) {
          setProxy(props.location.state.proxy)
        }
      }
      
      if(sessionStorage.session_p){
         if(sessionStorage.session_p !== ''){
          setProxy(sessionStorage.session_p)
         }
      }  

      if(clientPersonalInfo.id==='' && loggedUser.authenticated==='yes' && (loggedUser.clientid === '' || loggedUser.clientid === null || loggedUser.clientid === undefined)){ 
        setClientProfileInfo({...clientProfileInfo
          ,email_address: loggedUser.email
          ,sub: loggedUser.sub
          ,user_name: loggedUser.user_name
          ,internal_account_type: loggedUser.provider
        })
      }
      console.log("USER CONTEXT", context.userState)
      if (loggedUser.role === 'admin' || 
        (context.userState.clientid === undefined)) {
        setReadOnly(false);
      } else {
        setReadOnly(true);
      }

    }

    getCreds()
    var tabs = document.querySelectorAll(".tabs");
    M.Tabs.init(tabs, {});

    console.log("CONTEXT VALUE USER:", context.userState)

    if(context.chatLogs.gathering === true && context.chatLogs.registration === false ){
      //TO DO: SET VALUES BASED ON THE CHAT LOGS  
    }

    if(context.chatLogs.gathering === true && context.chatLogs.user_role ==='provider' && context.chatLogs.registration === true){
      //TO DO: SET THE DEFAULT TAB TO PROVIDER
    }
    
    var elems = document.querySelectorAll('.datepicker');
    var options = {yearRange: 90,};
    var instances = M.Datepicker.init(elems, options);

  }, [])

  useEffect(()=>{
    if(context.userState.authenticated === 'yes'){
      console.log("USER AUTHENTICATED:", context.userState)
    }
  },[context.userState])

  /*Validate phone or email. not both*/
  const validatePhoneNumber = () => {

    const phone = clientProfileInfo.mobile_phone_number;

    if (phone.length < 2) { return false }
    
    const phoneNumber = parsePhoneNumber(phone, "US");

    if (phoneNumber.isPossible() && phoneNumber.isValid()) {
      const checkedPhoneNumber = phoneNumber.formatInternational();
      setPhoneNumber({ contactNo: checkedPhoneNumber.split(' ').join('') });
      return true;
    }

    return false;
  }

  const enableAgreement = (v, a) => {
    setModal5(false);
    setIsDirty({...isDirty, personalInfo: true});
    //if(userProfile.id==='' && a===1 && v===true)
    document.getElementById("checkTermsOfUse").disabled = !v;
  }

  const getAge = (date) => {
    let mybirthdate = new Date(date)
    let today = new Date()
    let today_month = today.getMonth() + 1
    let age = today.getYear() - mybirthdate.getYear()

    if (
    	(today_month < mybirthdate.getMonth() + 1 
    	
        || 
        
        ((today_month === mybirthdate.getMonth() + 1) && (today.getDate() < 		mybirthdate.getDate())))) 		{
       age--;
    }

    return age;
  }

  const checkGuardian = (guardian) => {
    
    if (guardian === 1 && (guardian1Profile.last_name === '' || guardian1Profile.first_name === '' )) {
      
      alert("Please input your guardian's personal information (LAST NAME and FIRST NAME) and click verify again.")
      return;
    }

    if (guardian === 2 && (guardian2Profile.last_name === '' || guardian2Profile.first_name === '' )) {
      alert("Please input your guardian's personal information (LAST NAME and FIRST NAME) and click verify again.")
      return;
    }

    if(guardian === 1){
      setGuardianToVerify({id: '', last_name: guardian1Profile.last_name, first_name: guardian1Profile.first_name, middle_name: guardian1Profile.middle_name, contact_number: guardian1Profile.contact_number, order: 1})
    }

    if(guardian === 2){
      setGuardianToVerify({id: '', last_name: guardian2Profile.last_name, first_name: guardian2Profile.first_name, middle_name: guardian2Profile.middle_name, contact_number: guardian2Profile.contact_number, order: 2})
    }

    setModal2(true);
    
  }

  /*

  */
  const updateProfile = async (e) => {

    setErrMessage({ message: '' });

    if (await validateForm() === false) {
      return;
    }
    let myPhysicians = JSON.stringify(physicians.list);

    let customDate = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    //let processingStatus = '';
    //let credentialStatus = '';

    let temp_uniqueIdentifier = "LASTNAME#" + clientPersonalInfo.last_name.toUpperCase() + "#FIRSTNAME#" + clientPersonalInfo.first_name.toUpperCase() + "#BIRTHDATE#" + clientPersonalInfo.date_of_birth + "#CITYOFBIRTH#" + clientPersonalInfo.city_of_birth.toUpperCase().replace('CITY', '').trim()
    
    /*Need updating here for Admin users*/
    if (clientPersonalInfo.id.length > 0) {
        setClientPersonalInfo({
          ...clientPersonalInfo
          , unique_identifier: temp_uniqueIdentifier
          , last_name: clientPersonalInfo.last_name.toUpperCase()
          , first_name: clientPersonalInfo.first_name.toUpperCase()
          , middle_name: clientPersonalInfo.middle_name.toUpperCase()
          , city_of_birth: clientPersonalInfo.city_of_birth.toUpperCase().replace('CITY', '').trim()
          , other_physicians: myPhysicians
          , last_updated_by: userLogState.email
          , last_update_date: customDate
          , user_action: 'UPDATE'
        })
    }

    if (clientPersonalInfo.agree_terms_of_use  === "true" && clientPersonalInfo.agree_privacy_policy === "true" && clientPersonalInfo.consent_of_telehealth === "true" && clientPersonalInfo.id.length === 0) {
      setClientPersonalInfo({
        ...clientPersonalInfo
        , created_date: customDate
        , unique_identifier: temp_uniqueIdentifier
        , last_name: clientPersonalInfo.last_name.toUpperCase()
        , first_name: clientPersonalInfo.first_name.toUpperCase()
        , middle_name: clientPersonalInfo.middle_name.toUpperCase()
        , city_of_birth: clientPersonalInfo.city_of_birth.toUpperCase().replace('CITY', '').trim()
        , other_physicians: myPhysicians
        , last_update_date: customDate
        , last_updated_by: userLogState.email
        , user_action: 'NEW'
      })
    }
  }

  const proceedWithTheUpdate = (p) => {
    if (p === true) {
      if(!isDirty.personalInfo && !isDirty.profileInfo && !isDirty.providerInfo && !isDirty.guardian1 && !isDirty.guardian2 && !isDirty.physician)return;
      updateProfile();
    }
  }

  const updateUserAttributes = async (sub, role, firstName, middleName, lastName, birthDate, contactNumber,nickName) => {
    
    let apiName = 'AdminQueries';
    let path = '/updateUserAttributes';
    let myInit = {
      queryStringParameters: {
        "searchtext":sub,
        "lastname": lastName,
        "firstname": firstName,
        "middlename": middleName,
        "birthdate": birthDate,
        "contactno": contactNumber,
        "role": role,
        "nickname": nickName
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    const rest = await API.post(apiName, path, myInit);
    return rest;
  }

  const updateCognitoProfile = async (clientId, role, firstName, middleName, lastName, birthDate, email, contactNumber,sub,nickname) => {
    //&& props.userData.clientid !== proxyId
    birthDate = convertDateStringToDate(birthDate)
    if (userLogState.role === 'admin' && proxyId !== '') {
      
      await updateUserAttributes(sub, role, firstName, middleName, lastName, birthDate, contactNumber,nickname)
     

    } else {
      console.log("Updating Cognito Profile...");
      const user = await Auth.currentAuthenticatedUser();
      
      let cognitoData = {
        "custom:clientid": clientId,
        "custom:role": role,
        "family_name": lastName,
        "given_name": firstName,
        "middle_name": middleName,
        "birthdate": birthDate,
        "nickname": nickname,
        "phone_number": contactNumber
      };

      if (userLogState.provider.toUpperCase() === "COGNITO") {
        cognitoData = {
          ...cognitoData, "phone_number": contactNumber
        }
      }

      if (userLogState.provider.toUpperCase() === "FACEBOOK") {
        cognitoData = {
          ...cognitoData, "phone_number": contactNumber
        }
      }

      if (userLogState.provider.toUpperCase() === "GOOGLE") {
        cognitoData = {
           ...cognitoData, "phone_number": contactNumber
        }
      }
      console.log(cognitoData)
      /*
      Auth.verifyCurrentUserAttributeSubmit('phone_number', '696331')
      .then(() => {
          console.log('Verified');
      }).catch((e) => {
          console.log('failed with error', e);
      });
      */
      console.log(cognitoData);
      /*Need updating here for Admin users*/
      await Auth.updateUserAttributes(
        user,
        {
          ...cognitoData
        }).then(() => {
          /*
          Auth.verifyCurrentUserAttribute("phone_number")
          .then(() => {
              console.log('a verification code is sent');
          }).catch((e) => {
              console.log('failed with error', e);
          });
          */
          
          setUserState({ ...userLogState, clientid: clientId, role: role })

          verifyAttributes();

          console.log("Done updating profile with no error");
        }).catch(err => {
          console.log(err);
          console.log("Error updating your profile");
        });
    }
  }

  const verifyAttributes = async() => {
    let verifyPhone = false;
    let verifyEmail = false;
    
    await Auth.currentAuthenticatedUser()
      .then(user => {
        if(user.attributes["email_verified"]===false) {
          verifyEmail = true;
          setVerifyCodeItem("email");
          Auth.verifyCurrentUserAttribute("email")
          .then(() => {
          console.log('a verification code is sent');
          }).catch((e) => {
            console.log('failed with error');
          }); 
        }
       
        if(user.attributes["phone_number_verified"]===false){
          if(user.attributes["phone_number_verified"]===false) {
              verifyPhone = true;
              setVerifyCodeItem("phone_number");
              Auth.verifyCurrentUserAttribute("phone_number")
              .then(() => {
              console.log('a verification code is sent');
              }).catch((e) => {
                console.log('failed with error');
              }); 
          }
        }  
          
        if(verifyPhone || verifyEmail){
          setModal4(true);
        } 
        
        if(verifyPhone === false && verifyEmail === false){
          setVerifyContactInformation(false);
        }else{
          setVerifyContactInformation(true);
        }
    })
      .catch(err => {
      console.log(err)
    });
    
    
    
  }

  const clickCheckNPI = async() =>{
    let res = await checkNPI();
    console.log(res);

    if(res===false){
      showMessage("NPI Information did not match with your personal information","Provider Info:");
    }else{
      showMessage("NPI Information matched with your provider info","Provider Info:");
    }  
  }

  const checkNPI = async() =>{
    setLoader(true);
    let res = false;

    await API.get('hygeiaexternalapi','/external/npi/',{
      
      queryStringParameters:{
        npi_id: clientProviderInfo.npi_id
      }

    }).then((results)=>{
      if(results.result_count>0){
        /*1st pass*/
        if(results.results[0].basic.last_name === clientPersonalInfo.last_name 
          && results.results[0].basic.first_name.substring(0,3) === clientPersonalInfo.first_name.substring(0,3)){
            results.results[0].taxonomies.forEach(taxonomy => {
                if(taxonomy.primary === true){
                  console.log("Matched");
                  let zip1="";
                  let zip2="";
                  let zip="";
                  if(results.results[0].addresses[0].postal_code.length>5) 
                  {
                    zip1 = results.results[0].addresses[0].postal_code.substring(0, 5);
                    zip2 = results.results[0].addresses[0].postal_code.substring(5, 9);
                    zip = zip1 + "-" + zip2
                  }else{
                    zip1 = results.results[0].addresses[0].postal_code;
                  }
                  setZipCodes({...zipcodes, provider_zip: zip});
                  setClientProviderInfo({...clientProviderInfo
                    ,work_street_address_line_1: results.results[0].addresses[0].address_1
                    ,work_street_address_line_2: results.results[0].addresses[0].address_2
                    ,work_city: results.results[0].addresses[0].city
                    ,work_zip_code_first5: zip1
                    ,work_zip_code_last4: zip2
                    ,work_country_code: results.results[0].addresses[0].country_code
                    ,work_state_code: taxonomy.state
                    ,npi_status: results.results[0].basic.status
                  })
                  setLoader(false);
                  res = true;
                }
              }
            );
        }else{  
          console.log("Not Matched");
          setLoader(false);
        }
      }else{
        console.log("No records");
        setLoader(false);
      }
    }).catch(err => {setLoader(false);}); 

    return res;

  }

  const validateForm = async () => {

    console.log('Validation started');
    
    let valid = true;
    let eMessage = [];

    let age = 0;
    
    if (validatePhoneNumber() === false) {
      valid = false;
      eMessage.push("Invalid mobile number: Please verify your mobile contact number.");
    }

    let dateVariable = new Date(clientPersonalInfo.date_of_birth);
    
    if (dateVariable.toString().includes('Invalid Date')) {
      valid = false;
      eMessage.push("Birthdate: Invalid.");
    } else {
      age = getAge(dateVariable);
      
      if (age < 18 && (guardian1Profile.contact_number === '' && guardian2Profile.contact_number === '')) {
        valid = false;
        eMessage.push("Clients under 18 years of age are required to have at least one verified guardian");
      }
      if(age < 18 && clientPersonalInfo.is_provider === "true")
      {
        valid = false;
        eMessage.push("Clients under 18 years of age cannot be registered as providers");
      }
    }


    if (clientPersonalInfo.last_name.length === 0) {
      valid = false;
      eMessage.push("Last Name: Required.");
    }

    if (clientPersonalInfo.first_name.length === 0) {
      valid = false;
      eMessage.push("First Name: Required.");
    }

    if (clientPersonalInfo.city_of_birth.length === 0) {
      valid = false;
      eMessage.push("City of Birth: Required.");
    }

    if (EmailValidator.validate(clientProfileInfo.email_address) === false) {
      valid = false;
      eMessage.push("Invalid email address: Please verify your email address.");
    }


    let validZip1 = await checkZip1(clientProfileInfo.state_code,zipcodes.contact_zip.substr(0,5));

    if(validZip1===false){
      valid = false;
      eMessage.push("Zip code under contact information is not valid. Update your state or check your zip code.");
    }

    //let phoneNumber;
    
    //guardian 1
    if (guardian1Profile.first_name.length > 0 || guardian1Profile.last_name.length > 0 ) {
      if (guardian1Profile.first_name.length === 0) {
        valid = false;
        eMessage.push("Guardian 1: First name must be set based on the name registered by the guardian.");
      }

      
      if (guardian1Profile.last_name.length === 0) {
        valid = false;
        eMessage.push("Guardian 1: Last name must be set based on the name registered by the guardian.");
      }
    }

    //guardian 2
    if (guardian2Profile.first_name.length > 0 || guardian2Profile.last_name.length > 0 ) {

      if (guardian2Profile.first_name.length === 0) {
        valid = false;
        eMessage.push("Guardian 1: First name must be set based on the name registered by the guardian.");
      }

      if (guardian2Profile.last_name.length === 0) {
        valid = false;
        eMessage.push("Guardian 1: Last name must be set based on the name registered by the guardian.");
      }
    }

    if (clientPersonalInfo.agree_terms_of_use === "false" || clientPersonalInfo.agree_privacy_policy === "false" || clientPersonalInfo.consent_of_telehealth === "false") {
      valid = false;
      eMessage.push("Please read and accept agreements before submitting the form.");
    }

    if (clientPersonalInfo.is_provider === "true") {
      
      if ( clientProviderInfo.npi_id === null || clientProviderInfo.npi_id === undefined || clientProviderInfo.npi_id === "") {
        eMessage.push("NPI ID is required for providers");
        valid = false;
      }
      
      if ( clientProviderInfo.npi_status === null || clientProviderInfo.npi_status === undefined || clientProviderInfo.npi_status === "" ) {
        eMessage.push("NPI Status is required for providers");
        valid = false
      };
      
      if( clientProviderInfo.work_country_code === "" || clientProviderInfo.work_country_code === null || clientProviderInfo.work_country_code === undefined ){
        eMessage.push("Work country code is required for providers");
        valid = false
      }

      if( clientProviderInfo.work_state_code === "" || clientProviderInfo.work_state_code === null || clientProviderInfo.work_state_code === undefined ){
        eMessage.push("Work state code is required for providers");
        valid = false;
      }
      let res = await checkNPI();
      if(res===false){
        eMessage.push("NPI ID did not match with your personal information");
        valid = false;
      }
      
    }


    if(clientPersonalInfo.guardian_id_1 !=="na" && guardian1Profile.last_name ==="" && guardian1Profile.first_name === "" && guardian1Profile.middle_name ==="" && guardian1Profile.contact_number === "")
    {
      setClientPersonalInfo({...clientPersonalInfo, guardian_id_1: "na"})
    }

    if(clientPersonalInfo.guardian_id_1 !=="na" && guardian1Profile.last_name ==="" && guardian1Profile.first_name === "" && guardian1Profile.middle_name ==="" && guardian1Profile.contact_number === "")
    {
      setClientPersonalInfo({...clientPersonalInfo, guardian_id_1: "na"})
    }



    let myArrCreatedFromMap;
    if (eMessage.length > 0) {
      myArrCreatedFromMap = eMessage.map((item, i) => (<li className="red-text" key={item + i}>{item}</li>));
      showMessage(myArrCreatedFromMap,"User input checking / verification:")
    }

    console.log('Done with validation...');
    if (valid === false) {
      setErrMessage({ message: myArrCreatedFromMap });
      console.log(eMessage);
    }

    return valid;
  }

  function isNumberKey(evt) {
    var theEvent = evt || window.event;

    var key = theEvent.keyCode || theEvent.which;

    if ((key < 48 || key > 57) && !(key === 8 || key === 9 || key === 13 || key === 37 || key === 39 || key === 46) ){
        theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const verifyDate = () =>{
    let ret_date = checkDate(clientPersonalInfo.date_of_birth);
    if(ret_date === "Invalid Date"){
      setClientPersonalInfo({...clientPersonalInfo, date_of_birth: ''})
    }else if(ret_date === undefined){
      setClientPersonalInfo({...clientPersonalInfo, date_of_birth: ''})
    }else{
      let age = getAge(ret_date);
      console.log(age);
      setClientPersonalInfo({...clientPersonalInfo, date_of_birth: ret_date})
      
    }
  }

  const handleKeyUpDate = (evt) =>{
    
    evt = evt || window.event;
    const date = new Date();
    const yr = date.getFullYear();
    
    if(evt.keyCode !== 8){
      let chrCode = evt.target.value.charAt(evt.target.selectionStart - 1).charCodeAt();
      if((chrCode >= 48 && chrCode <= 57) || (chrCode >= 96 && chrCode <= 105)) {
        evt = evt || window.event;

        var size = inputDateRef.current.value.length;

        if ((size === 2 && inputDateRef.current.value > 12)
          || (size === 5 && Number(inputDateRef.current.value.split('/')[1]) > 31) 
          || (size === 10 && Number(inputDateRef.current.value.split('/')[2]) > yr)) {

              inputDateRef.current.value = ''; 
              setClientPersonalInfo({...clientPersonalInfo, date_of_birth: inputDateRef.current.value})
              alert('Invalid Date');
              return;
             
        }

        if(size >= 10){
          let date = new Date(inputDateRef.current.value);  
          if(!date.getTime()){
            inputDateRef.current.value = ''; 
            return;
          }else{
            inputDateRef.current.value = checkDate(inputDateRef.current.value);
          }
        }


        if ((size === 2 && inputDateRef.current.value < 13)
          || (size === 5 && Number(inputDateRef.current.value.split('/')[1]) < 32)) {

          if(dateLength < size && size > 0){
            inputDateRef.current.value += '/'; 
          }       
        } 
      }
    }
    setDateLength(size);
    setClientPersonalInfo({...clientPersonalInfo, date_of_birth: inputDateRef.current.value})
  }

  return (
    <div>
     

      <div className="white" >
        <nav>
          <div className="nav-wrapper hygeiabackground">
            <div>

              <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
              <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
              <a href="#!" className="breadcrumb" style={{ "paddingLeft": "10px" }}>Profile</a>

            </div>
          </div>
        </nav>
        
        {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}

        <div style={{ "margin": "20px" }}>

          <form onSubmit={updateProfile} id="frmProfile">

            
            <div className="row">
              <div className="col m12 s12">
                <TextInput id="txtClientID" noLayout={true}  label="Client ID:" readOnly={true} name="id" value={clientPersonalInfo.id} />
              </div>
            </div>
            
            <div className="row">

            <div className="col s12 no-pd">
            <ul className="tabs tabs-icon">
              <li className="tab col s4"><a className="active" href="#personalinfo" onClick={()=>{setActiveTab('PERSONAL INFORMATION')}}>
                <i className="material-icons">person</i><span className="hide-on-small-only">Personal Information</span></a></li>
              <li className="tab col s4"><a id="idContactInfo" href="#contactinfo" onClick={()=>{setActiveTab('CONTACT INFORMATION')}}>
                <i className="material-icons">contact_phone</i><span className="hide-on-small-only">Contact Information</span></a></li>
              {clientPersonalInfo.is_provider ==="true" ? 
              <li className="tab col s4"><a href="#providerinfoTAB" onClick={()=>{setActiveTab('PROVIDER INFORMATION')}}>
                <i className="material-icons">recent_actors</i><span className="hide-on-small-only">Provider Information</span></a></li> 
              :<li  className="tab col s4 disabled"><a href="#providerinfoTAB"></a></li>
              }
              
            </ul>
                <div className="col m12 s12 blue-grey lighten-4 hide-on-med-and-up">
                  <span className="hygeiafontsmall">{activeTab}</span>
                </div>
            </div>
            
            <div id="personalinfo" className="col s12">
              <div style={{"marginBottom":"15px"}}>{' '}</div>  
              <div className="row">
                {isReadOnly ? 
                <React.Fragment>
                <div className="col m4 s12">
                  <TextInput id="txtLastName" readOnly={isReadOnly} noLayout={true} name="clientPersonalInfo.last_name" label="Legal Last Name" 
                  value={clientPersonalInfo.last_name}  maxLength="50" minLength="2" />
                </div>
                <div className="col m4 s12">
                  <TextInput id="txtFirstName" readOnly={isReadOnly} noLayout={true} name="clientPersonalInfo.first_name" label="Legal First Name" 
                  value={clientPersonalInfo.first_name}   maxLength="50" minLength="2" />
                </div>
                <div className="col m4 s12">
                  <TextInput id="txtMiddleName" noLayout={true} name="clientPersonalInfo.middle_name" label="Middle Name" 
                  value={clientPersonalInfo.middle_name}  onChange={(e) => { handleChange(e) }} maxLength="50" />
                </div>
                </React.Fragment>
                : 
                <React.Fragment>
                <div className="col m4 s12">
                  <TextInput id="txtLastName1" readOnly={isReadOnly} noLayout={true} name="clientPersonalInfo.last_name" label="Legal Last Name" 
                  value={clientPersonalInfo.last_name} onChange={(e) => { handleChange(e) }} error="Required Field" required validate maxLength="50" minLength="2" />
                </div>
                <div className="col m4 s12">
                  <TextInput id="txtFirstName1" readOnly={isReadOnly} noLayout={true} name="clientPersonalInfo.first_name" label="Legal First Name" 
                  value={clientPersonalInfo.first_name} onChange={(e) => { handleChange(e) }} error="Required Field" required validate maxLength="50" minLength="2" />
                </div>
                <div className="col m4 s12">
                  <TextInput id="txtMiddleName1" noLayout={true} name="clientPersonalInfo.middle_name" label="Middle Name" 
                  value={clientPersonalInfo.middle_name} onChange={(e) => { handleChange(e) }} maxLength="50" />
                </div>
                </React.Fragment>
                }
                
              </div>
              

              
              <div className="row">
                <div className="col m4 s12">
                  
                  <div>
                    <TextInput ref={inputDateRef} id="txtDateOfBirth" name="clientPersonalInfo.date_of_birth"   onChange = {(e)=>{handleKeyUpDate(e)}} placeholder ="MM/DD/YYYY" label ="Date of birth" readOnly={isReadOnly} onBlur = {()=> verifyDate()} noLayout={true} value={clientPersonalInfo.date_of_birth} maxLength="30" />
                  </div>
                  
                </div>
                <div className="col m4 s12">
                  {isReadOnly ? 
                    <TextInput id="txtCityOfBirth" readOnly={isReadOnly} noLayout={true} name="clientPersonalInfo.city_of_birth" label="City of Birth" type="text" 
                    value={clientPersonalInfo.city_of_birth} maxLength="50" minLength="2" />
                  :
                    <TextInput id="txtCityOfBirth" noLayout={true} name="clientPersonalInfo.city_of_birth" label="City of Birth" type="text" 
                    value={clientPersonalInfo.city_of_birth} onChange={(e) => { handleChange(e) }} error={isReadOnly?"Read Only" : "Required Field"} 
                    required validate maxLength="50" minLength="2" />
                  }
                  
                </div>
                <div className="col m4 s12">
                    <TextInput id="txtNickName" noLayout={true} name="clientPersonalInfo.nickname" label="Nickname" value={clientPersonalInfo.nickname} onChange={(e) => { handleChange(e) }} maxLength="30" />
                </div>
              </div>
              
              <div className="row">
                  <div className="col m12 s12">
                    <fieldset>
                      <legend>Gender</legend>
                      <div className="col m3 s12">
                        <label>
                          <input id="txtMale" className="with-gap" name="gender" type="radio" value="M" checked={clientPersonalInfo.gender === "M"} onChange={(e) => { setClientPersonalInfo({ ...clientPersonalInfo, gender: e.target.value }); setIsDirty({...isDirty, personalInfo: true}); }} />
                          <span>Male</span>
                        </label>
                      </div>
                      <div className="col m3 s12">
                        <label>
                          <input id="txtFemale" className="with-gap" name="gender" type="radio" value="F" checked={clientPersonalInfo.gender === "F"} onChange={(e) => { setClientPersonalInfo({ ...clientPersonalInfo, gender: e.target.value }); setIsDirty({...isDirty, personalInfo: true}); }} />
                          <span>Female</span>
                        </label>
                      </div>
                    </fieldset>
                  </div>
              </div>
              <Collapsible accordion>
                  <CollapsibleItem expanded={false} header="Guardian 1" icon={<Icon>contact_phone</Icon>} node="div" style={{'display':'none'}}>
                    <div className="row">
                      <fieldset>
                        <legend>{clientPersonalInfo.guardian_id_1 === 'na' ? '' : <span>Account verified</span>}</legend>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian1LastName" type="text" readOnly = {guardian1Profile.read_only} placeholder="Last name" name="guardian1Profile.last_name" value={guardian1Profile.last_name} onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian1FirstName" type="text" readOnly = {guardian1Profile.read_only} placeholder="First name" name="guardian1Profile.first_name" value={guardian1Profile.first_name} onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian1MiddleName" type="text" readOnly = {guardian1Profile.read_only} placeholder="Middle name" name="guardian1Profile.middle_name" value={guardian1Profile.middle_name} onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian1ContactNo" type="text" readOnly = {guardian1Profile.read_only} placeholder="Contact Number" name="guardian1Profile.contact_number" value={guardian1Profile.contact_number} onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m8 s12">
                       
                            <div>
                            <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { checkGuardian(1) }}>Verify Guardian</a>
                            <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setClientPersonalInfo({...clientPersonalInfo, guardian_id_1:'na'}); setGuardian1Profile(initialGuardianGraphQL); setIsDirty({...isDirty, personalInfo: true, guardian1: true}); }}>Clear</a>
                            </div>
                         
                        </div>
                      </fieldset>
                    </div>
                  </CollapsibleItem>
                  <CollapsibleItem expanded={false} header="Guardian 2" icon={<Icon>contact_phone</Icon>} node="div" style={{'display':'none'}}>
                    <div className="row">
                      <fieldset>
                        <legend>Guardian 2 :{clientPersonalInfo.guardian_id_2 === 'na' ? '' : <span>Account verified</span>}</legend>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian2LastName" type="text" readOnly = {guardian2Profile.read_only} placeholder="Last name" value={guardian2Profile.last_name} name="guardian2Profile.last_name" onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian2FirstName" type="text" readOnly = {guardian2Profile.read_only} placeholder="First name" value={guardian2Profile.first_name} name="guardian2Profile.first_name" onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian2MiddleName" type="text" readOnly = {guardian2Profile.read_only} placeholder="Middle name" value={guardian2Profile.middle_name} name="guardian2Profile.middle_name" onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m4 s12">
                          <input id="txtGuardian2ContactNo" type="text" readOnly = {guardian2Profile.read_only} placeholder="Contact Number" value={guardian2Profile.contact_number} name="guardian2Profile.contact_number" onChange={(e) => { handleChange(e) }} maxLength="50" />
                        </div>
                        <div className="input-field col m8 s12">
             
                          <div>
                          <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { checkGuardian(2) }}>Verify Guardian</a>
                          <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setClientPersonalInfo({...clientPersonalInfo, guardian_id_2:'na'}); setGuardian2Profile(initialGuardianGraphQL); setIsDirty({...isDirty, personalInfo: true, guardian2: true}); }}>Clear</a>
                          </div> 
                     
                        </div>
                      </fieldset>
                    </div>
                  </CollapsibleItem>
                  
                  
                  <CollapsibleItem expanded={false} header="Provider(s)" icon={<Icon>recent_actors</Icon>} node="div" onSelect={(e) => { console.log(e) }}>
                    <div  style={{"paddingBottom":"30px"}}>
                      <ul className="collection with-header">
                        { physicians.content ? physicians.content : null }
                      </ul>
                      <div className="input-field col m12 s12">
                          <NpiQuery pickedPrimaryPhysician = {pickedPrimaryPhysician} primary={false} id="idOtherPhysician2"/>
                      </div>
                    </div>
                  </CollapsibleItem>
                </Collapsible>
                  
                <div className="row">
                  <div className="col m12 s12">
                    <fieldset>
                      <legend>Is Provider</legend>
                      <div className="col m3 s12">
                        <label>
                          <input id="txtIsProviderYes" className="with-gap" name="is_provider" type="radio" value="true" checked={clientPersonalInfo.is_provider === "true"} onChange={(e) => { setClientPersonalInfo({ ...clientPersonalInfo, is_provider: e.target.value.toString() }); setIsDirty({...isDirty, personalInfo: true}); }} />
                          <span>Yes</span>
                        </label>
                      </div>
                      <div className="col m3 s12">
                        <label>
                          <input id="txtProviderNo" className="with-gap" name="is_provider" type="radio" value="false" checked={clientPersonalInfo.is_provider === "false"} onChange={(e) => { setClientPersonalInfo({ ...clientPersonalInfo, is_provider: e.target.value.toString() }); setIsDirty({...isDirty, personalInfo: true}); }} />
                          <span>No</span>
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="row">
                  <div className="col m12 s12">
                    <fieldset>
                      <legend>{' '}e-Sign Agreement</legend>
                      <div className="col m3 s12">
                      <TextInput disabled id="txtAgreedDateTerms" label="Date " value={convertDateStringToDate(clientPersonalInfo.date_agreed_terms_of_use)} name="date_agreed_terms_of_use" onChange={(e) => { handleChange(e) }} />  
                      </div>
                      <div className="col m3 s12">
                      <Checkbox
                        id="checkTermsOfUse"
                        value={clientPersonalInfo.agree_terms_of_use}
                        checked={clientPersonalInfo.agree_terms_of_use === "true"}
                        label="-"
                        name="Agree terms of use"

                        onChange={(e) => {
                          e.target.checked ?
                            setClientPersonalInfo({
                              ...clientPersonalInfo
                              , agree_terms_of_use: e.target.checked.toString()
                              , date_agreed_terms_of_use: convertDateStringToDate(new Date().toString())
                              , agree_privacy_policy: e.target.checked.toString()
                              , date_agreed_privacy_policy: convertDateStringToDate(new Date().toString())
                              , consent_of_telehealth: e.target.checked.toString()
                              , date_consented_of_telehealth: convertDateStringToDate(new Date().toString())
                            })
                            :
                            setClientPersonalInfo({
                              ...clientPersonalInfo
                              , agree_terms_of_use: e.target.checked.toString()
                              , date_agreed_terms_of_use: ''
                              , agree_privacy_policy: e.target.checked.toString()
                              , date_agreed_privacy_policy: ''
                              , consent_of_telehealth: e.target.checked.toString()
                              , date_consented_of_telehealth: ''
                            })
                        }}
                      />
                      <a href="#!" onClick={()=>{setModal5(true)}} style={{"cursor":"pointer"}}>Agreement on Terms</a>
                      
                      
                      </div>
                    </fieldset>
                  </div>
                </div>
            </div>{ /* end of tab 1 */ }
                  <div id="contactinfo"  className="col s12">
                    <div className="row">
                        <div className="col m3 s12">
                          <TextInput readOnly id="txtEmail" noLayout={true} name="clientProfileInfo.email_address" error="Wrong Email format..." email label="Email Address" value={clientProfileInfo.email_address} />
                        </div>
                        <div className=" col m3 s12">
                          <CountryCode id="txtValidCountryCode" 
                          mobile_number={clientProfileInfo.mobile_phone_number} 
                          country_code={clientProfileInfo.mobile_country_code} 
                          setValue={(e)=>{setClientProfileInfo({...clientProfileInfo, mobile_country_code: e});setIsDirty({...isDirty, personalInfo: true})}} 
                          setMobileNumber={(e)=>{setClientProfileInfo({...clientProfileInfo, mobile_phone_number: e});setIsDirty({...isDirty, personalInfo: true})}} 
                          />
                        </div>
                        
                    </div>
                    <div className="row">
                      <div className="col m3 s12">
                        <Select
                          required
                          error="Required field"
                          validate
                          id="selCountry"
                            label="Country"
                            multiple={false}
                            options={{
                            classes: '',
                            dropdownOptions: {
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250
                            }
                            }}
                            value={clientProfileInfo.country_code}
                            onChange={(e) => { setClientProfileInfo({ ...clientProfileInfo, country: e.target.selectedOptions[0].text, countryCode: e.target.value }); setIsDirty({...isDirty, profileInfo: true}); }}
                            noLayout={true}
                          >
                            <option disabled value="">Choose your option</option>
                            {
                              country.map((data, key) => {
                              let arr = [];
                              if(data.Status==='ACTIVE')
                              arr.push(<option key={data.CountryCode} value={data.CountryCode}>{data.Country}</option>) 

                              return (
                                  arr
                              );
                              })
                              
                            }
                          </Select>
                  
                        </div>
                        <div className="col m3 s12">
                        <Select
                          required
                          error="Required field"
                          validate
                          id="selState"
                            label="State"
                            multiple={false}
                            options={{
                            classes: '',
                            dropdownOptions: {
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250
                            }
                            }}
                            value ={clientProfileInfo.state_code}
                            onChange={(e) => { setClientProfileInfo({ ...clientProfileInfo, state_code: e.target.value }); setIsDirty({...isDirty, profileInfo: true}); }}
                            noLayout={true}
                          >
                            <option disabled value="">Choose your state</option>
                            {
                              usa_state_codes.map((data, key) => {
                              let arr = [];
                              if(data.Status==='ACTIVE')
                              arr.push(<option key={data.StateCode} value={data.StateCode}>{data.State}</option>) 

                              return (
                                  arr
                              );
                              })
                              
                            }
                          </Select>
                        </div>
                        <div className="col m4 s12">    
                          <TextInput id="txtZipCode" noLayout={true} name="zipcodes.contact_zip" label="Zip Code" type="text" value={zipcodes.contact_zip} onChange={(e) => { handleChange(e) }} maxLength="10"  onKeyPress={(e)=>isNumberKey(e)} onBlur={(e)=>{ handleOnBlur(e)}}/>
                        </div>
                      </div>
                            
                      <TextInput id="txtStreetAddress1" noLayout={true} name="clientProfileInfo.street_address_line_1" label="Street Address line 1" value={clientProfileInfo.street_address_line_1} onChange={(e) => { handleChange(e) }} maxLength="100" />
                      <TextInput id="txtStreetAddress2" noLayout={true} name="clientProfileInfo.street_address_line_2" label="Street Address line 2" value={clientProfileInfo.street_address_line_2} onChange={(e) => { handleChange(e) }} maxLength="100" />
                      <TextInput id="txtCity" noLayout={true} name="clientProfileInfo.city" label="City" value={clientProfileInfo.city} onChange={(e) => { handleChange(e) }} />
                      <div className="row">
                      <div className="col m12 s12">
                        <TextInput id="txtSubId" noLayout={true}  label="Sub ID / Account Type " readOnly={true} name="sub" value={'[ ' + clientProfileInfo.sub +' ] [ '+ clientProfileInfo.internal_account_type.toUpperCase()+' ] '} />
                      </div>
                      </div>    
                  </div>{ /* end of tab 2 */ }

                  
                  
                  <div id="providerinfoTAB" className="col s12">
                    {userLogState.role === 'admin' && clientPersonalInfo.is_provider === "true" ?
                    <div className = "col s12">

                      <TextInput id="txtNpiId" name="clientProviderInfo.npi_id" value={clientProviderInfo.npi_id} label="NPI ID" onChange={(e) => { handleChange(e) }} minLength="1" maxLength="50" />
                      
                      <Select
                        id="selNpiStatus"
                        label="NPI Status"
                        multiple={false}
                        options={{
                          classes: '',
                          dropdownOptions: {
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250
                          }
                        }}
                        value={clientProviderInfo.credential_status == null ? '' : clientProviderInfo.npi_status}
                        onChange={(e) => { setClientProviderInfo({ ...clientProviderInfo, npi_status: e.target.value }); setIsDirty({...isDirty, providerInfo: true}); }}
                      >
                        <option disabled value="">Choose status</option>
                        <option value="N">NEW</option>
                        <option value="A">ACTIVE</option>
                        <option value="D">DEACTIVATED</option>
                        <option value="I">INACTIVE</option>
                      </Select>
                      
                      <Select
                        id="selCredentialStatus"
                        label="Credential Status"
                        multiple={false}
                        options={{
                          classes: '',
                          dropdownOptions: {
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250
                          }
                        }}
                        value={clientProviderInfo.credential_status == null ? '' : clientProviderInfo.credential_status}
                        onChange={(e) => { setClientProviderInfo({ ...clientProviderInfo, credential_status: e.target.value }); setIsDirty({...isDirty, providerInfo: true}); }}
                      >
                        <option disabled value="">Choose your option</option>
                        <option value="NEW">NEW</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INITIATED</option>
                        <option value="CONFIRMED">CONFIRMED</option>
                        <option value="REJECTED">REJECTED</option>

                      </Select>
                      <Select
                        id="selProviderStatus"
                        label="Provider Status"
                        multiple={false}
                        options={{
                          classes: '',
                          dropdownOptions: {
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250
                          }
                        }}
                        value={ clientProviderInfo.status == null ? '' : clientProviderInfo.status }
                        onChange={(e) => { setClientProviderInfo({ ...clientProviderInfo, status: e.target.value }); setIsDirty({...isDirty, providerInfo: true}); }}
                      >
                        <option disabled value="">Choose your option</option>
                        <option value="NEW">NEW</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                        <option value="REJECTED">REJECTED</option>
                      </Select>

                    </div>
                    :
                    <div>
                    <div className="col s12 m3">
                      <TextInput id="txtNpiIdAdmin" noLayout={true} name="clientProviderInfo.npi_id" label="NPI ID" value={clientProviderInfo.npi_id} onChange={(e) => { handleChange(e) }} />
                    </div>
                    
                    <div className="col s12 m3">
                      <Select
                          id="selNpiStatus"
                          label="NPI Status"
                          multiple={false}
                          options={{
                            classes: '',
                            dropdownOptions: {
                              alignment: 'left',
                              autoTrigger: true,
                              closeOnClick: true,
                              constrainWidth: true,
                              coverTrigger: true,
                              hover: false,
                              inDuration: 150,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              outDuration: 250
                            }
                          }}
                          value={clientProviderInfo.credential_status == null ? '' : clientProviderInfo.npi_status}
                          onChange={(e) => { setClientProviderInfo({ ...clientProviderInfo, npi_status: e.target.value }); setIsDirty({...isDirty, providerInfo: true}); }}
                        >
                          <option disabled value="">Choose status</option>
                          <option value="N">NEW</option>
                          <option value="A">ACTIVE</option>
                          <option value="D">DEACTIVATED</option>
                          <option value="I">INACTIVE</option>
                      </Select>
                    </div>

                    <div className="col s12 m3">
                      <TextInput id="txCredentialStatus" readOnly={true} noLayout={true} name="credential_status" label="Credential Status" value={clientProviderInfo.credential_status} />
                    </div>
                    <div className="col s12 m3">                      
                      <TextInput id="txtProvStatus" readOnly={true} noLayout={true} name="status" label="Status" value={clientProviderInfo.status} />
                    </div>
                    </div>
                  }
                  <div>
                    <div className="row">
                    <div className="col m6 s12">
                    <Select
                      required
                      error="Required field"
                      validate
                      id="selCountryWork"
                        label="Country"
                        multiple={false}
                        options={{
                        classes: '',
                        dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                        }
                        }}
                        value={clientProviderInfo.work_country_code}
                        onChange={(e) => { setClientProviderInfo({ ...clientProviderInfo, work_country_code: e.target.value });setIsDirty({...isDirty, providerInfo: true}); }}
                        noLayout={true}
                      >
                        <option disabled value="">Choose your option</option>
                        {
                          country.map((data, key) => {
                          let arr = [];
                          if(data.Status==='ACTIVE')
                          arr.push(<option key={data.CountryCode} value={data.CountryCode}>{data.Country}</option>) 

                          return (
                              arr
                          );
                          })
                          
                        }
                      </Select>
              
                    </div>
                    <div className="col m6 s12">
                    <Select
                      required
                      error="Required field"
                      validate
                      id="selStateWork"
                        label="State"
                        multiple={false}
                        options={{
                        classes: '',
                        dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                        }
                        }}
                        value={clientProviderInfo.work_state_code}
                      
                        onChange={(e) => { setClientProviderInfo({ ...clientProviderInfo, work_state_code: e.target.value }); setIsDirty({...isDirty, providerInfo: true}); }}
                        noLayout={true}
                      >
                        <option disabled value="">Choose your option</option>
                        {
                          usa_state_codes.map((data, key) => {
                          let arr = [];
                          if(data.Status==='ACTIVE')
                          arr.push(<option key={data.StateCode} value={data.StateCode}>{data.State}</option>) 

                          return (
                              arr
                          );
                          })
                          
                        }
                      </Select>
                    </div>
                    </div>
                    <div className="row">
                      <div className="col m6 s12">
                        <TextInput id="txtZipCodeWork" noLayout={true} name="zipcodes.provider_zip" label="Zip Code" type="text" value={zipcodes.provider_zip} onChange={(e) => { handleChange(e) }} maxLength="10"  onKeyPress={(e)=>isNumberKey(e)} onBlur={(e)=>{ handleOnBlur(e)}}/>
                      </div>
                    </div>
                  
                    <TextInput id="txtStreetAddress1Work" noLayout={true} name="clientProviderInfo.work_street_address_line_1" label="Street Address line 1" value={clientProviderInfo.work_street_address_line_1} onChange={(e) => { handleChange(e) }} maxLength="100" />
                    <TextInput id="txtStreetAddress2Work" noLayout={true} name="clientProviderInfo.work_street_address_line_2" label="Street Address line 2" value={clientProviderInfo.work_street_address_line_2} onChange={(e) => { handleChange(e) }} maxLength="100" />
                    <TextInput id="txtCityWork" noLayout={true} name="clientProviderInfo.work_city" label="City" value={clientProviderInfo.work_city} onChange={(e) => { handleChange(e) }} />
                    <TextInput id="txtTaxonomy" noLayout={true} name="clientProviderInfo.taxonomy" label="Taxonomy" value={clientProviderInfo.taxonomy} readOnly={true} />
                    
                  </div>  
                  <a href="#!" className="smallbutton green left" onClick = {()=>{
                    
                     
                    clickCheckNPI();
                    
                  }
                  }>Check Provider Info</a>

              </div>

            
      
            </div>{ /* TAB  */ }

            <hr></hr>
            <ul>{errMessage.message}</ul>
            
           
            {proxyId!=="" && activeTab==='CONTACT INFORMATION' ? 
              
              <div style={{"paddingLeft":"15px"}}>
                <div className="flex-container">
                    <div>
                    <a href="#!" className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  firstProfile() }}>{'|<'}</a>     
                    </div>
                    
                    <div>
                    <a href="#!" className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  prevProfile() }}>prev</a>      
                    </div>
                    
                    <div>
                    <a href="#!" className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  nextProfile() }}>next</a>     
                    </div>  
                    
                    <div>
                    <a href="#!" className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  lastProfile() }}>{'>|'}</a>    
                    </div>  
                </div>
              </div>
              :
              <React.Fragment></React.Fragment>
            }
            
          </form>
          <div>
            <label>Logged in user Account Type: </label>
            <label>[{' '}{userLogState.provider.toUpperCase()}{' '}]</label>
          </div>
              <div style={{"paddingLeft":"15px"}}>
              <div className="flex-container1" style={{"marginTop":"18px"}}>
                {verifyContactInformation === true ? 
                  <div>
                    <a href="#!" className="smallbutton green" style={{"minWidth":"160px","maxWidth":"160px"}}  onClick={() => { verifyAttributes()  }}>Verify contact info</a>    
                  </div> : <React.Fragment></React.Fragment>
                }
                  
                  <div>
                    <Confirm1 className="smallbutton green"  proceedWithTheUpdate={proceedWithTheUpdate} userProfile={clientPersonalInfo}/>     
                  </div>
              </div>
              </div>

          <Modal
            actions={[
              <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setModal1(false)  }}>Cancel</a>
              , <span>{'  '}</span>
              ,<a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { syncNewAccountWithExistingAccount() }}>Verify</a> 
            ]}
            bottomSheet={false}
            fixedFooter={false}
          
            id="modal1"
            open={openModal1}
            options={{
              dismissible: false,
              endingTop: '10%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt="Hygeia" src={logo}/>
            <div className="hygeiafontH1">Found record with the same personal info below:</div>
              <label>Last Name:</label><h6>{clientPersonalInfo.last_name}</h6><span>{' '}</span>
              <label>First Name:</label><h6>{clientPersonalInfo.first_name}</h6><br></br>
              <label>Date of Birth:</label><h6>{clientPersonalInfo.date_of_birth}</h6><span>{' '}</span>
              <label>CIty of Birth:</label><h6>{clientPersonalInfo.city_of_birth}</h6><br></br>
            <div className="hygeiafontH1">If this is your account, please enter your Client ID below:</div>
            <TextInput id="txtVerifyClientID" label="Client ID" name="clientIdToverify"/>
          </Modal>
          
          
          <Modal
            actions={[
              <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setModal2(false)  }}>Cancel</a>  
              , <span>{'  '}</span>
              ,<a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { verifyGuardian() }}>Verify</a> 
            ]}
            bottomSheet={false}
            fixedFooter={false}
            id="modal2"
            open={openModal2}
            options={{
              dismissible: false,
              endingTop: '10%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt = "Hygeia" src={logo}/>
            <div className="hygeiafontH1">To verify your guardian's account:</div>
            
            <label>Last Name:</label><h6>{guardianToVerify.last_name}</h6><br></br>
            <label>First Name:</label><h6>{guardianToVerify.first_name}</h6><br></br>
            <label>Contact Number:</label><h6>{guardianToVerify.contact_number}</h6><br></br>
          
            <div className="hygeiafontH1">Please enter your guardian's client id below:</div>
            <TextInput id="txtVerifyGuardianID" label="Client ID" name="guardianToVerify"/>
          </Modal>

          
          <Modal
            actions={[
              
              <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setModal3(false)  }}>Cancel</a>
              ,<span>{'  '}</span>
              ,<a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { verifyPhysician() }}>Verify</a>
            ]}
            bottomSheet={false}
            fixedFooter={false}
            id="modal3"
            open={openModal3}
            options={{
              dismissible: false,
              endingTop: '10%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt = "Hygeia" src={logo}/>      
            <div className="hygeiafontH1">To verify your physician:</div>
            <label>Last Name:</label><h6>{primaryPhysician.last_name}</h6><br></br>
            <label>First Name:</label><h6>{primaryPhysician.first_name}</h6><br></br>
            <div className="hygeiafontH1">Please enter your physician's NPI id below: </div>
            <TextInput id="txtVerifyPhysicianNpiId" label="NPI ID" name="PhysicianToVerify"/>
          </Modal>

          <Modal
            actions={[
              <a href="#!" className="smallbutton green" disabled style={{"margin":"8px"}} onClick={() => { setModal4(false)  }}>Later</a>
              ,<span>{'  '}</span>
              ,<a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { verifyCode() }}>Verify</a>
            ]}
            bottomSheet={false}
            fixedFooter={false}
            id="modal4"
            open={openModal4}
            options={{
              dismissible: false,
              endingTop: '10%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt="Hygeia" src={logo}/>      
            <div className="hygeiafontH1">Profile verification:</div>
            
            <p>To verify your changes, we sent verification code(s). Please check your sms/inbox and input the code in the text box provided below this message </p>
            <p>NOTE: An email will be sent for email address verification and sms message for Mobile Number verification.</p>
            <fieldset>
              <legend>Validate</legend>
              <div className="col m3 s12">
                <label>
                  <input id="txtValidatePhone" className="with-gap" name="verifyCodeItemPhone" type="radio" value="true" checked={verifyCodeItem === "phone_number"} 
                  onChange={(e) => { setVerifyCodeItem("phone_number") }} />
                  <span>Mobile Number</span>
                </label>
              </div>
              <div className="col m3 s12">
                <label>
                  <input id="txtValidateEmail" className="with-gap" name="verifyCodeItemEmail" type="radio" value="false" checked={verifyCodeItem === "email"} 
                  onChange={(e) => { setVerifyCodeItem("email") }} />
                  <span>Email</span>
                </label>
              </div>
            </fieldset>

            <p>Didn't receive the verification code... <a href="#!" onClick = {()=>{ 
              document.getElementById("idPsentVerificationAgain").innerHTML="Sent verification code..."
              Auth.currentAuthenticatedUser()
              .then(user => {
                if(user.attributes["email_verified"]===false) {
                 
                  Auth.verifyCurrentUserAttribute("email")
                  .then(() => {
                    console.log('a verification code is sent');
                  }).catch((e) => {
                    console.log('failed with error', e);
                  }); 
                }
               
                if(user.attributes["phone_number_verified"]){
                  if(user.attributes["phone_number_verified"]===false) {
                      Auth.verifyCurrentUserAttribute("phone_number")
                      .then(() => {
                        console.log('a verification code is sent');
                      }).catch((e) => {
                        console.log('failed with error', e);
                      }); 
                  }
                }  
              })
              .catch(err => {
                  console.log(err)
              });
                 

            }} style={{"cursor":"pointer"}}>send another</a></p>
            <p className = "red-text" id="idPsentVerificationAgain"></p>

            <TextInput id="txtVerifyContactInfo" label="Verification Code" name="VerificationCode" onChange={(e) => { console.log(e.target.value)  }}/>
          </Modal>

          <Modal
            actions={[
              <Agreements1 enableAgreement={enableAgreement}/>
              ,<span>{'  '}</span>
              ,<a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setModal5(false) }}>Cancel</a>
            ]}
            bottomSheet={false}
            fixedFooter={false}
            id="modal5"
            open={openModal5}
            options={{
              dismissible: false,
              endingTop: '10%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt="Hygeia" src={logo}/>      
            <div className="hygeiafontH1">Agreement on Terms:</div>
            
            <p>Please read the entire content and agree to all of the terms.</p>
          </Modal>

          <Modal
            actions={[
              <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setMsgBox({msg:"",header:"", show: false}) }}>Close</a>
            ]}
            bottomSheet={false}
            fixedFooter={false}
           
            id="msgBox"
            open={msgBox.show}
            options={{
              dismissible: false,
              endingTop: '5%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt="Hygeia" src={logo}/>
            <div className="hygeiafontH1">{msgBox.header}</div>
            <h6> {msgBox.msg} </h6>
            
          </Modal>
        </div>




      </div>
    </div>
  )
}

export default Profile
