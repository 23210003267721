// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React,{useState, useEffect, useContext} from 'react';
import { useParams } from "react-router-dom";
import AppContext from '../../AppContext'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { useHistory } from "react-router-dom";
import { TextInput, Modal} from 'react-materialize';
import logo from '../../assets/imgs/logo_64.png'
import Prescription from '../prescription';

import {
  AudioInputControl,
  AudioOutputControl,
  ControlBar,
  ControlBarButton,
  Phone,
  useMeetingManager,
  MeetingStatus,
  useMeetingStatus,
  VideoInputControl,
  VideoTileGrid,
  ContentShareControl,
  Notification,
} from 'amazon-chime-sdk-component-library-react';
import { endMeeting } from '../../utils/api';
import './chime.css';

const HygeiaMeeting = (props) => {
  const history = useHistory();
  const meetingManager = useMeetingManager();
  const params = useParams();
  const meetingStatus = useMeetingStatus();
  const [display, setDisplay] = useState('block');
  const context = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [sendDetails, setSendDetails] = useState({});

  const setModal = (p_state) =>{
    setOpenModal(p_state);

    if(p_state === false){
        history.push("/providerwaitingroom", { from: "providerwaitingroom" });
    }
    
  }

  const clickedEndMeeting = async () => {

    if(params.client_id && params.client_id.length > 10 && context.clientInfo.id && context.clientInfo.id.length >0){
      if(params.id && params.client_id !== context.clientInfo.id){
        let sessionQueue = await API.graphql(graphqlOperation(queries.getSessionQueues, {id: params.id}));
        let sessionContactInfo = await API.graphql(graphqlOperation(queries.queryClientsProfileByClientId, {client_id: params.client_id}));
      
      
        console.log(sessionQueue.data.getSessionQueues);
        console.log(sessionContactInfo.data.queryClientsProfileByClientId.items[0]);

        
        let sessionInfo = sessionQueue.data.getSessionQueues;
        let clientInfo = JSON.parse(JSON.stringify(sessionInfo.client_info));

          let s = new Date(parseInt(sessionInfo.session_start));
     

          let d = new Date();
     

          let minutes = (d - s)/1000;
          minutes /=60;
          minutes = Math.abs(Math.round(minutes));  

     
          
          delete sessionInfo.client_info;
          delete sessionInfo._deleted;
          delete sessionInfo._lastChangedAt;
          delete sessionInfo.createdAt;
          delete sessionInfo.updatedAt;

          sessionInfo.status = "end";
          sessionInfo.meeting_id = "";
          sessionInfo.session_end = d.getTime();
          sessionInfo.session_total_time = minutes;
          

        try{
            const results = await API.graphql(
              graphqlOperation(mutations.updateSessionQueues, {input:{...sessionInfo}})
            );
            
            //console.log("RESULTS AFTER UPDATE", results);
            //history.push("/providerwaitingroom", { from: "providerwaitingroom" });

            
            setSendDetails({
              emailAddress: sessionContactInfo.data.queryClientsProfileByClientId.items[0].email_address,
              patientName: clientInfo.last_name + ' ' + clientInfo.first_name,
              address: sessionContactInfo.data.queryClientsProfileByClientId.items[0].street_address_line_1,
              totalMinutes: minutes,
              meetingId: params.id
            });
            
            // API.get('HygeiaInternalAPIs','/internal/sendPrescription/',{
            
            //   queryStringParameters:{
            //     emailAddress: sessionContactInfo.data.queryClientsProfileByClientId.items[0].email_address,
            //     patientName: clientInfo.last_name + ' ' + clientInfo.first_name,
            //     address: sessionContactInfo.data.queryClientsProfileByClientId.items[0].street_address_line_1,
            //     totalMinutes: minutes,
            //     meetingId: params.id
            //   }
            
            // }).then((results)=>{
            //    console.log("Done sending prescription")
        
            // }).catch(err => { console.log("Error sending email") });  

            setModal(true);

          }catch(error){
            console.log(error);
          }
          
      }
    }
    
    const meetingId = meetingManager.meetingId;
    
    if (meetingId) {
      await endMeeting(meetingId, meetingId);
      await meetingManager.leave();
    }
  }
  
  const Severity = {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
  };

  useEffect(() => {
    
    console.log("Params at the Meeting",params);

    const checkInfo = async () =>{
      let sessionQueue = await API.graphql(graphqlOperation(queries.getSessionQueues, {id: params.id}));
      let sessionContactInfo = await API.graphql(graphqlOperation(queries.queryClientsProfileByClientId, {client_id: params.client_id}));
      
      
      console.log(sessionQueue.data.getSessionQueues.client_info.first_name);
      console.log(sessionContactInfo.data.queryClientsProfileByClientId.items[0]);
    }
    checkInfo();

  },[]);

  const sendPrescription =()=>{
    API.get('HygeiaInternalAPIs','/internal/sendPrescription/',{
            
      queryStringParameters: sendDetails
    
    }).then((results)=>{
       alert("Done sending prescription");
    }).catch(err => { console.log("Error sending email") });  
  }

  useEffect(() => {
    
    console.log("Meeting Status",meetingStatus);

  },[meetingStatus]);
  
  return (
     
      <div style={{marginTop: '2rem', height: '40rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <VideoTileGrid />
        {meetingStatus === MeetingStatus.Succeeded ?
          <ControlBar
            layout="undocked-horizontal"
            showLabels
          >
            <ContentShareControl />
            <AudioInputControl />
            <VideoInputControl />
            <AudioOutputControl />
            <ControlBarButton icon={<Phone />} onClick={clickedEndMeeting} label="End" />
          </ControlBar> 
          :
          <div>
          </div>
        }

          <Modal
            actions={[
              <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setModal(false); sendPrescription(); }}>Send</a> 
            ]}

            bottomSheet={false}
            fixedFooter={false}
          
            id="modal1"
            open={openModal}
            options={{
              dismissible: false,
              endingTop: '10%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img alt="Hygeia" src={logo}/>
            <Prescription/>
          </Modal>
      </div>
     
  );
};

export default HygeiaMeeting;
