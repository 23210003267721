import React,{useState,useEffect,useRef} from 'react'
import 'materialize-css';
import { Icon,Button,Modal, TextInput,Preloader,DatePicker as MaterializeDatePicker } from 'react-materialize';
import logo from '../../assets/imgs/logo_64.png';
import * as EmailValidator from 'email-validator';

//import g from '../../assets/imgs/btn_google_light_pressed_ios.svg';
import f from '../../assets/imgs/f_logo_RGB-Hex-Blue_512.png';
import { Auth } from 'aws-amplify';
import M from 'materialize-css';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

import { checkDate } from '../../utils/initialstate';

const DatePicker = (props) =>{
  const [dateValue1, setDateValue1] = useState('');
  const [invalid, setInvalid] = useState({invalid: '', error: ''});
  const inputRef = useRef();
  const inputRefTypeSize = useRef(0);
  

  const handleKeyUpDate = (evt) =>{
    let size;
    evt = evt || window.event;
    const date = new Date();
    const yr = date.getFullYear();
    console.log(evt.target.name);
    console.log(evt.target.value);

    if(evt.keyCode !== 8){
      let chrCode = evt.target.value.charAt(evt.target.selectionStart - 1).charCodeAt();
      if((chrCode >= 48 && chrCode <= 57) || (chrCode >= 96 && chrCode <= 105)) {
        evt = evt || window.event;

        size = inputRef.current.value.length;
        

        if((size === 2 && inputRef.current.value > 12) || (inputRef.current.value.split('/')[0] > 12)){
          inputRef.current.value = ''; 
          return;
        }

        // if(size > 3 && inputRef.current.value.split('/')[0] > 12){
        //   inputRef.current.value = ''; 
        //   return;
        // }



        if(size > 3 && inputRef.current.value.split('/')[1] > 31){
          inputRef.current.value = inputRef.current.value.split('/')[0] + '/'; 
          return;
        }
       
        if(size >= 10 && Number(inputRef.current.value.split('/')[2]) > yr){
          inputRef.current.value = inputRef.current.value.split('/')[0] + '/' + inputRef.current.value.split('/')[1] + '/'; 
          return;
        }

        // if ((size === 2 && inputRef.current.value > 12)
        //   || (size === 5 && Number(inputRef.current.value.split('/')[1]) > 31) 
        //   || (size === 10 && Number(inputRef.current.value.split('/')[2]) > yr)) {
        //     inputRef.current.value = ''; 
        //     return;
             
        // }
        if(size >= 10){
          let date = new Date(inputRef.current.value);  
          if(!date.getTime()){
            inputRef.current.value = ''; 
            
            setInvalid({...invalid, invalid:'invalid', error:'Invalid input. Please follow date format: mm/dd/yyyy'});
            return;
          }else{
            let temp_date = checkDate(inputRef.current.value);
            console.log('VALIDATED DATE', inputRef.current.value);
            console.log('VALIDATED DATE', temp_date);
            inputRef.current.value = temp_date;

            props.setValue(evt.target.name, temp_date);
            setInvalid({...invalid, invalid:'', error:''});
          }
        }

        if ((size === 2 && inputRef.current.value < 13)
          || (size === 5 && Number(inputRef.current.value.split('/')[1]) < 32)) {

          if(inputRefTypeSize.current < size && size > 0){
            inputRef.current.value += '/'; 
          }       
        } 
        
      }
    }
    inputRefTypeSize.current = size;
  }

  const checkDatePicker = () =>{
    var elems = document.getElementById(`id_${props.id}`);
    var instances = M.Datepicker.init(elems,{
      defaultDate: new Date(inputRef.current.value),
      setDefaultDate: true,
      autoClose: true,
      onSelect: function(e){pickDateSelected(e)},
      yearRange: 50
     
    });
    instances.open();
  }

  const pickDateSelected = (e) =>{
    console.log("PICK DATE TRIGGERED")
    let tempDate = checkDate(e);
    if(tempDate.length > 0){
      setDateValue1(tempDate);
    }else{
      setDateValue1('');
    }
  }
  
  useEffect(()=>{
    if(dateValue1 === ''){
      setInvalid({...invalid, invalid:'invalid', error: 'mm/dd/yyyy'});
    }else{
      props.setValue(props.name, dateValue1);
      inputRef.current.value = dateValue1;
      setInvalid({...invalid, invalid:'', error: ''});
    }
    
  },[dateValue1])

  return(
    <React.Fragment>
    <TextInput
      className={invalid.invalid}
      error={invalid.error}
      validate={true}
      icon={<Icon onClick={(e)=>checkDatePicker()}>date_range</Icon>}
      ref={inputRef}  
      noLayout
      // defaultValue={props.dateValue}
      name={props.name}
      id={props.id}
      label= {props.label || '' + 'mm/dd/yyyy'}
      placeholder='mm/dd/yyyy'
      onChange = {(e)=>handleKeyUpDate(e)}
      
    />

    <MaterializeDatePicker
      style={{display:'none'}}
      id={`id_${props.id}`}
      className="datepicker"
      options={{
        autoClose: true,
        container: null,
        defaultDate: null,
        disableDayFn: null,
        disableWeekends: false,
        events: [],
        firstDay: 0,
        format: 'mm/dd/yyyy',
        yearRange: 100
      }}
    />
    </React.Fragment>
  )

}
export default DatePicker






