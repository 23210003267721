/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChimeMeeting = /* GraphQL */ `
  query CreateChimeMeeting($title: String, $name: String, $region: String) {
    createChimeMeeting(title: $title, name: $name, region: $region) {
      statusCode
      headers
      body
      isBase64Encoded
    }
  }
`;
export const joinChimeMeeting = /* GraphQL */ `
  query JoinChimeMeeting($meetingId: String, $name: String) {
    joinChimeMeeting(meetingId: $meetingId, name: $name) {
      statusCode
      headers
      body
      isBase64Encoded
    }
  }
`;
export const endChimeMeeting = /* GraphQL */ `
  query EndChimeMeeting($meetingId: String) {
    endChimeMeeting(meetingId: $meetingId) {
      statusCode
      headers
      body
      isBase64Encoded
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClients = /* GraphQL */ `
  query GetClients($id: ID!) {
    getClients(id: $id) {
      id
      first_name
      last_name
      date_of_birth
      city_of_birth
      nickname
      middle_name
      gender
      guardian_id_1
      guardian_id_2
      pcp_id
      ocp_id
      other_physicians
      other_name
      agree_terms_of_use
      date_agreed_terms_of_use
      agree_privacy_policy
      date_agreed_privacy_policy
      consent_of_telehealth
      date_consented_of_telehealth
      is_provider
      unique_identifier
      user_action
      created_date
      created_by
      last_update_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientss = /* GraphQL */ `
  query ListClientss(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientsProfiles = /* GraphQL */ `
  query SyncClientsProfiles(
    $filter: ModelClientsProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientsProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        client_id
        sub
        email_address
        mobile_country_code
        mobile_phone_number
        street_address_line_1
        street_address_line_2
        city
        state_code
        zip_code_1
        zip_code_2
        country_code
        country
        user_name
        internal_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientsProfile = /* GraphQL */ `
  query GetClientsProfile($id: ID!) {
    getClientsProfile(id: $id) {
      id
      client_id
      sub
      email_address
      mobile_country_code
      mobile_phone_number
      street_address_line_1
      street_address_line_2
      city
      state_code
      zip_code_1
      zip_code_2
      country_code
      country
      user_name
      internal_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientsProfiles = /* GraphQL */ `
  query ListClientsProfiles(
    $filter: ModelClientsProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientsProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_id
        sub
        email_address
        mobile_country_code
        mobile_phone_number
        street_address_line_1
        street_address_line_2
        city
        state_code
        zip_code_1
        zip_code_2
        country_code
        country
        user_name
        internal_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProvidersInfos = /* GraphQL */ `
  query SyncProvidersInfos(
    $filter: ModelProvidersInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProvidersInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        client_id
        work_street_address_line_1
        work_street_address_line_2
        work_city
        work_state_code
        work_zip_code_first5
        work_zip_code_last4
        work_country_code
        taxonomy
        enumeration_type
        npi_id
        npi_status
        credential_status
        status
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProvidersInfo = /* GraphQL */ `
  query GetProvidersInfo($id: ID!) {
    getProvidersInfo(id: $id) {
      id
      client_id
      work_street_address_line_1
      work_street_address_line_2
      work_city
      work_state_code
      work_zip_code_first5
      work_zip_code_last4
      work_country_code
      taxonomy
      enumeration_type
      npi_id
      npi_status
      credential_status
      status
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProvidersInfos = /* GraphQL */ `
  query ListProvidersInfos(
    $filter: ModelProvidersInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProvidersInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_id
        work_street_address_line_1
        work_street_address_line_2
        work_city
        work_state_code
        work_zip_code_first5
        work_zip_code_last4
        work_country_code
        taxonomy
        enumeration_type
        npi_id
        npi_status
        credential_status
        status
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSessionQueues = /* GraphQL */ `
  query SyncSessionQueues(
    $filter: ModelSessionQueuesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSessionQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        client_id
        provider_id
        date_queued
        session_start
        session_end
        session_total_time
        client_log
        provider_log
        main_concern
        symptoms
        classification
        specialization_a
        specialization_b
        state
        city
        payment_process
        amount
        meeting_id
        payment_transaction_number
        payment_session_id
        payment_status
        steps_history
        chat_history
        status
        user_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSessionQueues = /* GraphQL */ `
  query GetSessionQueues($id: ID!) {
    getSessionQueues(id: $id) {
      id
      client_id
      provider_id
      date_queued
      session_start
      session_end
      session_total_time
      client_log
      provider_log
      main_concern
      symptoms
      classification
      specialization_a
      specialization_b
      state
      city
      payment_process
      amount
      meeting_id
      payment_transaction_number
      payment_session_id
      payment_status
      steps_history
      chat_history
      status
      user_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSessionQueuess = /* GraphQL */ `
  query ListSessionQueuess(
    $filter: ModelSessionQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionQueuess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_id
        provider_id
        date_queued
        session_start
        session_end
        session_total_time
        client_log
        provider_log
        main_concern
        symptoms
        classification
        specialization_a
        specialization_b
        state
        city
        payment_process
        amount
        meeting_id
        payment_transaction_number
        payment_session_id
        payment_status
        steps_history
        chat_history
        status
        user_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeetings = /* GraphQL */ `
  query SyncMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        meetingId
        title
        data
        session_queue_id
        provider_id
        tick_time_stamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($title: String!) {
    getMeeting(title: $title) {
      meetingId
      title
      data
      session_queue_id
      provider_id
      tick_time_stamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $title: String
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetings(
      title: $title
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        meetingId
        title
        data
        session_queue_id
        provider_id
        tick_time_stamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendees = /* GraphQL */ `
  query SyncAttendees(
    $filter: ModelAttendeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        attendeeId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendee = /* GraphQL */ `
  query GetAttendee($attendeeId: String!) {
    getAttendee(attendeeId: $attendeeId) {
      attendeeId
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAttendees = /* GraphQL */ `
  query ListAttendees(
    $attendeeId: String
    $filter: ModelAttendeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAttendees(
      attendeeId: $attendeeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        attendeeId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedicalHistories = /* GraphQL */ `
  query SyncMedicalHistories(
    $filter: ModelMedicalHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedicalHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        client_id
        provider_id
        session_id
        revision
        signed
        share_history
        date_signed
        data
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedicalHistory = /* GraphQL */ `
  query GetMedicalHistory($id: ID!) {
    getMedicalHistory(id: $id) {
      id
      client_id
      provider_id
      session_id
      revision
      signed
      share_history
      date_signed
      data
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMedicalHistorys = /* GraphQL */ `
  query ListMedicalHistorys(
    $filter: ModelMedicalHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_id
        provider_id
        session_id
        revision
        signed
        share_history
        date_signed
        data
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedicalShareHistories = /* GraphQL */ `
  query SyncMedicalShareHistories(
    $filter: ModelMedicalShareHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedicalShareHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        medical_record_id
        client_id
        provider_id
        npi_id
        session_id
        revision
        granted
        start_date
        end_date
        created_date
        created_by
        last_updated_date
        last_updated_by
        provider_email_address
        provider_phone_number
        provider_name
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        provider_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedicalShareHistory = /* GraphQL */ `
  query GetMedicalShareHistory($id: ID!) {
    getMedicalShareHistory(id: $id) {
      id
      medical_record_id
      client_id
      provider_id
      npi_id
      session_id
      revision
      granted
      start_date
      end_date
      created_date
      created_by
      last_updated_date
      last_updated_by
      provider_email_address
      provider_phone_number
      provider_name
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      provider_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMedicalShareHistorys = /* GraphQL */ `
  query ListMedicalShareHistorys(
    $filter: ModelMedicalShareHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalShareHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medical_record_id
        client_id
        provider_id
        npi_id
        session_id
        revision
        granted
        start_date
        end_date
        created_date
        created_by
        last_updated_date
        last_updated_by
        provider_email_address
        provider_phone_number
        provider_name
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        provider_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryClientsByUniqueIdentifier = /* GraphQL */ `
  query QueryClientsByUniqueIdentifier(
    $unique_identifier: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryClientsByUniqueIdentifier(
      unique_identifier: $unique_identifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryClientsByLastNameFirstName = /* GraphQL */ `
  query QueryClientsByLastNameFirstName(
    $last_name: String
    $first_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryClientsByLastNameFirstName(
      last_name: $last_name
      first_name: $first_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryClientsProfileByClientIdAndSub = /* GraphQL */ `
  query QueryClientsProfileByClientIdAndSub(
    $client_id: String
    $sub: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientsProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryClientsProfileByClientIdAndSub(
      client_id: $client_id
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        sub
        email_address
        mobile_country_code
        mobile_phone_number
        street_address_line_1
        street_address_line_2
        city
        state_code
        zip_code_1
        zip_code_2
        country_code
        country
        user_name
        internal_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryClientsProfileByClientId = /* GraphQL */ `
  query QueryClientsProfileByClientId(
    $client_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientsProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryClientsProfileByClientId(
      client_id: $client_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        sub
        email_address
        mobile_country_code
        mobile_phone_number
        street_address_line_1
        street_address_line_2
        city
        state_code
        zip_code_1
        zip_code_2
        country_code
        country
        user_name
        internal_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryProvidersInfoByClientIdAndNpi = /* GraphQL */ `
  query QueryProvidersInfoByClientIdAndNpi(
    $client_id: ID
    $npi_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProvidersInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProvidersInfoByClientIdAndNpi(
      client_id: $client_id
      npi_id: $npi_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        work_street_address_line_1
        work_street_address_line_2
        work_city
        work_state_code
        work_zip_code_first5
        work_zip_code_last4
        work_country_code
        taxonomy
        enumeration_type
        npi_id
        npi_status
        credential_status
        status
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryProvidersInfoByNpi = /* GraphQL */ `
  query QueryProvidersInfoByNpi(
    $npi_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelProvidersInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProvidersInfoByNpi(
      npi_id: $npi_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        work_street_address_line_1
        work_street_address_line_2
        work_city
        work_state_code
        work_zip_code_first5
        work_zip_code_last4
        work_country_code
        taxonomy
        enumeration_type
        npi_id
        npi_status
        credential_status
        status
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryProvidersInfoByClientId = /* GraphQL */ `
  query QueryProvidersInfoByClientId(
    $client_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProvidersInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProvidersInfoByClientId(
      client_id: $client_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        work_street_address_line_1
        work_street_address_line_2
        work_city
        work_state_code
        work_zip_code_first5
        work_zip_code_last4
        work_country_code
        taxonomy
        enumeration_type
        npi_id
        npi_status
        credential_status
        status
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const querySessionQueuesByClientId = /* GraphQL */ `
  query QuerySessionQueuesByClientId(
    $client_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSessionQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySessionQueuesByClientId(
      client_id: $client_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        provider_id
        date_queued
        session_start
        session_end
        session_total_time
        client_log
        provider_log
        main_concern
        symptoms
        classification
        specialization_a
        specialization_b
        state
        city
        payment_process
        amount
        meeting_id
        payment_transaction_number
        payment_session_id
        payment_status
        steps_history
        chat_history
        status
        user_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const querySessionQueuesByMainConcern = /* GraphQL */ `
  query QuerySessionQueuesByMainConcern(
    $main_concern: String
    $statusCreated_dateClient_id: ModelSessionQueuesQuerySessionQueuesByMainConcernCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySessionQueuesByMainConcern(
      main_concern: $main_concern
      statusCreated_dateClient_id: $statusCreated_dateClient_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        provider_id
        date_queued
        session_start
        session_end
        session_total_time
        client_log
        provider_log
        main_concern
        symptoms
        classification
        specialization_a
        specialization_b
        state
        city
        payment_process
        amount
        meeting_id
        payment_transaction_number
        payment_session_id
        payment_status
        steps_history
        chat_history
        status
        user_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const querySessionQueuesByStatus = /* GraphQL */ `
  query QuerySessionQueuesByStatus(
    $status: String
    $main_concernCreated_dateClient_id: ModelSessionQueuesQuerySessionQueuesByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySessionQueuesByStatus(
      status: $status
      main_concernCreated_dateClient_id: $main_concernCreated_dateClient_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        provider_id
        date_queued
        session_start
        session_end
        session_total_time
        client_log
        provider_log
        main_concern
        symptoms
        classification
        specialization_a
        specialization_b
        state
        city
        payment_process
        amount
        meeting_id
        payment_transaction_number
        payment_session_id
        payment_status
        steps_history
        chat_history
        status
        user_account_type
        created_date
        created_by
        last_updated_date
        last_updated_by
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryByMeetingId = /* GraphQL */ `
  query QueryByMeetingId(
    $meetingId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByMeetingId(
      meetingId: $meetingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        meetingId
        title
        data
        session_queue_id
        provider_id
        tick_time_stamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryMedicalRecordsByClientId = /* GraphQL */ `
  query QueryMedicalRecordsByClientId(
    $client_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMedicalRecordsByClientId(
      client_id: $client_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        provider_id
        session_id
        revision
        signed
        share_history
        date_signed
        data
        created_date
        created_by
        last_updated_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryMedicalShareHistoryByClientId = /* GraphQL */ `
  query QueryMedicalShareHistoryByClientId(
    $client_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalShareHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMedicalShareHistoryByClientId(
      client_id: $client_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medical_record_id
        client_id
        provider_id
        npi_id
        session_id
        revision
        granted
        start_date
        end_date
        created_date
        created_by
        last_updated_date
        last_updated_by
        provider_email_address
        provider_phone_number
        provider_name
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        provider_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryMedicalShareHistoryByProviderId = /* GraphQL */ `
  query QueryMedicalShareHistoryByProviderId(
    $provider_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalShareHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMedicalShareHistoryByProviderId(
      provider_id: $provider_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medical_record_id
        client_id
        provider_id
        npi_id
        session_id
        revision
        granted
        start_date
        end_date
        created_date
        created_by
        last_updated_date
        last_updated_by
        provider_email_address
        provider_phone_number
        provider_name
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        provider_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queryMedicalShareHistoryByNpiAndSession = /* GraphQL */ `
  query QueryMedicalShareHistoryByNpiAndSession(
    $npi_id: String
    $session_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalShareHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMedicalShareHistoryByNpiAndSession(
      npi_id: $npi_id
      session_id: $session_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medical_record_id
        client_id
        provider_id
        npi_id
        session_id
        revision
        granted
        start_date
        end_date
        created_date
        created_by
        last_updated_date
        last_updated_by
        provider_email_address
        provider_phone_number
        provider_name
        client_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        provider_info {
          id
          first_name
          last_name
          date_of_birth
          city_of_birth
          nickname
          middle_name
          gender
          guardian_id_1
          guardian_id_2
          pcp_id
          ocp_id
          other_physicians
          other_name
          agree_terms_of_use
          date_agreed_terms_of_use
          agree_privacy_policy
          date_agreed_privacy_policy
          consent_of_telehealth
          date_consented_of_telehealth
          is_provider
          unique_identifier
          user_action
          created_date
          created_by
          last_update_date
          last_updated_by
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
