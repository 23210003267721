import React,{useState, useEffect} from 'react'
import 'materialize-css';

import logo from '../../assets/imgs/logo_64.png'
import usa_state_codes from '../../utils/usa_state_codes.json'
import classification_codes from '../../utils/classifications.json'
import { API } from 'aws-amplify';
import { TextInput, Select, Modal, Table, Preloader, Button } from 'react-materialize';

import M from 'materialize-css';

const NpiQuery = (props) => {
    
    const [providersList, setProvidersList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [providerSearch, setProviderSearch] = useState({last_name: '',first_name:'',state_code:'',classification:'', skip:0, limit: 10, page: 1})



    const getProviders =  async () =>{

      if(providerSearch.state_code === '' && providerSearch.classification === '' && providerSearch.last_name==='' && providerSearch.first_name ==='') return;

      

      setLoader(true);
  
      // console.log("checking providers...");
      let classification = providerSearch.classification.replace(/\s/g , "_");
      
      

      API.get('hygeiaexternalapi','/external/npi/',{
        
        queryStringParameters:{
          last_name: providerSearch.last_name,
          first_name: providerSearch.first_name,
          state: providerSearch.state_code,
          classification: classification,
          skip: providerSearch.skip,
          limit: providerSearch.limit
        }
      
      }).then((results)=>{
        console.log(results);
        if(results.result_count > 0){
          console.log('Prep table');
          setProvidersList(results.results);
        }
        setLoader(false);
        // consolesetUserListProvider(results);
      }).catch(err => { setLoader(false); }); 
  
     
    }
  
    const nextPage = () =>{
      if(providersList.length<10)return;
      setProviderSearch({...providerSearch, page: providerSearch.page  + 1, skip: (providerSearch.page + 1) *  providerSearch.limit})
    }

    const prevPage = () =>{
      if(providersList.length<10)return;
      if(providerSearch.page > 1) setProviderSearch({...providerSearch, page: providerSearch.page - 1, skip: (providerSearch.page - 1) *  providerSearch.limit})
    }

    useEffect(() => {
       const getProvs = async() => {
          getProviders();
       }

       if(providerSearch.page>0 && providersList.length>0){
          getProvs();   
       }

    }, [providerSearch.page])

  
   
    useEffect(() => {
      if(providerSearch.state_code === ''){
        //document.getElementById("selSearchState").value=null;
        setProviderSearch({...providerSearch, state_code:''})
      }

      if(providerSearch.classification === ''){
        //document.getElementById("selStateClassification").value=null;
        setProviderSearch({...providerSearch, classification:''})
      }
    }, [providerSearch.state_code, providerSearch.classification])

    const selectActionProvider = (e) => {
        if(e.target.id){

          let trid = "tr" + e.target.id;
          let trElements = document.getElementById(trid);
          let providerName = trElements.cells[0].innerText + " " + trElements.cells[1].innerText 
          let providerOtherName = trElements.cells[2].innerText 
          let providerTaxonomy = trElements.cells[4].innerText 
          console.log(providerName.toUpperCase(), providerOtherName.toUpperCase(), providerTaxonomy);
         
          /*props.primary field to check if the physician is a primary care provider or not*/
          props.pickedPrimaryPhysician(e.target.id, false, providerName, providerOtherName, providerTaxonomy, '','','',true);
          let elem = document.getElementById(props.id);
          let instance = M.Modal.getInstance(elem);
          instance.close();
        }
    }

    const handleChange = (e) => {
      const key = e.target.name;
      const val = e.target.value;
    
      let setter = key.split('.')[0]
      let name = key.split('.')[1]
      if(setter==="providerSearch"){
        setProviderSearch({ ...providerSearch, [name]: val });
      }
    }

    const renderTableDataProvider = () => {
    
      return providersList.map((provider, index) => {
        let primary_taxonomy = '';

        provider.taxonomies.forEach(taxonomy => {
          if(taxonomy.primary === true){
            primary_taxonomy = taxonomy.desc
          }
        });

        if(provider.basic.status === "A"){
        return (
          
          <tr key={provider.number} id={"tr" + provider.number}>
            
            <td>{provider.basic.last_name}</td>    
            <td>{provider.basic.first_name}</td>    
            <td>{provider.other_names.length > 0 ? provider.other_names[0].first_name + ' ' + provider.other_names[0].last_name : ''}</td>
            <td>{provider.addresses[0].telephone_number}</td>    
            <td>{primary_taxonomy}</td>    
            <td>  
              <span className="blue-text" id ={provider.number} style={{"cursor":"pointer"}} onClick ={(e) =>{  selectActionProvider(e) }}> Choose </span>
             
            </td> 
  
          </tr>
        )
      }
     })
    }
   
    return (
    
      
      <Modal
          actions={[
            <Button type="button"  modal="close" node="button" className="smallbutton green" style={{"marginRight":"10px"}}>Close</Button>            
          ]}
          bottomSheet={false}
          fixedFooter={false}
          id={props.id}
          
          options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '4%'
          }}
        
          trigger={<a className="smallbutton green" style={{"minWidth":"130px","maxWidth":"130px","margin":"8px"}}>Pick Physician</a>}
          >
          
          <div>
          <img src={logo}/>
            {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}
            <div className="row">
              <div className="col m6 s12">
                  <TextInput id="txtFirstName" noLayout={true} name="providerSearch.first_name" label="First Name" 
                  value={providerSearch.first_name}  onChange={(e) => { handleChange(e) }} maxLength="30" />
              </div>
              <div className="col m6 s12">
                  <TextInput id="txtLastName" noLayout={true} name="providerSearch.last_name" label="Last Name" 
                  value={providerSearch.last_name}  onChange={(e) => { handleChange(e) }} maxLength="30" />
              </div>
              <div className="col m6 s12">
              <Select id="selSearchState"
                      label="State"
                      noLayout={true}
                      multiple={false}
                      options={{
                        classes: '',
                        dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                        }
                      }}
                      value ={providerSearch.state_code}
                        onChange={(e) => { setProviderSearch({ ...providerSearch, state_code: e.target.value }); }}
                        noLayout={true}
                >
                  <option value="">Choose state</option>
                  {
                    usa_state_codes.map((data, key) => {
                    let arr = [];
                    if(data.Status==='ACTIVE')
                      arr.push(<option key={data.StateCode} value={data.StateCode}>{data.State}</option>) 
                      return (arr);
                    })          
                  }
              </Select>
              </div>   
              <div className="col m6 s12">
              <Select id="selStateClassification"
                      label="Specialty"
                      noLayout={true}
                      multiple={false}
                      options={{
                        classes: '',
                        dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                        }
                      }}
                      
                      value ={providerSearch.classification}
                        onChange={(e) => { setProviderSearch({ ...providerSearch, classification: e.target.value }); }}
                        noLayout={true}
                >
                  <option value="">Choose classification</option>
                  {
                    classification_codes.map((data, key) => {
                    let arr = [];
                    if(data.status==='Active')
                      arr.push(<option key={data.classification} value={data.classification}>{data.classification}</option>) 
                      return (arr);
                    })          
                  }
              </Select>
              </div>   
              <div className="col m12 s12">
                <div className="flex-container2">
                    <div>
                    <a className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  
                        if(providerSearch.page > 1)
                          setProviderSearch({...providerSearch, page: 1, skip: 0})
                        else
                          getProviders();
                      }}>Search</a>     
                    </div>
                    
                    <div>
                    <a className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => { 
                        setProvidersList([]);
                        setProviderSearch({...providerSearch,last_name: '',first_name:'',classification:'',state_code:'', skip:0, limit: 10, page: 1});
                      }}>Clear</a>      
                    </div>
                    
                    <div>
                    <a className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  prevPage() }}>Prev</a>     
                    </div>  
                    
                    <div>
                    <a className="smallbutton green" style={{"minWidth":"60px","maxWidth":"60px"}} onClick={() => {  nextPage() }}>Next</a>    
                    </div>  
                </div>
              </div>
              
            </div>

          </div>

          <div className="row">
              {
                providersList.length > 0 ? 
                <div>
                  <div className="col m12 s12 ">
                    <span>PAGE: {providerSearch.page}</span>
                  </div>
                  
                </div> : <div></div>
              }
          </div>    
              
          <div style={{"marginTop": "24px"}}>
              <Table responsive={true} centered={false} className="left">
              <thead>
                <tr>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Other names</th>
                  <th>Phone Number</th>
                  <th>Specialty</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>	
                  {
                    renderTableDataProvider()
                  }
              </tbody>
              </Table>
          </div>
          
      </Modal>
      
    )
}

export default NpiQuery
