
import React, { useEffect, useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useParams } from "react-router-dom";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import {
  MeetingProvider,
  lightTheme
} from 'amazon-chime-sdk-component-library-react';
import HygeiaMeeting from '../../components/chime/HygeiaMeeting';
import HygeiaMeetingForm from '../../components/chime/HygeiaMeetingForm';
import AppContext from '../../AppContext'
import './chime.css'
const Chime = () => {
  const context = useContext(AppContext);
  const params = useParams();
 
 

  return (
    <>
    <ThemeProvider theme={lightTheme}>
        <MeetingProvider>
            <HygeiaMeetingForm />
            <HygeiaMeeting />
        </MeetingProvider>
    </ThemeProvider>
    </>
  );
}
export default Chime;