import React from 'react'
import 'materialize-css';
//import { TextInput, Select, Button, Icon, AppBar } from 'react-materialize';
//import M from 'materialize-css';
//import MainNav from '../../components/mainnav';
//import { Auth, API } from 'aws-amplify';
//import { initUserList } from '../../utils/initialstate';
import { useHistory } from "react-router-dom";
import waitingRoom from '../../assets/imgs/virtual.jpg';
import mapView from '../../assets/imgs/map_view.jpg';
import providerProfile from '../../assets/imgs/provider_profile.jpg';
import home from '../../assets/imgs/home.jpg';

const Provider = (props) => {
	
  //let users = props.users;
  
  //const [filter, setFilter] = useState('');
  //const [searchInput, setSearchInput] = useState('');
  //const [role, setRole] = useState('');
  //const [userList, setUserList] = useState([]);

  const history = useHistory();
  /*
  * GO BACK
  * 
  */
  const goBack = () => {
    if(typeof history.location.state !== 'undefined'){
      if(typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    }else{
      goHome();
    }
  }

  const goHome = () =>{
    history.push("/", { from: "/provider" });
  }

  const goWaitingRoom = () =>{
    history.push("/providerwaitingroom", { from: "/provider" });
  }

  const goto = async(p_path)=>{


    const location = {
      pathname: p_path,
      state: { from: '/mainclient'}
    }
    history.push(location);

  }
  return (
    <div>
       
    <div className="white">
      <nav>
        <div className="nav-wrapper hygeiabackground">
        <div>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}}>Provider</a>
        </div>
        </div>
      </nav>
      <div className="row">
        <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" onClick = {()=>goWaitingRoom() }  src={waitingRoom}/>
              
            </div>
            <div className="card-content">
              <p className="font1">
                  <a href="#" onClick = {()=>goWaitingRoom() }>Meeting room</a> 
              </p>
            </div>
           
          </div>
        </div>
        <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={mapView}/>
              
            </div>
            <div className="card-content">
              <p className="font1">
                  <a href="#" onClick = {()=>console.log('')}>E-Map View</a> 
              </p>
            </div>
           
          </div>
        </div>
        <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={providerProfile}/>
              
            </div>
            <div className="card-content">
              <p className="font1">
                  <a href="#" onClick = {()=>console.log('')}>Provider Profile</a> 
              </p>
            </div>
           
          </div>
        </div>
        <div className="col m3 s12">
          <div className="card">
            <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" src={home}/>
              {/* <span class="card-title"><a href="#" style={{fontWeight:'bold', color:'white'}} onClick = {()=>console.log('')}>Home</a> </span> */}
            </div>
            <div className="card-content">
              <p className="font1">
                  <a href="#" onClick = {()=>goto('/')}>Home</a> 
              </p>
            </div>
           
          </div>
        </div>
      </div>
    </div>

    
  </div>
  )
}

export default Provider

