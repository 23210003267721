import React, { useEffect, useState } from 'react'
import 'materialize-css';
import M from 'materialize-css';
import { Button, Modal, Row, Col, Collection, CollectionItem } from 'react-materialize';
import country from '../../utils/country.json';
import logo from '../../assets/imgs/logo_64.png';
import parsePhoneNumber from 'libphonenumber-js'

import US from '../../assets/imgs/united-states-of-america.png';

import PH from '../../assets/imgs/philippines.png';

import './style.css';

const CountryCode = (props) => {
  const flags = {
    US:US
    ,PH:PH
  }
  const [isDirty, setIsDirty] = useState(false);
  const [countryValue, setCountryValue] = useState('+1');
  const [mobileNumber, setMobileNumber] = useState('');
  const [flag, setFlag] = useState(flags["US"]);
  
  const [arrayCountry,setArrayCountry] = useState([]);
  const [msgBox, setMsgBox] = useState({msg: "",header: "", show:false});
  
  const pickedCountry = (e) =>{
    console.log("PICKED COUNTRY",e)
    setCountryValue(e.dialCode);
    setFlag(flags[e.isoCode]);
    props.setValue(e.dialCode);
    let elem = document.getElementById("idModalCountryCode" + props.id);
    let instance = M.Modal.getInstance(elem);
    instance.close();
  }
  const showModal = () =>{
    let elem = document.getElementById("idModalCountryCode" + props.id);
    let instance = M.Modal.getInstance(elem);
    instance.open();
  }

  function isNumberKey(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    setIsDirty(true);
    if ((key < 48 || key > 57) && !(key === 8 || key === 9 || key === 13 || key === 37 || key === 39 || key === 46) ){
        theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const checkInput =()=>{
    let tmpCountryValue = countryValue;

    if(tmpCountryValue.charAt(0)!=='+') tmpCountryValue = "+" + tmpCountryValue;

    tmpCountryValue = (arrayCountry.find(country => country.startsWith(tmpCountryValue)));

    if(tmpCountryValue===undefined){
      setCountryValue("");
      showMessage("Please input a valid country code for your mobile phone.","Country Code:");
    }else{
      setCountryValue(tmpCountryValue);
    }
  }

  const showMessage = (p_message,p_header) =>{
    
    setMsgBox({msg: p_message,header: p_header, show: true})
  
  }

  const validatePhoneNumber = () => {

    const phone = mobileNumber;
  
    if (phone.length < 2) { return false }
    
    const phoneNumber = parsePhoneNumber(phone, "US");

    if (phoneNumber.isPossible()) {
      return true;
    }

    return false;
  }

  useEffect(() => {

    country.forEach((obj) => {
      if(obj.status === 'active'){
        console.log(obj)
        setArrayCountry(arrayCountry => arrayCountry.concat(obj.dialCode))
      }
    });
  },[])

  useEffect(() => {
    if(props.country_code){
      setCountryValue(props.country_code);
    }
    if(props.mobile_number){
      if(props.mobile_number.length === 10){
        setMobileNumber(props.mobile_number)
      }
      if(props.mobile_number.length > 10){
        setMobileNumber(props.mobile_number.substring(props.mobile_number.length - 10, props.mobile_number.length));    
        setCountryValue(props.mobile_number.substring(0, props.mobile_number.length - 10));    
      }
    }
    
    for (const i in country) {
      if(country[i].dialCode === props.country_code){
        setFlag(flags[country[i].isoCode]);
      }
    }
  },[props.mobile_number,props.country_code])

  const updateMobileNumber = (e) =>{

    if(mobileNumber.length === 0)return;
    let validNumber = validatePhoneNumber();
    if(validNumber === true) 
      props.setMobileNumber(e);
    else
      showMessage("Invalid phone number. ","Phone Number:");

  }

  return (
    <>
       <label>Mobile Number</label>
       <div className="container1">
          <div>
            <img id = {"img" + props.id} style={{"cursor":"help"}} className = "flag" src={flag} onClick={(e)=>{showModal()}}></img>
          </div>
          <div style={{"maxWidth":"50px"}}>
            
            <input type="text"
            id ={"countryCode" + props.id}
            value = {countryValue} onChange={(e)=> setCountryValue(e.target.value)} 
            style={{"height":"2.6rem"}}
            maxLength="4"
            minLength="2"
            onKeyPress={(e)=>isNumberKey(e)}
            onBlur={()=>{checkInput()}}
            />
            
          </div>
          <div style={{"width":"100%"}}>
            <input type="text"
              id ={"mobileNumber" + props.id}
              style={{"height":"2.6rem"}}
              value = {mobileNumber} onChange={(e)=> setMobileNumber(e.target.value)}
              onBlur={(e)=>updateMobileNumber(e.target.value)}
              maxLength="10"
              minLength="10"
              onKeyPress={(e)=>isNumberKey(e)}
              
              />
          </div>
       </div>
        
        <Modal
        actions={[
            <Button modal="close" className="smallbutton blue" node="button">Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        id={"idModalCountryCode" + props.id}
        open={false}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '4%'
        }}
        >
        <Row>
        <Col m={12} s={12}>
            
            <Collection>
            {
                country.map((data, key) => {
                let arr = [];
                
                if(data.status==='active'){
                  arr.push(
                  <CollectionItem className="avatar" href="#" key={data.name} onClick={()=>{pickedCountry(data)}}> 
                  <img src={flags[data.isoCode]} className="circle"/>

                  <span className="title">
                      Country: {data.name}
                  </span>
                  <p>
                      Dial: {data.dialCode}
                  </p>
                  </CollectionItem>) 
                  

                }
                return (
                    arr
                );
                })         
            }
            
            </Collection>
        </Col>
        </Row>
        </Modal>

        <Modal
            actions={[
              <a className="button blue" style={{"margin":"8px"}} onClick={() => { setMsgBox({msg:"",header:"", show: false}) }}>Close</a>
            ]}
            bottomSheet={false}
            fixedFooter={false}
           
            id="msgBox"
            open={msgBox.show}
            options={{
              dismissible: false,
              endingTop: '5%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img src={logo}/>
            <div className="hygeiafontH1">{msgBox.header}</div>
            <h6> {msgBox.msg} </h6>    
        </Modal>
    </>
  )
}
export default CountryCode
