import React, { useState, useEffect, useContext } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from './pages/landingpage/landingpage';
import User from './pages/user/user';
import Profile from './pages/profile/profile';
import Medical from './pages/medical/medical';
import Provider from './pages/provider/provider';
import Billing from './pages/billing/billing';
import ForTesting from './pages/fortesting';
import Redirect from './pages/redirectpage';
import SignOut from './pages/signout';
import ClientWaitingRoom from "./pages/client-waitingroom";
import ProviderWaitingroom from './pages/provider-waitingroom/provider-waitingroom';
import {initialLoginDataState, userCredentials, storeItem, getItem} from './utils/initialstate.js'
import MainNav from './components/mainnav';
import AppContext from './AppContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import _ from 'lodash';
import Chime from './pages/chime';
import ClientMain from './pages/client-main';

//Material UI Changes
import MedicalRecords from './pages/medical-records';

import StepItems from './pages/medical-records/listitems';

export default function AppRoutes(props) {
//const context = useContext(AppContext)
const [loggedUser, setLoggedUser] = useState( initialLoginDataState );
const [clientInfo, setClientInfo] = useState([]);
const [providerInfo, setProviderInfo] = useState({});
const [contactInfo, setContactInfo] = useState({});

const [chatLogs, setChatLogs] = useState(
  {
    userSessionAttributes: {}
    , login: false
    , registration: false
    , gathering: false
    , authorization: false
    , queue: false
    , consult: false
    , prescription: false
    , payment: false
    , user_role: 'client'
  });
 
  useEffect(() => {
    const getCreds = async() => {
      let ret = await userCredentials('FROM ROUTES');
      if(ret){
        setLoggedUser({...ret});
      }    
    }
    getCreds();
  },[]);

  useEffect(()=>{
    console.log("APPROUTES LOAD TRIGGERED");
    if(!_.isEmpty(loggedUser.clientid)){
      console.log("APPROUTES LOAD TRIGGERED 1");
      const getProfile = async () => {

        let client = await API.graphql(
          graphqlOperation(queries.getClients, {id: loggedUser.clientid})
        );
        if(client.data.getClients){
          setClientInfo(client.data.getClients);
        }

        storeItem("userInfo", client.data.getClients);
        

        let provider = await API.graphql(
          graphqlOperation(queries.queryProvidersInfoByClientId, {client_id: loggedUser.clientid})
        );
        if(provider.data.queryProvidersInfoByClientId.items){
          if(provider.data.queryProvidersInfoByClientId.items.length > 0){
            setProviderInfo({...providerInfo
              , npi_id: provider.data.queryProvidersInfoByClientId.items[0].npi_id
              , work_city: provider.data.queryProvidersInfoByClientId.items[0].work_city
              , work_state_code: provider.data.queryProvidersInfoByClientId.items[0].work_state_code
            });
            
            let provInfo = {
              npi_id: provider.data.queryProvidersInfoByClientId.items[0].npi_id
              , work_city: provider.data.queryProvidersInfoByClientId.items[0].work_city
              , work_state_code: provider.data.queryProvidersInfoByClientId.items[0].work_state_code
            }
            storeItem("providerInfo", provInfo);

          }
        }

        let profiles = await API.graphql(
          graphqlOperation(queries.queryClientsProfileByClientId, {client_id: loggedUser.clientid})
        );
        
        if(profiles.data.queryClientsProfileByClientId.items){
  
          for(const item of profiles.data.queryClientsProfileByClientId.items){
  
            if(loggedUser.provider === item.internal_account_type){
              setContactInfo(item);
              storeItem("contactInfo", item)
            }
  
          }
  
        }

      }
  
      getProfile();
    }
  },[loggedUser]);
  

  const AppContextValues = {
    userState: loggedUser, setUserState:setLoggedUser, chatLogs, setChatLogs, clientInfo, providerInfo, contactInfo
  }

  return (
    
    <div>
     
    <Router>
    <AppContext.Provider value = {AppContextValues} >
    <MainNav userData={loggedUser}/>
    <Switch>
    
      <Route exact path="/" render={(props) => <LandingPage userData={loggedUser} {...props}/>} />
      <Route exact path="/signout" render={(props) => <SignOut userData={loggedUser} {...props} /> }/> 
      <Route path="/profile" render={(props) => <Profile userData={loggedUser} {...props} /> }/> 
      <Route path="/test" render={(props) => <ForTesting userData={loggedUser} {...props} /> }/> 
      <Route path="/user" render={(props) => <User userData={loggedUser} {...props}/>}/>
      
      <Route exact path="/medical" render={() => <Medical/>}/>
      <Route path="/medical/:client_id/:id/:user_account_type/:name" render={(props) => <Medical/>}/>
      
      <Route path="/provider" render={(props) => <Provider userData={loggedUser} {...props}/>}/>
      <Route path="/billing" render={(props) => <Billing userData={loggedUser} {...props}/>}/>
      <Route path="/redirect" render={(props) => <Redirect userData={loggedUser} {...props}/>}/>
      <Route path="/providerwaitingroom" render={(props) => <ProviderWaitingroom userData={loggedUser} {...props}/>}/>
      <Route exact path="/chime" render={(props) => <Chime {...props}/>}/>
      <Route path="/chime/:client_id/:id/:meeting_id" render={(props) => <Chime {...props}/>}/>
      <Route path="/client" render={(props) => <ClientWaitingRoom {...props}/>}/>
      <Route path="/mainclient" render={(props) => <ClientMain {...props}/>}/>

      <Route path="/provider/view/medical/:client_id/:medical_rec_id/:id" render={(props) => <MedicalRecords/>}/>
      <Route path="/medrec/:medical_record_id" render={() => <StepItems/>}/>
      <Route path="/meddetails" render={() => <MedicalRecords/>}/>
    </Switch>
    </AppContext.Provider>
    </Router>
    
    </div>

  );
}
