import React, {useState} from 'react'
import { Icon, TextInput, Select, Checkbox, Preloader, Collapsible, CollapsibleItem, Modal, Row, Col, Divider, Collection, CollectionItem, RadioGroup, Textarea } from 'react-materialize';
import { genKey } from '../../utils/initialstate';
import DatePicker from '../datepicker';

import 'materialize-css';


export default function MedicalRecordItem(props) {
  const{items, formValues, setFormValues,handleChangeText,handleChangeCheckBox,handleChangeDate, category} = props;
  
  return (
    <Row>
      {

        items && items.map((item, index)=>

          <Col s={12} m={item.grid} key={`column_sub_item_options_${index}_${item.sub_category}`}>

                {
                  item && item.type === 'text' ? 
                  
                  <TextInput
                  
                    
                    id={`text_${item.sub_category.replace(/[^A-Z0-9]+/ig, "_")}_${index}`}
                    label={item.label || item.sub_options[0]}
                    placeholder={item.label}
                    name={item.sub_options[0]}
                    noLayout
                    value={formValues[item.sub_category]}
                    onChange={(e)=>handleChangeText(e)}
                  /> 

                  : null

                }
              
            
                {
                  item && item.type === 'checkbox' && !props.readOnly ? 
                  
                  item.sub_options.map((i3,k3)=>
                    <p key={`cell_item_${k3}_${item.sub_category}`}>
                      <label>
                        <input 
                          id={`checkbox_${genKey()}`}
                          maxLength={item.maxlength}
                          className="with-gap" 
                          name={item.sub_category} 
                          type="checkbox" 
                          data-label={i3} 
                          checked={formValues[item.sub_category] === i3} 
                          onChange={(e) => {handleChangeCheckBox(e)}} 
                          
                        />
                        <span>{i3}</span>
                      </label>
                      
                    </p>
                  )
                  
                  : null
                }

                {
                  item && item.type === 'checkbox' && props.readOnly ? 
                  
                  item.sub_options.map((i3,k3)=>
                    <p key={`cell_item_${k3}_${item.sub_category}`}>
                      <label>
                        <input 
                          id={`checkbox_${genKey()}`}
                          maxLength={item.maxlength}
                          className="with-gap" 
                          name={item.sub_category} 
                          type="checkbox" 
                          data-label={i3} 
                          checked={props.checkedItem === true && i3==='Yes'} 
                        />
                        <span>{i3}</span>
                      </label>
                      
                    </p>
                  )
                  
                  : null
                }
             
             
                {
                  item && item.type === 'multitext' ? 
                  <Textarea
                    maxLength = {item.maxlength}
                    data-length={item.maxlength}
                    noLayout={true}
                    autoFocus="autoFocus"
                    id={`multitext${item.sub_category.replace(/[^A-Z0-9]+/ig, "_")}_${index}`}
                    label={item.sub_options[0]}
                    value={formValues[item.sub_category]}
                    name={item.sub_category}
                    onChange={(e)=>{handleChangeText(e)}}
                  /> 
                  : null
                }
                
                {
                  
                  item && item.type === 'date' ?
                    <React.Fragment>
                      {/* <label>{item.sub_options[0]}</label>              
                      <input type = "date" 
                        class = "datepicker" 
                        id={`multitext${item.sub_category.replace(/[^A-Z0-9]+/ig, "_")}_${index}`} 
                        value={formValues[item.sub_category]}
                      />     */}

                      <DatePicker  
                        id={`datepicker${item.sub_category.replace(/[^A-Z0-9]+/ig, "_")}_${index}`} 
                        name={item.sub_category} 
                        label={item.sub_options[0].label || 'Date'} 
                        setValue = {handleChangeDate} 
                        dateValue={formValues[item.sub_category]}
                      />
                    </React.Fragment>

                    :null
                 
                }
             
              
           
          </Col>

        )
        
      }
      
    </Row>
  )
}
