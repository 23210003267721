import React, { useState, useEffect } from 'react'
import 'materialize-css';
import { Button, Checkbox, Modal } from 'react-materialize';
import { convertDateStringToDate } from '../../utils/initialstate'


const Agreements1 = (props) => {

  const [readAgreement, setReadAgreement] = useState(true);

  const close = () =>{
    if(props.enableAgreement)
      props.enableAgreement(readAgreement, 1);
  }

  return (
    <>
      <Modal
        actions={[
          <Button flat modal="close" node="button" className="smallbutton green" onClick={() => { close()}}>Agree</Button>,
          
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header="Agreement on Terms"
        id="Modal-0"
        open={false}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}

        trigger={props.trigger ? props.trigger : <a node="button" className="smallbutton green" style={{"margin":"8px","cursor":"pointer"}}>Agreement on Terms</a>}
      >

        <div style={{ "height": "200px", "overflowX": "hidden", "overflowY": "auto" }} onScroll={(e) => {
          if (e.target.clientHeight === e.target.scrollHeight - e.target.scrollTop) {
            setReadAgreement(true);
            setReadAgreement((readAgreement) => {
              console.log(readAgreement)
              return readAgreement;
            });
          }
        }}>

            <p>These Terms of Use ("Terms") govern your use of the GlobalHealthWay.com  ("Global Health Way", "Hygeia", "HygeiaMD", "HyegeiaMMC", "HyegeiaRMC", "HygeiaHelp", "we", "us", or "our") website and any other website or online service that Global Health Way operates and that links to these Terms (collectively, the "Services").</p>

            <p>Please review these Terms carefully before using the Services. We may change these Terms or modify any features of the Services at any time.  The most current version of the Terms can be viewed by clicking on the "Terms of Use" link posted through the Services.  You accept the Terms by using the Services, and you accept any changes to the Terms by continuing to use the Services after we post the changes.</p>

            <p>I. PRIVACY</p>

            <p>By using the Services, you consent to our processing your information consistent with our Privacy Policy (https://www.GlobalHealthWay.com/privacy-policy/).</p>

            <p>II. IMPORTANT INFORMATION ABOUT YOUR USE OF THE SERVICES</p>

            <p>DO NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 9-1-1 IMMEDIATELY.</p>

            <p>Global Health Way does not provide any physicians' or other healthcare providers' (collectively, "Providers") services itself. All of the Providers are independent of Global Health Way and merely using the Services to communicate with you. Any information or advice received from a Provider comes from the Provider, and not from Global Health Way. Your interactions with the Providers via the Services are not intended to take the place of your relationship(s) with your regular health care practitioner(s).</p>

            <p>NEITHER Global Health Way NOR ANY OF ITS LICENSORS OR SUPPLIERS OR ANY THIRD PARTIES WHO PROMOTE THE SERVICES OR PROVIDE YOU WITH A LINK TO THE SERVICES SHALL BE LIABLE FOR ANY PROFESSIONAL ADVICE YOU OBTAIN FROM A PROVIDER VIA THE SERVICE NOR FOR ANY INFORMATION OBTAINED FROM OUR SERVICES. YOU ACKNOWLEDGE YOUR RELIANCE ON ANY PROVIDERS OR INFORMATION PROVIDED BY THE SERVICES IS SOLELY AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY FOR ALL RISK ASSOCIATED THEREWITH.</p>

            <p>Global Health Way does not make any representations or warranties about the training or skill of any Providers using the Services. You are ultimately responsible for choosing your particular Provider on the Services. You hereby certify that you are physically located in the State you have entered as your current location on the Services. You acknowledge that your ability to access and use the Services is conditioned upon the truthfulness of this certification and that the Providers you access through the Services are relying upon this certification in order to interact with you. IN</p>

            <p>THE EVENT THAT YOUR CERTIFICATION IS INACCURATE, YOU AGREE TO INDEMNIFY Global Health Way AND THE PROVIDERS YOU INTERACT WITH FROM ANY RESULTING DAMAGES, COSTS, OR CLAIMS.</p>

            <p>Providers may not utilize the Services to provide fee for service consultations to Medicare or Medicaid beneficiaries. In select circumstances, Providers may utilize the Services to provide consultations to participants in a Medicare Advantage or Managed Medicaid plan. If you provide false or deceptive information regarding your Medicare or Medicaid enrollment status, Global Health Way reserves the right to terminate all current or future use of the Services by you.</p>

            <p>III. ACCOUNT ENROLLMENT</p>

            <p>To access Providers using the Services, you must first establish an individual user account ("Account") by providing certain information. You agree that you will not create more than one Account, or create an Account for anyone other than yourself (with the exception of subaccounts established for minor children of whom you are a parent or legal guardian). You agree to provide true, accurate, current, and complete information on the Account enrollment form and to keep this information current and updated as needed.</p>

            <p>You represent and warrant that you are at least 18 years of age and possess the legal right and ability, on behalf of yourself or a minor child of whom you are a parent or legal guardian, to agree to these Terms of Use.</p>

            <p>IV. PROHIBITED CONDUCT</p>

            <p>You may not access or use, or attempt to access or use, the Services to take any action that could harm us or any third party, interfere with the operation of the Services, or use the Services in a manner that violates any laws. For example, and without limitation, you may not:</p>

            <p>• transmit any message or information under a false name or otherwise misrepresent your affiliation or the origin of materials you transmit;</p>

            <p>• provide information on the enrollment form that is untrue, inaccurate, not current, or incomplete;</p>

            <p>• transmit any message or information that is unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening, or hateful;</p>

            <p>• transmit any message or information that infringes or violates the intellectual property, privacy, or publicity rights of others;</p>

            <p>• reproduce, retransmit, distribute, disseminate, sell, publish, broadcast, or circulate content received through the Services to anyone without prior express permission;</p>

            <p>• engage in unauthorized spidering, "scraping," or harvesting of content or personal information, or use any other unauthorized automated means to compile information;</p>

            <p>• take any action that imposes an unreasonable or disproportionately large load on our network or infrastructure;</p>

            <p>• use any device, software, or routine to interfere or attempt to interfere with the proper working of the Services or any activity conducted on the Services or attempt to probe, scan, test the vulnerability of, or breach the security of any system or network;</p>

            <p>• attempt to modify, translate, decipher, decompile, disassemble, reverse-engineer, or create derivative works of any of the software comprising or in any way making up a part of the Services; or</p>

            <p>• engage in any other conduct that restricts or inhibits any person from using or enjoying the Services, or that, in our sole judgment, exposes us or any of our users, affiliates, or any other third party to any liability, damages, or detriment of any type.</p>

            <p>Violations of system or network security may result in civil or criminal liability. We may investigate and work with law enforcement authorities to prosecute users who violate the Terms. We may suspend or terminate your access to the Services for any or no reason at any time without notice.</p>

            <p>V. PAYMENT AUTHORIZATION</p>

            <p>By providing a credit card or other payment method accepted by Global Health Way ("ePayment"), you are expressly agreeing that we are authorized to charge to the ePayment any fees for your use of the Services, together with any applicable taxes. Please note that Global Health Way may not receive complete information from your health insurance plan, if applicable, regarding the applicable co-pay due from you for your consultation. As such, you may be billed more than once with respect to a consultation to account for additional co-pay amounts due, if any. Should you choose not to enter your health plan billing details, you elect to be seen as self-pay, thereby waiving health plan claim submission. </p>

            <p>You agree that authorizations to charge your ePayment remains in effect until you cancel it in writing, and you agree to notify Global Health Way of any changes to your ePayment. You certify that you are an authorized user of the ePayment and will not dispute charges for the Services that correspond to consultation fees or the co-payment required by your health plan. You acknowledge that the origination of ACH transactions to your account must comply with applicable provisions of U.S. law. In the case of an ACH transaction rejected for insufficient funds, Global Health Way may at its discretion attempt to process the charge again at any time within 30 days.</p>

            <p>When scheduling a visit with a Provider using the Services, you will be required to provide a ePayment.  If you choose to cancel your scheduled appointment, you must do so at least 24 hours in advance of the scheduled appointment time.  Should you choose to cancel your scheduled appointment within 24 hours of the scheduled appointment time, Operator may assess a reservation fee.  </p>

            <p>You acknowledge and agree that fees for consultations may increase at any time.</p>

            <p>VI. INTELLECTUAL PROPERTY RIGHTS</p>

            <p>The Services are protected under the copyright laws of the United States and other countries. All copyrights in the Services are owned by us or our third-party licensors to the full extent permitted under the United States Copyright Act and all international copyright laws. You may not publish, reproduce, distribute, display, perform, edit, adapt, modify, or otherwise exploit any part of the Services without our written consent.</p>

            <p>All rights in the product names, company names, trade names, logos, service marks, trade dress, slogans, product packaging, and designs of the Services, whether or not appearing in large print or with the trademark symbol, belong exclusively to Global Health Way or its licensors and are protected from reproduction, imitation, dilution, or confusing or misleading uses under national and international trademark and copyright laws. The use or misuse of these trademarks or any materials, except as authorized herein, is expressly prohibited, and nothing stated or implied on the Services confers on you any license or right under any patent or trademark of Global Health Way, its affiliates, or any third party.</p>

            <p>VII. PASSWORD SECURITY</p>

            <p>You are solely responsible for the security of your username and password, and for any use of the Services using your username and password. You should not share your username or password with any third party or allow any third party to access the Services using your username and password. You agree to notify us if you have any reason to believe that your username or password has been lost, compromised, or misused in any way. We reserve the right to revoke or deactivate your username and password at any time.</p>

            <p>VIII. WEBSITE AND THIRD-PARTY CONTENT</p>

            <p>Other than information received directly by you from Providers, the content on the Services should not be considered medical advice. You should always talk to an appropriately qualified health care professional for diagnosis and treatment, including information regarding which medications or treatment may be appropriate for you. NONE OF THE CONTENT ON THE SERVICES REPRESENTS OR WARRANTS THAT ANY PARTICULAR MEDICATION OR TREATMENT IS SAFE, APPROPRIATE, OR EFFECTIVE FOR YOU. Global Health Way does not recommend or endorse any specific tests, providers, medications, products, or procedures.</p>

            <p>The Services may provide links to third-party content. You acknowledge and agree that we are not responsible for the availability of such third-party content, and we do not control, endorse, sponsor, recommend, or otherwise accept responsibility for such content. Use of any linked third-party content is at the user's own risk.</p>

            <p>IX. CONSENT TO SERVICES</p>

            <p>The Service respects and upholds patient confidentiality with respect to protected health information as outlined by the Health Insurance Portability and Accountability Act ("HIPAA"), and, subject to HIPAA regulations, will obtain express patient consent prior to sharing any patient-identifiable information to a third party for purposes other than treatment, payment or health care operations. In addition, by clicking the "AGREE" button you are authorizing Providers to release your contact information to Global Health Way solely in order for Global Health Way to provide you with information about additional clinical services or general wellness. You may opt out of receiving such information by contacting us at privacy@GlobalHealthWay.com. Finally, when using the Service you may be asked if you would like to share certain PHI collected by Apple Health with Global Health Way. By clicking on "SYNC" you are authorizing Global Health Way to collect and Providers to utilize such PHI.</p>

            <p>By clicking the "AGREE" button, you also acknowledge the importance of providing a record of your clinical encounters via the Services to your primary care provider.  At the end of your consultation, you will have the option to send this record to your primary care provider via email and we encourage you to do so.  </p>

            <p>X. CONSENT FOR TREATMENT PERFORMED</p>

            <p> We are providing this information on behalf of Providers:</p>

            <p> Telemedicine involves the use of communications to enable health care providers at sites remote from patients to provide consultative services. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include live two-way audio and video and other materials (e.g., medical records, data from medical devices). </p>

            <p> The communications systems used will incorporate reasonable security protocols to protect the confidentiality of patient information and will include reasonable measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption. At the end of each encounter, the patient will be provided an encounter summary, which may be kept for the patient's records and may be shared with the patient's local primary care or other provider, as appropriate. </p>

            <p> Anticipated Benefits of Telemedicine </p>

            <p> • Improved access to medical care by enabling a patient to remain at his or her home or office while consulting a clinician. </p>

            <p> • More efficient medical evaluation and management. </p>

            <p> Possible Risks of Telemedicine </p>

            <p> As with any medical procedure, there are potential risks associated with the use of telemedicine. Provider believes that the likelihood of these risks materializing is very low. These risks may include, without limitation, the following: </p>

            <p> • Delays in medical evaluation and consultation or treatment may occur due to deficiencies or failures of the equipment. </p>

            <p> • Security protocols could fail, causing a breach of privacy of personal medical information. </p>

            <p> • Lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other negative outcomes. </p>

            <p> By accepting these Terms, you acknowledge that you understand and agree with the following: </p>

            <p> 1. I understand that the laws that protect privacy and the confidentiality of medical information also apply to telemedicine; I have received the HIPAA Notice which explains these issues in greater detail. </p>

            <p> 2. I understand that telemedicine may involve electronic communication of my personal medical information to medical practitioners who may be located in other areas, including out of state. </p>

            <p> 3. I understand that I may expect the anticipated benefits from the use of telemedicine in my care, but that no results can be guaranteed or assured. </p>

            <p> 4. I understand that my healthcare information may be shared with others (including health care providers and health insurers) for treatment, payment, and healthcare operations purposes. Psychotherapy notes are maintained by clinicians but are not shared with others, while billing codes and encounter summaries are shared with others and with me. If I obtain psychotherapy from Provider, I understand that my therapist has the right to limit the information provided to me if in my therapist's professional judgment sharing the information with me would be harmful to me. </p>

            <p> 5. I further understand that my healthcare information may be shared in the following circumstances: </p>

            <p> a) When a valid court order is issued for medical records. </p>

            <p> b) Reporting suspected abuse, neglect, or domestic violence. </p>

            <p> c) Preventing or reducing a serious threat to anyone's health or safety. </p>

            <p> Patient Consent to the Use of Telemedicine </p>

            <p> I have read and understand the information provided above, and understand the risks and benefits of telemedicine, and by accepting these Terms I hereby give my informed consent to participate in a telemedicine visit under the terms described herein. </p>

            <p> XI. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY </p>

            <p> YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR OTHER VIOLATION OF RIGHTS. WE DO NOT WARRANT THE ADEQUACY, CURRENCY, ACCURACY, LIKELY RESULTS, OR COMPLETENESS OF THE SERVICES OR ANY THIRD-PARTY SITES LINKED TO OR FROM THE SERVICES, OR THAT THE FUNCTIONS PROVIDED WILL BE UNINTERRUPTED, VIRUS-FREE, OR ERROR-FREE. WE EXPRESSLY DISCLAIM ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT INCLUDED IN THE SERVICES OR ANY THIRD-PARTY SITES LINKED TO OR FROM THE SERVICES. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. </p>

            <p> IN NO EVENT WILL WE, OR OUR PARENTS, SUBSIDIARIES, AFFILIATES, LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES, SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS BE LIABLE FOR ANY DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOST PROFITS, OR OTHER DAMAGES WHATSOEVER ARISING IN CONNECTION WITH THE USE OF THE SERVICES, ANY INTERRUPTION IN AVAILABILITY OF THE SERVICES, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, LOSS OF DATA, OR USE, MISUSE, RELIANCE, REVIEW, MANIPULATION, OR OTHER UTILIZATION IN ANY MANNER WHATSOEVER OF THE SERVICES OR THE DATA COLLECTED THROUGH THE SERVICES, EVEN IF ONE OR MORE OF THEM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSS. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, Global Health Way'S LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. </p>

            <p> YOU AGREE THAT YOUR SOLE REMEDY FOR ANY CLAIM ARISING OUT OF OR CONNECTED WITH THE SERVICES WILL BE TO CEASE USING THE SERVICES. </p>

            <p> YOU ACKNOWLEDGE AND AGREE THAT Global Health Way IS NOT ENGAGED IN THE PRACTICE OF MEDICINE AND THAT Global Health Way IS NOT DETERMINING APPROPRIATE MEDICAL USE OF THE SERVICES. Global Health Way, ITS LICENSORS, SUPPLIERS, AND ALL THIRD PARTIES WHO PROMOTE THE SERVICES OR PROVIDE YOU WITH A LINK TO THE SERVICES EXPRESSLY DISCLAIM ANY AND ALL LIABILITY RESULTING FROM THE DELIVERY OF HEALTHCARE VIA THE SERVICE, INCLUDING BUT NOT LIMITED TO LIABILITY FOR MEDICAL MALPRACTICE. </p>

            <p> XII. INDEMNIFICATION </p>

            <p> YOU AGREE TO INDEMNIFY, DEFEND AND HOLD US AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES, SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS HARMLESS FROM AND AGAINST ANY AND ALL LOSS, COSTS, EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES AND EXPENSES), CLAIMS, DAMAGES AND LIABILITIES RELATED TO OR ASSOCIATED WITH YOUR USE OF THE SERVICES AND ANY ALLEGED VIOLATION BY YOU OF THESE TERMS. WE RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE OF ANY CLAIM FOR WHICH WE ARE ENTITLED TO INDEMNIFICATION UNDER THIS SECTION. IN SUCH EVENT, YOU SHALL PROVIDE US WITH SUCH COOPERATION AS WE REASONABLY REQUEST. </p>

            <p> XIII. CHOICE OF LAW AND FORUM </p>

            <p> The Services are intended for use by residents of the United States. We do not intentionally provide access to the Services to individuals located outside the United States. You agree that your access to and use of the Services will be governed by and will be construed in accordance with the laws of the Commonwealth of Massachusetts without regard to principles of conflicts of laws. You agree that any claim or dispute against us arising out of or relating to the Services must be resolved by a federal district court located in Boston, Massachusetts, unless agreed upon by all parties. </p>

            <p> XIV. MISCELLANEOUS </p>

            <p> These Terms constitute the entire agreement between you and us, superseding any prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us. In the event any provision of these Terms is held unenforceable, it will not affect the validity or enforceability of the remaining provisions and will be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision. You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms or your access to and use of the Services. </p>

            <p> Our failure to enforce any provisions of these Terms or respond to a violation by any party does not waive our right to subsequently enforce any terms or conditions of the Terms or respond to any violations. Nothing contained in these Terms is in derogation of our right to comply with governmental, court, and law enforcement requests or requirements relating to your use of the Services or information provided to or gathered by us with respect to such use. </p>

            <p> LEGAL NOTICES </p>

            <p> CURRENT PROCEDURAL TERMINOLOGY ("CPT") </p>

            <p> CPT Copyright © 2016 American Medical Association. All rights reserved. </p>

            <p> Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use. The AMA does not directly or indirectly practice medicine or dispense medical services. The AMA assumes no liability for data contained or not contained herein. Applicable FARs/DFARs restrictions apply to government use. </p>

            <p> CPT is a registered trademark of the American Medical Association. </p>

            <p> If you are see a provider while you are located in California, please be advised that medical doctors are licensed and regulated by the Medical Board of California (800-633-2322; www.mbc.ca.gov). </p>

            <p> GEOGRAPHIC RESTRICTIONS </p>

            <p> I hereby certify that I am physically in my current state (you will select this state on the next page), and agree to only interact with a physician through Online Care Services while I am present in this state. I acknowledge that my ability to access and use these services is conditional upon the truthfulness of the certifications I make at the time of accessing a provider and that the Providers I access are relying upon this certification in order to interact with me.</p>

            <p> AGE REQUIREMENTS </p>

            <p> I hereby certify that I am at least 18 years of age and am qualified under the laws of my state to make medical decisions on my own behalf. I acknowledge that my ability to access and use the GlobalHealthWay system and information is conditional upon the truthfulness of my certification of age. Emancipated minors or a minor in need of confidential medical treatment, you are advised to seek in person care rather than a telehealth consultation. </p>

        </div>

      </Modal>
    </>
  )
}

export const Agreements2 = (props) => {
  return (
    <>
      <Modal
        actions={[
          <Button flat modal="close" node="button" className="smallbutton green" onClick={() => { props.enableAgreement(2) }}>Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header="Agreement on Privacy"
        id="Modal-0"
        open={false}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}

        trigger={<a href="#!" node="button">Agreement on Privacy</a>}
      >
        <p>
          1 Sample agreement text here...
          Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
            </p>
        <p>
          2 Sample agreement text here...
          Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
            </p>
        <p>
          3 Sample agreement text here...
          Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
            </p>

      </Modal>
    </>
  )
}

export const Agreements3 = (props) => {
  return (
    <>
      <Modal
        actions={[
          <Button flat modal="close" node="button" className="smallbutton green" onClick={() => { props.enableAgreement(3) }}>Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header="Consent Agreement"
        id="Modal-0"
        open={false}
        options={{
          dismissible: true,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}

        trigger={<a href="#!" node="button">Consent Agreement</a>}
      >
        <p>
          1 Sample agreement text here...
          Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
            </p>
        <p>
          2 Sample agreement text here...
          Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
            </p>
        <p>
          3 Sample agreement text here...
          Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
          Sample agreement text here...Sample agreement text here...Sample agreement text here...Sample agreement text here...
            </p>

      </Modal>
    </>
  )
}


export const AgreementsMain1 = (props) => {

  const [readAgreement, setReadAgreement] = useState({ agreed: "false", dateAgreed: '[ dd/mm/yyyyy ]' });


  useEffect(() => {
    document.getElementById("checkTermsOfUse").disabled = true;
  }, [])

  return (

      <Modal
        actions={[
          <Checkbox
            id="checkTermsOfUse"
            value={readAgreement.agreed}
            checked={readAgreement.agreed === "true"}
            label={readAgreement.dateAgreed}
            name="agreedTerms"

            onChange={(e) => {
              e.target.checked ?
                setReadAgreement({ agreed: e.target.checked.toString(), dateAgreed: convertDateStringToDate(new Date().toString()) })

                :
                setReadAgreement({ agreed: e.target.checked.toString(), dateAgreed: '[ mm/dd/yyyy ]' })
            }}
          />,
          <Button flat modal="close" node="button" className="smallbutton green" >Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header="Terms Of Use"
        id="Modal-0"
        open={false}
        options={{
          dismissible: true,
          endingTop: '5%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}

        trigger={<a href="#!" node="button" className="smallbutton green">Terms Of Use</a>}
      >

        <div style={{ "height": "300px", "overflowX": "hidden", "overflowY": "auto" }} onScroll={(e) => {
          if (e.target.clientHeight === e.target.scrollHeight - e.target.scrollTop) {
            document.getElementById("checkTermsOfUse").disabled = false;
          }
        }}>



          <p>These Terms of Use ("Terms") govern your use of the GlobalHealthWay.com  ("Global Health Way", "Hygeia", "HygeiaMD", "HyegeiaMMC", "HyegeiaRMC", "HygeiaHelp", "we", "us", or "our") website and any other website or online service that Global Health Way operates and that links to these Terms (collectively, the "Services").</p>

          <p>Please review these Terms carefully before using the Services. We may change these Terms or modify any features of the Services at any time.  The most current version of the Terms can be viewed by clicking on the "Terms of Use" link posted through the Services.  You accept the Terms by using the Services, and you accept any changes to the Terms by continuing to use the Services after we post the changes.</p>

          <p>I. PRIVACY</p>

          <p>By using the Services, you consent to our processing your information consistent with our Privacy Policy (https://www.GlobalHealthWay.com/privacy-policy/).</p>

          <p>II. IMPORTANT INFORMATION ABOUT YOUR USE OF THE SERVICES</p>

          <p>DO NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 9-1-1 IMMEDIATELY.</p>

          <p>Global Health Way does not provide any physicians' or other healthcare providers' (collectively, "Providers") services itself. All of the Providers are independent of Global Health Way and merely using the Services to communicate with you. Any information or advice received from a Provider comes from the Provider, and not from Global Health Way. Your interactions with the Providers via the Services are not intended to take the place of your relationship(s) with your regular health care practitioner(s).</p>

          <p>NEITHER Global Health Way NOR ANY OF ITS LICENSORS OR SUPPLIERS OR ANY THIRD PARTIES WHO PROMOTE THE SERVICES OR PROVIDE YOU WITH A LINK TO THE SERVICES SHALL BE LIABLE FOR ANY PROFESSIONAL ADVICE YOU OBTAIN FROM A PROVIDER VIA THE SERVICE NOR FOR ANY INFORMATION OBTAINED FROM OUR SERVICES. YOU ACKNOWLEDGE YOUR RELIANCE ON ANY PROVIDERS OR INFORMATION PROVIDED BY THE SERVICES IS SOLELY AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY FOR ALL RISK ASSOCIATED THEREWITH.</p>

          <p>Global Health Way does not make any representations or warranties about the training or skill of any Providers using the Services. You are ultimately responsible for choosing your particular Provider on the Services. You hereby certify that you are physically located in the State you have entered as your current location on the Services. You acknowledge that your ability to access and use the Services is conditioned upon the truthfulness of this certification and that the Providers you access through the Services are relying upon this certification in order to interact with you. IN</p>

          <p>THE EVENT THAT YOUR CERTIFICATION IS INACCURATE, YOU AGREE TO INDEMNIFY Global Health Way AND THE PROVIDERS YOU INTERACT WITH FROM ANY RESULTING DAMAGES, COSTS, OR CLAIMS.</p>

          <p>Providers may not utilize the Services to provide fee for service consultations to Medicare or Medicaid beneficiaries. In select circumstances, Providers may utilize the Services to provide consultations to participants in a Medicare Advantage or Managed Medicaid plan. If you provide false or deceptive information regarding your Medicare or Medicaid enrollment status, Global Health Way reserves the right to terminate all current or future use of the Services by you.</p>

          <p>III. ACCOUNT ENROLLMENT</p>

          <p>To access Providers using the Services, you must first establish an individual user account ("Account") by providing certain information. You agree that you will not create more than one Account, or create an Account for anyone other than yourself (with the exception of subaccounts established for minor children of whom you are a parent or legal guardian). You agree to provide true, accurate, current, and complete information on the Account enrollment form and to keep this information current and updated as needed.</p>

          <p>You represent and warrant that you are at least 18 years of age and possess the legal right and ability, on behalf of yourself or a minor child of whom you are a parent or legal guardian, to agree to these Terms of Use.</p>

          <p>IV. PROHIBITED CONDUCT</p>

          <p>You may not access or use, or attempt to access or use, the Services to take any action that could harm us or any third party, interfere with the operation of the Services, or use the Services in a manner that violates any laws. For example, and without limitation, you may not:</p>

          <p>• transmit any message or information under a false name or otherwise misrepresent your affiliation or the origin of materials you transmit;</p>

          <p>• provide information on the enrollment form that is untrue, inaccurate, not current, or incomplete;</p>

          <p>• transmit any message or information that is unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening, or hateful;</p>

          <p>• transmit any message or information that infringes or violates the intellectual property, privacy, or publicity rights of others;</p>

          <p>• reproduce, retransmit, distribute, disseminate, sell, publish, broadcast, or circulate content received through the Services to anyone without prior express permission;</p>

          <p>• engage in unauthorized spidering, "scraping," or harvesting of content or personal information, or use any other unauthorized automated means to compile information;</p>

          <p>• take any action that imposes an unreasonable or disproportionately large load on our network or infrastructure;</p>

          <p>• use any device, software, or routine to interfere or attempt to interfere with the proper working of the Services or any activity conducted on the Services or attempt to probe, scan, test the vulnerability of, or breach the security of any system or network;</p>

          <p>• attempt to modify, translate, decipher, decompile, disassemble, reverse-engineer, or create derivative works of any of the software comprising or in any way making up a part of the Services; or</p>

          <p>• engage in any other conduct that restricts or inhibits any person from using or enjoying the Services, or that, in our sole judgment, exposes us or any of our users, affiliates, or any other third party to any liability, damages, or detriment of any type.</p>

          <p>Violations of system or network security may result in civil or criminal liability. We may investigate and work with law enforcement authorities to prosecute users who violate the Terms. We may suspend or terminate your access to the Services for any or no reason at any time without notice.</p>

          <p>V. PAYMENT AUTHORIZATION</p>

          <p>By providing a credit card or other payment method accepted by Global Health Way ("ePayment"), you are expressly agreeing that we are authorized to charge to the ePayment any fees for your use of the Services, together with any applicable taxes. Please note that Global Health Way may not receive complete information from your health insurance plan, if applicable, regarding the applicable co-pay due from you for your consultation. As such, you may be billed more than once with respect to a consultation to account for additional co-pay amounts due, if any. Should you choose not to enter your health plan billing details, you elect to be seen as self-pay, thereby waiving health plan claim submission. </p>

          <p>You agree that authorizations to charge your ePayment remains in effect until you cancel it in writing, and you agree to notify Global Health Way of any changes to your ePayment. You certify that you are an authorized user of the ePayment and will not dispute charges for the Services that correspond to consultation fees or the co-payment required by your health plan. You acknowledge that the origination of ACH transactions to your account must comply with applicable provisions of U.S. law. In the case of an ACH transaction rejected for insufficient funds, Global Health Way may at its discretion attempt to process the charge again at any time within 30 days.</p>

          <p>When scheduling a visit with a Provider using the Services, you will be required to provide a ePayment.  If you choose to cancel your scheduled appointment, you must do so at least 24 hours in advance of the scheduled appointment time.  Should you choose to cancel your scheduled appointment within 24 hours of the scheduled appointment time, Operator may assess a reservation fee.  </p>

          <p>You acknowledge and agree that fees for consultations may increase at any time.</p>

          <p>VI. INTELLECTUAL PROPERTY RIGHTS</p>

          <p>The Services are protected under the copyright laws of the United States and other countries. All copyrights in the Services are owned by us or our third-party licensors to the full extent permitted under the United States Copyright Act and all international copyright laws. You may not publish, reproduce, distribute, display, perform, edit, adapt, modify, or otherwise exploit any part of the Services without our written consent.</p>

          <p>All rights in the product names, company names, trade names, logos, service marks, trade dress, slogans, product packaging, and designs of the Services, whether or not appearing in large print or with the trademark symbol, belong exclusively to Global Health Way or its licensors and are protected from reproduction, imitation, dilution, or confusing or misleading uses under national and international trademark and copyright laws. The use or misuse of these trademarks or any materials, except as authorized herein, is expressly prohibited, and nothing stated or implied on the Services confers on you any license or right under any patent or trademark of Global Health Way, its affiliates, or any third party.</p>

          <p>VII. PASSWORD SECURITY</p>

          <p>You are solely responsible for the security of your username and password, and for any use of the Services using your username and password. You should not share your username or password with any third party or allow any third party to access the Services using your username and password. You agree to notify us if you have any reason to believe that your username or password has been lost, compromised, or misused in any way. We reserve the right to revoke or deactivate your username and password at any time.</p>

          <p>VIII. WEBSITE AND THIRD-PARTY CONTENT</p>

          <p>Other than information received directly by you from Providers, the content on the Services should not be considered medical advice. You should always talk to an appropriately qualified health care professional for diagnosis and treatment, including information regarding which medications or treatment may be appropriate for you. NONE OF THE CONTENT ON THE SERVICES REPRESENTS OR WARRANTS THAT ANY PARTICULAR MEDICATION OR TREATMENT IS SAFE, APPROPRIATE, OR EFFECTIVE FOR YOU. Global Health Way does not recommend or endorse any specific tests, providers, medications, products, or procedures.</p>

          <p>The Services may provide links to third-party content. You acknowledge and agree that we are not responsible for the availability of such third-party content, and we do not control, endorse, sponsor, recommend, or otherwise accept responsibility for such content. Use of any linked third-party content is at the user's own risk.</p>

          <p>IX. CONSENT TO SERVICES</p>

          <p>The Service respects and upholds patient confidentiality with respect to protected health information as outlined by the Health Insurance Portability and Accountability Act ("HIPAA"), and, subject to HIPAA regulations, will obtain express patient consent prior to sharing any patient-identifiable information to a third party for purposes other than treatment, payment or health care operations. In addition, by clicking the "AGREE" button you are authorizing Providers to release your contact information to Global Health Way solely in order for Global Health Way to provide you with information about additional clinical services or general wellness. You may opt out of receiving such information by contacting us at privacy@GlobalHealthWay.com. Finally, when using the Service you may be asked if you would like to share certain PHI collected by Apple Health with Global Health Way. By clicking on "SYNC" you are authorizing Global Health Way to collect and Providers to utilize such PHI.</p>

          <p>By clicking the "AGREE" button, you also acknowledge the importance of providing a record of your clinical encounters via the Services to your primary care provider.  At the end of your consultation, you will have the option to send this record to your primary care provider via email and we encourage you to do so.  </p>

          <p>X. CONSENT FOR TREATMENT PERFORMED</p>

          <p> We are providing this information on behalf of Providers:</p>

          <p> Telemedicine involves the use of communications to enable health care providers at sites remote from patients to provide consultative services. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include live two-way audio and video and other materials (e.g., medical records, data from medical devices). </p>

          <p> The communications systems used will incorporate reasonable security protocols to protect the confidentiality of patient information and will include reasonable measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption. At the end of each encounter, the patient will be provided an encounter summary, which may be kept for the patient's records and may be shared with the patient's local primary care or other provider, as appropriate. </p>

          <p> Anticipated Benefits of Telemedicine </p>

          <p> • Improved access to medical care by enabling a patient to remain at his or her home or office while consulting a clinician. </p>

          <p> • More efficient medical evaluation and management. </p>

          <p> Possible Risks of Telemedicine </p>

          <p> As with any medical procedure, there are potential risks associated with the use of telemedicine. Provider believes that the likelihood of these risks materializing is very low. These risks may include, without limitation, the following: </p>

          <p> • Delays in medical evaluation and consultation or treatment may occur due to deficiencies or failures of the equipment. </p>

          <p> • Security protocols could fail, causing a breach of privacy of personal medical information. </p>

          <p> • Lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other negative outcomes. </p>

          <p> By accepting these Terms, you acknowledge that you understand and agree with the following: </p>

          <p> 1. I understand that the laws that protect privacy and the confidentiality of medical information also apply to telemedicine; I have received the HIPAA Notice which explains these issues in greater detail. </p>

          <p> 2. I understand that telemedicine may involve electronic communication of my personal medical information to medical practitioners who may be located in other areas, including out of state. </p>

          <p> 3. I understand that I may expect the anticipated benefits from the use of telemedicine in my care, but that no results can be guaranteed or assured. </p>

          <p> 4. I understand that my healthcare information may be shared with others (including health care providers and health insurers) for treatment, payment, and healthcare operations purposes. Psychotherapy notes are maintained by clinicians but are not shared with others, while billing codes and encounter summaries are shared with others and with me. If I obtain psychotherapy from Provider, I understand that my therapist has the right to limit the information provided to me if in my therapist's professional judgment sharing the information with me would be harmful to me. </p>

          <p> 5. I further understand that my healthcare information may be shared in the following circumstances: </p>

          <p> a) When a valid court order is issued for medical records. </p>

          <p> b) Reporting suspected abuse, neglect, or domestic violence. </p>

          <p> c) Preventing or reducing a serious threat to anyone's health or safety. </p>

          <p> Patient Consent to the Use of Telemedicine </p>

          <p> I have read and understand the information provided above, and understand the risks and benefits of telemedicine, and by accepting these Terms I hereby give my informed consent to participate in a telemedicine visit under the terms described herein. </p>

          <p> XI. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY </p>

          <p> YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR OTHER VIOLATION OF RIGHTS. WE DO NOT WARRANT THE ADEQUACY, CURRENCY, ACCURACY, LIKELY RESULTS, OR COMPLETENESS OF THE SERVICES OR ANY THIRD-PARTY SITES LINKED TO OR FROM THE SERVICES, OR THAT THE FUNCTIONS PROVIDED WILL BE UNINTERRUPTED, VIRUS-FREE, OR ERROR-FREE. WE EXPRESSLY DISCLAIM ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT INCLUDED IN THE SERVICES OR ANY THIRD-PARTY SITES LINKED TO OR FROM THE SERVICES. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. </p>

          <p> IN NO EVENT WILL WE, OR OUR PARENTS, SUBSIDIARIES, AFFILIATES, LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES, SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS BE LIABLE FOR ANY DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOST PROFITS, OR OTHER DAMAGES WHATSOEVER ARISING IN CONNECTION WITH THE USE OF THE SERVICES, ANY INTERRUPTION IN AVAILABILITY OF THE SERVICES, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, LOSS OF DATA, OR USE, MISUSE, RELIANCE, REVIEW, MANIPULATION, OR OTHER UTILIZATION IN ANY MANNER WHATSOEVER OF THE SERVICES OR THE DATA COLLECTED THROUGH THE SERVICES, EVEN IF ONE OR MORE OF THEM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSS. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, Global Health Way'S LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. </p>

          <p> YOU AGREE THAT YOUR SOLE REMEDY FOR ANY CLAIM ARISING OUT OF OR CONNECTED WITH THE SERVICES WILL BE TO CEASE USING THE SERVICES. </p>

          <p> YOU ACKNOWLEDGE AND AGREE THAT Global Health Way IS NOT ENGAGED IN THE PRACTICE OF MEDICINE AND THAT Global Health Way IS NOT DETERMINING APPROPRIATE MEDICAL USE OF THE SERVICES. Global Health Way, ITS LICENSORS, SUPPLIERS, AND ALL THIRD PARTIES WHO PROMOTE THE SERVICES OR PROVIDE YOU WITH A LINK TO THE SERVICES EXPRESSLY DISCLAIM ANY AND ALL LIABILITY RESULTING FROM THE DELIVERY OF HEALTHCARE VIA THE SERVICE, INCLUDING BUT NOT LIMITED TO LIABILITY FOR MEDICAL MALPRACTICE. </p>

          <p> XII. INDEMNIFICATION </p>

          <p> YOU AGREE TO INDEMNIFY, DEFEND AND HOLD US AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES, SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS HARMLESS FROM AND AGAINST ANY AND ALL LOSS, COSTS, EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES AND EXPENSES), CLAIMS, DAMAGES AND LIABILITIES RELATED TO OR ASSOCIATED WITH YOUR USE OF THE SERVICES AND ANY ALLEGED VIOLATION BY YOU OF THESE TERMS. WE RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE OF ANY CLAIM FOR WHICH WE ARE ENTITLED TO INDEMNIFICATION UNDER THIS SECTION. IN SUCH EVENT, YOU SHALL PROVIDE US WITH SUCH COOPERATION AS WE REASONABLY REQUEST. </p>

          <p> XIII. CHOICE OF LAW AND FORUM </p>

          <p> The Services are intended for use by residents of the United States. We do not intentionally provide access to the Services to individuals located outside the United States. You agree that your access to and use of the Services will be governed by and will be construed in accordance with the laws of the Commonwealth of Massachusetts without regard to principles of conflicts of laws. You agree that any claim or dispute against us arising out of or relating to the Services must be resolved by a federal district court located in Boston, Massachusetts, unless agreed upon by all parties. </p>

          <p> XIV. MISCELLANEOUS </p>

          <p> These Terms constitute the entire agreement between you and us, superseding any prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us. In the event any provision of these Terms is held unenforceable, it will not affect the validity or enforceability of the remaining provisions and will be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision. You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms or your access to and use of the Services. </p>

          <p> Our failure to enforce any provisions of these Terms or respond to a violation by any party does not waive our right to subsequently enforce any terms or conditions of the Terms or respond to any violations. Nothing contained in these Terms is in derogation of our right to comply with governmental, court, and law enforcement requests or requirements relating to your use of the Services or information provided to or gathered by us with respect to such use. </p>

          <p> LEGAL NOTICES </p>

          <p> CURRENT PROCEDURAL TERMINOLOGY ("CPT") </p>

          <p> CPT Copyright © 2016 American Medical Association. All rights reserved. </p>

          <p> Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use. The AMA does not directly or indirectly practice medicine or dispense medical services. The AMA assumes no liability for data contained or not contained herein. Applicable FARs/DFARs restrictions apply to government use. </p>

          <p> CPT is a registered trademark of the American Medical Association. </p>

          <p> If you are see a provider while you are located in California, please be advised that medical doctors are licensed and regulated by the Medical Board of California (800-633-2322; www.mbc.ca.gov). </p>

          <p> GEOGRAPHIC RESTRICTIONS </p>

          <p> I hereby certify that I am physically in my current state (you will select this state on the next page), and agree to only interact with a physician through Online Care Services while I am present in this state. I acknowledge that my ability to access and use these services is conditional upon the truthfulness of the certifications I make at the time of accessing a provider and that the Providers I access are relying upon this certification in order to interact with me.</p>

          <p> AGE REQUIREMENTS </p>

          <p> I hereby certify that I am at least 18 years of age and am qualified under the laws of my state to make medical decisions on my own behalf. I acknowledge that my ability to access and use the GlobalHealthWay system and information is conditional upon the truthfulness of my certification of age. Emancipated minors or a minor in need of confidential medical treatment, you are advised to seek in person care rather than a telehealth consultation. </p>

        </div>

      </Modal>
  
  )
}

export const AgreementsMain2 = (props) => {

  const [readAgreement, setReadAgreement] = useState({ agreed: "false", dateAgreed: '[ dd/mm/yyyyy ]' });


  useEffect(() => {
    document.getElementById("checkPrivacy").disabled = true;
  }, [])

  return (

      <Modal
        actions={[
          <Checkbox
            id="checkPrivacy"
            value={readAgreement.agreed}
            checked={readAgreement.agreed === "true"}
            label={readAgreement.dateAgreed}
            name="agreedTerms"

            onChange={(e) => {
              e.target.checked ?
                setReadAgreement({ agreed: e.target.checked.toString(), dateAgreed: convertDateStringToDate(new Date().toString()) })

                :
                setReadAgreement({ agreed: e.target.checked.toString(), dateAgreed: '[ mm/dd/yyyy ]' })
            }}
          />,
          <Button flat modal="close" node="button" className="smallbutton green" >Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header="Privacy and Policy"
        id="Modal-0"
        open={false}
        options={{
          dismissible: true,
          endingTop: '5%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}

        trigger={<a href="#!" node="button" className="smallbutton green">Privacy Policy</a>}
      >

        <div style={{ "height": "300px", "overflowX": "hidden", "overflowY": "auto" }} onScroll={(e) => {
          if (e.target.clientHeight === e.target.scrollHeight - e.target.scrollTop) {
            document.getElementById("checkPrivacy").disabled = false;
          }
        }}>


		  <p><strong>Definitions</strong></p>
		  
		  <p><strong>Personal Data:</strong></p>
          <p>Personal Data means data about a living individual who can be identified from those data.</p>

          <p><strong>Usage Data:</strong></p>
          <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
          
          <p><strong>Usage Data:</strong></p>
          <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
          
          <p><strong>Data Subject:</strong></p>
          <p>Data Subject is any living individual who is the subject of Personal Data.</p>
          
          <p><strong>User:</strong></p>
          <p>User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>

          <p><strong>Types of Data Collected</strong></p>
          <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
          
          <p><strong>Personal Data</strong></p>
          <p>While using our Service, you may be asked to provide certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
          <ul>
            <li> - Legal first name and legal last name</li>
            <li> - Email address</li>
            <li> - Phone number</li>
            <li> - City of Birth</li>
            <li> - Date Of Birth</li>
            <li> - Address, City, State, Zip code (optional)</li>
            <li> - Usage Data</li>
          </ul>
          <p>We may email you with notifications about your account. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us at <a href="mailto:SignMeOff@GlobalHealthWay.com">SignMeOff@GlobalHealthWay.com</a>.</p>
          
          <p><strong>Usage Data</strong></p>
          <p>We may also collect information that your browser sends whenever you visit our website or Service, or when you access the Service with a mobile device ("Usage Data").<br /><br />
          This Usage Data may include information such as your computer's Internet Protocol address (i.e. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.<br /><br />
          When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
          </p>
          
          
          <p><strong>Tracking Cookies Data</strong></p>
          <p>We sometimes use cookies and similar tracking technologies to track the activity on our sales website pages only and may hold certain information.<br /><br />
          Cookies are files with small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
          </p>
          
          <p><strong>Use of Data</strong></p>
          <p>GHW uses the collected data for various purposes:</p>
          <ul>
            <li> - To provide and maintain our Service</li>
            <li> - To notify you about changes to our Service</li>
            <li> - To provide customer support</li>
            <li> - To monitor the usage of our Service</li>
            <li> - To detect, prevent and address technical issues</li>
          </ul>
          
          <p><strong>Retention of Data</strong></p>
          <p>GHW will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations under United States law (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.<br /><br />
          GHW will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
          </p>
          
          <p><strong>Transfer of Data</strong></p>
          <p>Your information, including Personal Data, may be transferred to and maintained on servers located outside of your state, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.<br /><br />
          If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process and store it there.<br /><br />
          Your consent to this Privacy Policy, followed by your submission of such information represents your agreement to that transfer.<br /><br />
          GHW will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
          </p>
          
          <p><strong>Transfer of Data</strong></p>
          <p>Your information, including Personal Data, may be transferred to and maintained on servers located outside of your state, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.<br /><br />
          If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process and store it there.<br /><br />
          Your consent to this Privacy Policy, followed by your submission of such information represents your agreement to that transfer.<br /><br />
          GHW will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
          
          <p><strong>Information Sharing and Disclosure</strong></p>
          <p>GHW will not sell, rent or disclose your personally identifiable information to anyone unless required to do so by law, legal process, litigation, and/or requests from public and governmental authorities within or outside your country of residence.</p>
          
          <p><strong>Disclosure for Law Enforcement</strong></p>
          <p>Under certain circumstances, GHW may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          
          <p><strong>Legal Requirements</strong></p>
          <p>GHW may disclose your Personal Data in the good faith belief that such action is necessary:</p>
          <ul>
            <li> - To comply with a legal obligation</li>
            <li> - To protect and defend the rights or property of GHW</li>
            <li> - To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li> - To protect the personal safety of users of the Service or the public</li>
            <li> - To protect against legal liability</li>
          </ul>
          
          <p><strong>Your Ability to Edit / Delete Your Account Information</strong></p>
          <p>GHW gives Users the ability to sign in and edit or delete their Personal Data and account Information and preferences at any time.<br /><br />
          GHW aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.<br /><br />
          If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact your GHW account main manager at your organization (the individual who created or is in control of the GHW account). If the main manager on the account needs assistance they can contact <a href="mailto:support@GlobalHealthWay.com">support@GlobalHealthWay.com</a>.</p>
          
          <p><strong>Security</strong></p>
          <p>Your GHW Account Information is password‐protected for your privacy and security. GHW uses industry‐standard SSL‐encryption to protect data transmissions. Each GHW user should use their own username/password for access to ensure security.</p>
        
        <p><strong>Security of Data</strong></p>
        <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
          
        <p><strong>Security of Payment Information</strong></p>
        <p>GHW uses third‐party services for payment processing (e.g. payment processors). We will not store or collect your payment card details. That information is provided directly to our third‐party payment processor whose use of your personal information is governed by their Privacy Policy. This payment processor adheres to the standards set by PCI‐DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI‐DSS requirements help ensure the secure handling of payment information.</p>
          
        <p><strong>Links to Other Sites</strong></p>
        <p>Our Service may contain links to other websites that are not operated by us. If you click on a third‐party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.<br /><br />
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third‐party sites or services.</p>
          
        <p><strong>Changes to this Privacy Policy</strong></p>
        <p>GHW may amend this policy from time to time. If we make any substantial changes in the way we use your personal information, we will notify you by posting an announcement on the home page of subscriber manager accounts.<br /><br />
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted.<br /><br />
       Revised: November 30, 2020</p>
        </div>

      </Modal>
  
  )
}

export const AgreementsMain3 = (props) => {

  const [readAgreement, setReadAgreement] = useState({ agreed: "false", dateAgreed: '[ dd/mm/yyyyy ]' });


  useEffect(() => {
    document.getElementById("checkTele").disabled = true;
  }, [])

  return (

      <Modal
        actions={[
          <Checkbox
            id="checkTele"
            value={readAgreement.agreed}
            checked={readAgreement.agreed === "true"}
            label={readAgreement.dateAgreed}
            name="agreedTerms"

            onChange={(e) => {
              e.target.checked ?
                setReadAgreement({ agreed: e.target.checked.toString(), dateAgreed: convertDateStringToDate(new Date().toString()) })

                :
                setReadAgreement({ agreed: e.target.checked.toString(), dateAgreed: '[ mm/dd/yyyy ]' })
            }}
          />,
          <Button flat modal="close" node="button" className="smallbutton green" >Close</Button>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header="Consent to Participate in a Telemedicine Consultation"
        id="Modal-0"
        open={false}
        options={{
          dismissible: true,
          endingTop: '5%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}

        trigger={<a href="#!" node="button" className="smallbutton green">TeleHealth Consultation</a>}
      >

        <div style={{ "height": "300px", "overflowX": "hidden", "overflowY": "auto" }} onScroll={(e) => {
          if (e.target.clientHeight === e.target.scrollHeight - e.target.scrollTop) {
            document.getElementById("checkTele").disabled = false;
          }
        }}>

          <p>Patient Name: ____________________________________</p>
          <p>Medical Record No: ______________________________</p>
          
		  <p>1. I understand that my health care provider wishes me to engage in a telemedicine consultation.<br /><br />
2. My health care provider has explained to me how the video conferencing technology will be used to affect such a consultation will not be the same as a direct patient/health care provider visit due to the fact that I will not be in the same room as my health care provider.<br /><br />
3. I understand there are potential risks to this technology, including interruptions, unauthorized access and technical difficulties. I understand that my health care provider or I can discontinue the telemedicine consult/visit if it is felt that the videoconferencing connections are not adequate for the situation.<br /><br />
4. I understand that my healthcare information may be shared with other individuals for scheduling and billing purposes. Others may also be present during the consultation other than my health care provider and consulting health care provider in order to operate the video equipment. The above mentioned people will all maintain confidentiality of the information obtained. I further understand that I will be informed of their presence in the consultation and thus will have the right to request the following: (1) omit specific details of my medical history/physical examination that are personally sensitive to me; (2) ask non‐medical personnel to leave the telemedicine examination room: and or (3) terminate the consultation at any time.<br /><br />
5. I have had the alternatives to a telemedicine consultation explained to me, and in choosing to participate in a telemedicine consultation. I understand that some parts of the exam involving physical tests may be conducted by individuals at my location at the direction of the consulting health care provider.<br /><br />
6. In an emergent consultation, I understand that the responsibility of the telemedicine consulting specialist is to advise my local practitioner and that the specialist’s responsibility will conclude upon the termination of the video conference connection.<br /><br />
7. I understand that billing will occur from both my practitioner and as a facility fee from the site from which I am presented.<br /><br />
8. I have had a direct conversation with my doctor, during which I had the opportunity to ask questions in regard to this procedure. My questions have been answered and the risks, benefits and any practical alternatives have been discussed with me in a language in which I understand.<br /><br />
By signing this form, I certify:<br /><br />
That I have read or had this form read and/or had this form explained to me<br /><br />
That I fully understand its contents including the risks and benefits of the procedure(s).<br /><br />
That I have been given ample opportunity to ask questions and that any questions have been answered to my satisfaction.</p>
        </div>

      </Modal>
  
  )
}

export default Agreements1




