import React, { useState } from 'react'
import 'materialize-css';
import { Button, DatePicker } from 'react-materialize';


import { Auth, API } from 'aws-amplify';

import { useHistory } from "react-router-dom";

const Billing = (props) => {
	
  //let users = props.users;
  
  const [filter, setFilter] = useState('');
  const [searchInput, setSearchInput] = useState('');
  //const [role, setRole] = useState('');
  //const [userList, setUserList] = useState([]);
  const history = useHistory();
  
  /*
  * GO BACK
  * 
  */
  const goBack = () => {
    if(typeof history.location.state !== 'undefined'){
      if(typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    }else{
      goHome();
    }
  }

  const goHome = () =>{
    history.push("/", { from: "/billing" });
  }
  
  let nextToken
  const searchUsers = async(limit,filter,searchtext) => {
    let apiName = 'AdminQueries';
    let path = '/listUsersWithFilter';
    let myInit = { 
        queryStringParameters: {
          "limit": limit,
          "searchfilter": filter,
          "searchtext": searchtext,
          "token": nextToken
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
    nextToken = NextToken;
    return rest;
  }
  
  const handleClick = async () => {
    let searchFilter = filter;
    let searchText = searchInput;
    
    let res = await searchUsers(10,searchFilter,searchText);
    console.log(res);
    //setUserList(res);
  }
  
  return (
    <div>
       
    <div className="white">
      <nav>
        <div className="nav-wrapper hygeiabackground">
        <div>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}}>Billing</a>
        </div>
        </div>
      </nav>
	  
	  <div className="row" style={{"margin":"20px"}}>
	  <h5 style={{"textAlign": "center", "color": "#226e93"}}>Payments and Billings</h5>
	    
	    <div style={{"margin" : "10px"}}>
          <div className="col m4 s12">
            <DatePicker id="startDate" noLayout = {true} name="birthdate" label="Start Date mm/dd/yyyy" error="Required field"
            />
          </div>
          <div className="col m4 s12">
            <DatePicker id="startDate" noLayout = {true} name="birthdate" label="End Date mm/dd/yyyy" error="Required field"
            />
          </div>
          <div className="col m4 s12"><br />
            <Button node="button" type="button" waves="light" onClick={handleClick}>Search</Button>
          </div>
		  
              <table className="responsive-table striped" style={{"marginTop": "24px"}}>
              <thead className="center">
                <tr>
                  <th>Session</th>
                  <th>Client</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Session #1</td>
                  <td>Patient 1</td>
                  <td>$10.00</td>
                  <td>Completed</td>
                </tr>
                <tr>
                  <td>Session #2</td>
                  <td>Patient 2</td>
                  <td>$15.00</td>
                  <td>Completed</td>
                </tr>
                <tr>
                  <td>Session #3</td>
                  <td>Patient 3</td>
                  <td>$24.00</td>
                  <td>Completed</td>
                </tr>
                      {/*
                          renderTableData()
                      */}
              </tbody>
              </table>
              </div>
      </div>
    </div>
  </div>
  )
}

export default Billing

