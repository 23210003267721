import React from 'react';
import 'materialize-css';
import { useHistory } from "react-router-dom";
import rxImage from '../../assets/imgs/rx.png';
import meds from '../../assets/imgs/meds.png';

const Prescription = () => {
  const history = useHistory();

  return (
    <div className = "row">
      
         
      <div className="col s12 m12 ">
        <div className="card-panel grey lighten-5 z-depth-1">
          <div className="row">
            <div className="col s12 m1">
              <img style={{maxHeight:'54px'}} src={rxImage} alt="" className="responsive-img"/> 
            </div>
            <div className="col s12 m11">
              <p className="flow-text">Global Prescription</p>
              <span>Patient Name: </span>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col s12 m1"><img src={meds} alt="" className="circle"/></div>
            <div className="col s12 m8">
                <p style={{marginLeft:'5px'}}>1) Prescription description here</p>
                <p style={{marginLeft:'5px'}}>{'  Notes: '}Once a day </p>
            </div>
            <div className="col s12 m1">
                <p className="secondary-content">30 pcs</p>
            </div>
            

            
          </div>
          <hr/>  

          <div className="row">
            <div className="col s12 m1"><img src={meds} alt="" className="circle"/></div>
            <div className="col s12 m8">
                <p style={{marginLeft:'5px'}}>2) Prescription description here</p>
                <p style={{marginLeft:'5px'}}>{'  Notes: '}Once a day </p>
            </div>
            <div className="col s12 m1">
                <p className="secondary-content">30 pcs</p>
            </div>  
          </div>
          <hr/>  

          <div className="row">
            <div className="col s12 m1"><img src={meds} alt="" className="circle"/></div>
            <div className="col s12 m8">
                <p style={{marginLeft:'5px'}}>3) Prescription description here</p>
                <p style={{marginLeft:'5px'}}>{'  Notes: '}Once a day </p>
            </div>
            <div className="col s12 m1">
                <p className="secondary-content">30 pcs</p>
            </div>  
          </div>
          <hr/>  


          <div>
              <p>Additional Notes Here from Provider....</p>
          </div>


        </div>
      </div>
       
         
    </div>
  )
}

export default Prescription







