export const Extrim = (p_string) => {

    if(p_string){
        let t = p_string.trim();
        t = t.replace(/(\r\n|\n|\r)/gm, '');
        t = t.replace(/[^0-9a-zA-Z.]/g, '')
        return t;
    }else{
        return '';
    }

}

export const IsValidMail = (p_mail) => {
    if(p_mail){
        let mail = p_mail.trim();
        return /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(mail);
    }
}


export const getAge = (date) => {
    let mybirthdate = new Date(date)
    let today = new Date()
    let today_month = today.getMonth() + 1
    let age = today.getYear() - mybirthdate.getYear()
    if (
    	(today_month < mybirthdate.getMonth() + 1 
        || 
        ((today_month === mybirthdate.getMonth() + 1) && (today.getDate() < 		mybirthdate.getDate())))) 		{
       age--;
    }
    return age;
}

export default Extrim

