// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { API, graphqlOperation } from 'aws-amplify';
import { createAttendeeGraphQl, createMeetingGraphQl, deleteMeetingGraphQl } from '../graphql/mutations';
import { createChimeMeeting, getAttendee, endChimeMeeting, getMeeting, joinChimeMeeting } from '../graphql/queries';


export async function createMeeting(title, attendeeName, region) {
  const joinInfo = await API.graphql(graphqlOperation(createChimeMeeting, {title: title, name: attendeeName, region: region }));
  const joinInfoJson = joinInfo.data.createChimeMeeting;
  const joinInfoJsonParse = JSON.parse(joinInfoJson.body);
  return joinInfoJsonParse;
}

export async function joinMeeting(meetingId, name) {
  const joinInfo = await API.graphql(graphqlOperation(joinChimeMeeting, {meetingId: meetingId, name: name}));
  const joinInfoJson = joinInfo.data.joinChimeMeeting;
  const joinInfoJsonParse = JSON.parse(joinInfoJson.body);
  return joinInfoJsonParse;
}

export async function endMeeting(meetingId, meetingIdClient) {
  
  const endInfo = await API.graphql(graphqlOperation(endChimeMeeting, {meetingId: meetingId}));
  const endInfoJson = endInfo.data.endChimeMeeting;
  //await API.graphql(graphqlOperation(deleteMeetingGraphQl, {input: {title: meetingId}}));
  try{
    console.log(meetingIdClient);
    await API.graphql(graphqlOperation(deleteMeetingGraphQl, {input: {title: meetingIdClient, _version: 1}}));
    
  }catch(error){
    return endInfoJson;
  }
  
  
  return endInfoJson;
}

export async function addMeetingToDB(title, meetingId, meetingData, session_queue_id, provider_id, tick_time_stamp) {
  await API.graphql(graphqlOperation(createMeetingGraphQl
    , {input: {title: title
        , meetingId: meetingId
        , data: meetingData
        , session_queue_id: session_queue_id
        , provider_id: provider_id
        , tick_time_stamp: tick_time_stamp
      }}));
}

export async function addAttendeeToDB(attendeeID, attendeeName) {
  await API.graphql(graphqlOperation(createAttendeeGraphQl, {input: {attendeeId: attendeeID, name: attendeeName }}));
}

export async function getMeetingFromDB(title) {
  const meetingInfo = await API.graphql(graphqlOperation(getMeeting, {title: title }));
  return meetingInfo;
}

export async function getAttendeeFromDB(attendeeId) {
  const attendeeInfo = await API.graphql(graphqlOperation(getAttendee, {attendeeId: attendeeId }));
  return attendeeInfo;
}