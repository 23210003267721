// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

import {
  Flex,
  FormField,
  Input,
  PrimaryButton,
  useMeetingManager,
  useMeetingStatus,
  Notification
} from 'amazon-chime-sdk-component-library-react';

import { 
  addAttendeeToDB,
  addMeetingToDB,
  createMeeting,
  getAttendeeFromDB,
  getMeetingFromDB,
  joinMeeting
 } from '../../utils/api';

 
import './chime.css';

import AppContext from '../../AppContext'
import { useParams } from 'react-router';


const HygeiaMeetingForm = (props) => {
  const meetingStatus = useMeetingStatus();
  const context = useContext(AppContext);
  const meetingManager = useMeetingManager();
  const [meetingTitle, setMeetingTitle] = useState('');
  const [meetingId, setMeetingId] = useState('');

  const [attendeeName, setName] = useState('');
  const [buttonText, setButtonText] = useState('Notify Provider');
  const params = useParams();
  const [sessionInfo, setSessionInfo] = useState([]);
  const [queueId, setQueueId] = useState('');
  const [respClientInfo, setRespClientInfo] = useState({});

  const [respContactInfo, setContactInfo] = useState({});

  const [disabledButton, setDisabledButton] = useState(true);
  const [tickTime, setTickTime] = useState('');

  const [titleReadOnly, setTitleReadOnly] = useState(_.isEmpty(params) ? false : true);

  function getAttendeeCallback() {
    return async (chimeAttendeeId, externalUserId) => {
      const attendeeInfo = await getAttendeeFromDB(chimeAttendeeId);
      const attendeeData = attendeeInfo.data.getAttendee;
      return {
        name: attendeeData.name
      };
    }
  }

  //October 16, 2021
  //Comment out below for now while checking the other process of checking meeting IDs
  // useEffect(()=>{
  //   if(props.meetingId){
  //     if(props.meetingId.length > 0){
  //       setMeetingTitle(props.meetingId);
  //     }
  //   }
  // },[props.meetingId]);

  useEffect(()=>{
    if( _.isEmpty(context.userState.clientid) && params){

      if (typeof(Storage) !== "undefined" && params.client_id && params.id && params.meeting_id) {
       
        localStorage.setItem("c_client_id", params.client_id);
        localStorage.setItem("c_queue_id", params.id);
        localStorage.setItem("c_meeting_id", params.meeting_id);

      } 

      Auth.federatedSignIn();
      return;
    }

    if (typeof(Storage) !== "undefined") {
        localStorage.removeItem("c_client_id");
        localStorage.removeItem("c_queue_id");
        localStorage.removeItem("c_meeting_id");
    }
    
      if(params.id){
        console.log("Parameters", params.id);
        setQueueId(params.id);
      }

      if(params.client_id){
        if(params.client_id !== context.clientInfo.id){
          console.log("Not Equal");
        }
      }

      const getMeetingInfo = async() =>{
        if(params.meeting_id){
          if(params.meeting_id !== 'null' && params.meeting_id !== 'undefined'){
            let meetingInfo = await API.graphql(graphqlOperation(queries.queryByMeetingId, {meetingId: params.meeting_id}));
            setMeetingId(params.meeting_id);
            setMeetingTitle(meetingInfo.data.queryByMeetingId.items[0].title);
          }
        }
      }
      getMeetingInfo();

  
  },[]);

  useEffect(()=>{
    
    const getQueue = async () =>{
      let sessionQueue;
      if(context.clientInfo.id && queueId.length > 0){
        sessionQueue = await API.graphql(graphqlOperation(queries.getSessionQueues, {id: queueId}));
        console.log(sessionQueue);
        setSessionInfo(sessionQueue.data.getSessionQueues);
      }
    }
    getQueue();

  },[queueId]);

  useEffect(()=>{
    console.log(sessionInfo)
    //check condition if the meeting_id is null

    if((sessionInfo.meeting_id === '' || !sessionInfo.meeting_id) && params.client_id){
        const d = new Date();
        const n = d.getTime().toString();
        setTickTime(n);
        setMeetingTitle(params.client_id + '#' + n);
    }
    
  },[sessionInfo]);


  useEffect(()=>{
    if(!_.isEmpty(context.userState.clientid) && !_.isEmpty(context.clientInfo.first_name)){
      
      if(context.clientInfo.is_provider === 'true'){
        setButtonText('Notify Client');
      }
      if(!_.isEmpty(context.clientInfo.nickname)){
        setName(context.clientInfo.nickname);
      }else{
        setName(context.clientInfo.first_name)
      }
      getInformation();
    }
  },[context.userState, context.clientInfo]);

  const getInformation = async () =>{
    try{
      let respClientInfo = await API.graphql(graphqlOperation(queries.getClients, {id: params.client_id}));
      let respContactInfo = await API.graphql(graphqlOperation(queries.queryClientsProfileByClientId, {client_id: params.client_id}));
  
      setRespClientInfo(respClientInfo);
      setContactInfo(respContactInfo);
    }catch(error){
      console.log("Fetching of profile failed", error);
    }
  }

  const clickedJoinMeeting = async (event) => {
    event.preventDefault();
    
    meetingManager.getAttendee = getAttendeeCallback();
    
    const title = meetingTitle.trim().toLocaleLowerCase();
    const name = attendeeName.trim();

    const meetingResponse = await getMeetingFromDB(title);
    console.log(meetingResponse);
    const meetingJson = meetingResponse.data.getMeeting;

    try {
      
      if (meetingJson) {
        const meetingData = JSON.parse(meetingJson.data);
        const joinInfo = await joinMeeting(meetingData.MeetingId, name);
        
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);
 
        await meetingManager.join({
          meetingInfo: meetingData,
          attendeeInfo: joinInfo.Attendee
        });

        //october 23
        let item = {...sessionInfo};
        
        let d = new Date();
        let n = d.getTime();

        delete item.client_info;
        delete item._deleted;
        delete item._lastChangedAt;
        delete item.createdAt;
        delete item.updatedAt;
        item.session_start = n;

        try{
          const results = await API.graphql(
            graphqlOperation(mutations.updateSessionQueues, {input:{...item}})
          );
          console.log("RESULTS AFTER UPDATE", results);
        }catch(error){
          console.log(error);
        }

      } else {
        

        const joinInfo = await createMeeting(title, name, 'us-east-1');

        setMeetingId(joinInfo.Meeting.MeetingId);
        await addMeetingToDB(title, joinInfo.Meeting.MeetingId, JSON.stringify(joinInfo.Meeting), params.id, context.clientInfo.id, tickTime);
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);

        await meetingManager.join({
          meetingInfo: joinInfo.Meeting,
          attendeeInfo: joinInfo.Attendee
        });

        let item = {...sessionInfo};
        
        delete item.client_info;
        delete item._deleted;
        delete item._lastChangedAt;
        delete item.createdAt;
        delete item.updatedAt;
        item.status = "online";
        item.meeting_id = joinInfo.Meeting.MeetingId;
        item.provider_id = context.clientInfo.id;
        
        try{
          const results = await API.graphql(
            graphqlOperation(mutations.updateSessionQueues, {input:{...item}})
          );
          console.log("RESULTS AFTER UPDATE", results);
        }catch(error){
          console.log(error);
        }
    
        setDisabledButton(false);
        clickedNotify(event, joinInfo.Meeting.MeetingId);

      }
    } catch (error) {
      //alert notifications
      console.log(error);
    }
  
    // At this point you can let users setup their devices, or start the session immediately
    await meetingManager.start();
  };

  const clickedNotify = async(event, p_meeting_id) =>{
    if(event){
      event.preventDefault();
    }

    if(params.client_id){

        //getClients

        let respClientInfo = await API.graphql(graphqlOperation(queries.getClients, {id: params.client_id}));

        let respContactInfo = await API.graphql(graphqlOperation(queries.queryClientsProfileByClientId, {client_id: params.client_id}));

        let clientProfile = respClientInfo.data.getClients;

        let contactInfo = respContactInfo.data.queryClientsProfileByClientId.items[0];

        let role = context.clientInfo.is_provider === 'true' ? "provider" : "client";
        
       
        API.get('HygeiaInternalAPIs','/internal/notifyclient/',{

          queryStringParameters:{
            client_id: params.client_id,
            role: role,
            email_address: contactInfo.email_address,
            name: clientProfile.first_name + ' ' + clientProfile.last_name,
            contact_no: contactInfo.mobile_country_code + contactInfo.mobile_phone_number,
            queue_id: params.id,
            meeting_id: meetingId ? meetingId : p_meeting_id,
            meeting_title:  meetingTitle.trim().toLocaleLowerCase()
          }
        
        }).then((results)=>{


          alert("Notification sent");  
          return true;
    
        }).catch(err => { 
          
          return false;
        }); 

      
    }else{
      alert("Please pick client / provider to notify");
    }
  }

  const Severity = {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
  };

  const handleChange = (e) => {
    if(titleReadOnly === false) setMeetingTitle(e.target.value);
  }

  return (
    <React.Fragment>
    <form className = 'default' style={{margin:'10px'}}>
      <FormField
        field = {Input}     
        label = 'Meeting ID'
        value = {meetingTitle}
        
        onChange = { (e) => handleChange(e) }

        fieldProps={{
          name: 'meetingTitle',
          placeholder: 'Enter meeting title',

        }}
      />

      <FormField
        field={Input}
        label="Name"
        value={attendeeName}
        fieldProps={{
          name: 'Name',
          placeholder: 'Attendee Name',
          readOnly:  true 
        }} 
      />

      <Flex
        container
        layout="fill-space-centered"
        style={{ marginTop: '2.5rem' }}
      >
          <PrimaryButton label="Join Meeting" disabled={!disabledButton} onClick={clickedJoinMeeting} style={{backgroundColor:'#3DAE52', margin: '5px'}} />
          <PrimaryButton label={buttonText} disabled={disabledButton} onClick={clickedNotify} style={{backgroundColor:'#3DAE52', margin: '5px'}} />
      </Flex>

      
    </form>
     
    </React.Fragment>
  );
};

export default HygeiaMeetingForm;
