import React, { useState, useEffect, useContext } from 'react'
import 'materialize-css';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { Button } from 'react-materialize';
import  StripeContainer  from '../../components/payment/';
import hygeia from '../../assets/imgs/hygeia_pinay_with_mask.png';
import './redirect.css';
import AppContext from '../../AppContext';
import { storeItem, getItem } from '../../utils/initialstate';

const Redirect = (props) => {
  
  //let users = props.users;
  const context = useContext(AppContext);

  const [showStripe, setShowStripe] = useState(false);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState({
    login: false,
    registration: false,
    authorization: false,
    queue: false,
    consult: false,
    prescription: false,
    payment: false
  })
  
  /*
  * GO BACK
  * 
  */
  const goBack = () => {
    if(typeof history.location.state !== 'undefined'){
      if(typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    }else{
      goHome();
    }
  }

  const goHome = () =>{
    history.push("/");
  }

  const goToClientWaitingRoom = () =>{
    
    if(steps.login === true && steps.registration === true && steps.authorization === true && steps.queue === true){
      const location = {
        pathname: `/client`,
        state: { from: '/redirect'}
      }
      history.push(location);
    }else{
      checkSteps();
    }
    
  }

  const checkSteps = async () =>{
    //let userSessionAttributes = "";
    let login = false;
    let registration = false;
    let gathering = false;
    let authorization = false;
    let queue = false;
    let consult = false;
    let prescription = false;
    let payment = false;
    let user_role = "client";
    let time;

    // if(context.userState.authenticated==='yes'){
    //   alert('')
    //   login = true;
    // }
    
    // if(context.userState.clientid){
    //   if(context.userState.clientid.length > 0)
    //     registration = true;
    // }

    await Auth.currentAuthenticatedUser()
      .then(
      user => {
        login = true;
        if(user.attributes["custom:clientid"]){
          registration = true;
          
          const tempItem = getItem("undefined");
          const tempItemSession = getItem("undefinedsession");
       
          if(tempItem && tempItemSession){
            if(context.clientInfo.id){
              storeItem(context.clientInfo.id, tempItem);
              storeItem(context.clientInfo.id + 'steps', tempItemSession) ;
              localStorage.removeItem("undefined");
              localStorage.removeItem("undefinedsession");
            }
          }
          
        }
        


      }
    ).catch(err => {
        login = false;
    });
    
    if (typeof(Storage) !== "undefined") {
      // Store
      const logs = sessionStorage.getItem("chatLogs");
      if(logs !== null){
        const chatLogs = JSON.parse(logs);
        gathering = chatLogs.gathering;
        authorization = chatLogs.authorization;
        queue = chatLogs.queue;
        consult = chatLogs.consult;
        prescription = chatLogs.prescription;
        payment = chatLogs.payment;
        user_role = chatLogs.user_role;
        time = chatLogs.time;
      }else{
        return;
      }
      
    }

    let l_step = 0

    if(login === false){
      console.log("SET STEP TO STEP 1")
      l_step = 1
    }
    if(registration === false && l_step === 0){
      console.log("SET STEP TO STEP 2")
      l_step = 2
    }
    if(gathering === false && l_step === 0){
      console.log("SET STEP TO STEP 3")
      l_step = 3
    }
    if(authorization === false && l_step === 0){
      console.log("SET STEP TO STEP 4")
      l_step = 4
    }
    if(queue === false && l_step === 0){
      console.log("SET STEP TO STEP 5")
      l_step = 5
    }
    if(consult === false && l_step === 0){
      console.log("SET STEP TO STEP 6")
      l_step = 6
    }
    if(prescription === false && l_step === 0){
      console.log("SET STEP TO STEP 7")
      l_step = 7
    }
    if(payment === false && l_step === 0){
      console.log("SET STEP TO STEP 8")
      l_step = 8
      
    }

    let tmpValue = {
      login,
      registration,
      gathering,
      authorization,
      queue,
      consult,
      payment,
      user_role,
      time
    }

    sessionStorage.setItem("chatLogs", JSON.stringify(tmpValue))
    setSteps({...steps, 
      login,
      registration,
      gathering,
      authorization,
      queue,
      consult,
      payment})

    setStep(l_step)
    
  }

  useEffect(() => {
    const check = async() =>{
      await checkSteps();
    }
    check();
  },[])


  useEffect(() => {
      if(context.clientInfo.id){
        const tempItem = getItem("undefined");
        const tempItemSession = getItem("undefinedsession");
    
        if(tempItem && tempItemSession){
          storeItem(context.clientInfo.id, tempItem);
          storeItem(context.clientInfo.id + 'steps', tempItemSession) ;
          localStorage.removeItem("undefined");
          localStorage.removeItem("undefinedsession");
        }
      }
  },[context.clientInfo])



  useEffect(() => {  
    const check = async() =>{
      await checkSteps();
    }

    if(showStripe === false)
      check();
  },[showStripe])

  useEffect(() => {
    console.log("STEP: ", step)
    if(step === 1){
        Auth.federatedSignIn();  
    }

    if(step === 2){
      if(steps.login === true)
        history.push({
            pathname: '/profile'
        })
      else
        Auth.federatedSignIn();  
    }

    if(step === 4){
      if(steps.login === true && steps.registration === true)
        setShowStripe(true);
    }

    if(step === 5){

    }

  },[step])

  const clearSession = () => {
    sessionStorage.setItem("chatLogs","");
    sessionStorage.setItem("chatLogsHistory","");
    history.push({
      pathname: '/'
    })

  }
  const nextStep = () =>{
    if(step === 1){
      Auth.federatedSignIn();  
    }
    if(step === 2){
      history.push({
        pathname: '/profile'
      })
    }
    if(step === 4){
      setShowStripe(true);
    }
  }
return (
    <div>

    <div>
      <nav>
        <div className="nav-wrapper hygeiabackground">
        <div>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
            <a href="#!"  className="breadcrumb" style={{"paddingLeft": "10px"}}>Tele Consultation:</a>
        </div>
        </div>
      </nav>
      
      <StripeContainer id="idShowAuthorization" 
        clientId={ props.userData.clientid } 
        userData = { props.userData } 
        showStripe = { showStripe } 
        setShowStripe = { setShowStripe }
        
        step = { step }
        setStep = { setStep }
      />  

      <div className="row container center" style={{"marginTop":"20px","background":"transparent"}}> 
      <div>
        <div className="row container center">
          <div className="col s12 m12">
              <div className='container'>
                
                <h5 className="green-text">TeleHealth Consultation in progress</h5>
                <span> <img className='top-avatar' src={hygeia} alt="Hygeia"/> </span>
              </div>
            
          </div>
        </div>

        <div className="row container center">
              <div className="col s12 m12">
               
                  {
                      step < 5 ? 
                      <div className='chat-msg-hygeia'> 
                        
                        <div className='cm-msg-text'>
                          <div className='cm-msg-text'> 
                            <span>You will be prompted with your next step... </span>     
                          </div>
                          <div className='cm-msg-text'> 
                            <span>If you happen to have cancelled the step please click the step link below or click the clear session button </span>     
                          </div>
                          <h5>STEP: {step}</h5>
                        </div>
                        
                      </div>
                    : null
                  }
                  
                  {

                    step === 5 ? 
                    <div className='chat-msg-hygeia'> 
                      
                      <div className='cm-msg-text'>
                        <div className='cm-msg-text'> 
                          <span> You are now queued for a TeleHealth consultation... </span>     
                        </div>
                        <div className='cm-msg-text'> 
                          
                            <span> Please standby... </span>     
                          
                        </div>
                        
                      </div>
                      
                    </div>
                    : null

                  }
            </div>
        </div>
        <div className="row container center">
            <div className="col s12 m12">
                      <div className='chat-msg-hygeia'> 
                      
                        <div>
                          <div className='cm-msg-text'> 
                            <span> You are now queued for a TeleHealth consultation... </span>     
                          </div>
                          <div className='cm-msg-text'> 
                            
                              <span> Please standby... </span>     
                            
                          </div>
                          
                          {
                            steps.login === true ? 
                              <div className='cm-msg-text'> 
                                <a href="#!">1 Login: Ok</a>
                              </div>
                              : 
                              <div className='cm-msg-text'> 
                                <a href="#!" style={{color:"red"}} onClick={ ()=>{nextStep()}} >Login</a>
                              </div>
                              
                          }
                          {
                            steps.registration === true ? 
                              <div className='cm-msg-text'> 
                                <a href="#!">2 Registration: Ok</a>
                              </div>
                              
                              : 
                              <div className='cm-msg-text'> 
                                <a href="#!" style={{color:"red"}} onClick={ ()=>{nextStep()}} >Registration</a>
                              </div>
                              
                          }
                          {
                            steps.gathering === true ? 
                              <div className='cm-msg-text'> 
                                <a href="#!">3 Symptoms Gathering: Ok</a> 
                              </div>
                              : 
                              <div className='cm-msg-text'> 
                                <a href="#!" style={{color:"red"}} onClick={ ()=>{nextStep()}} >3 Symptoms Gathering</a>
                              </div>
                          }
                          {
                            steps.authorization === true ? 
                              <div className='cm-msg-text'> 
                                <a href="#!">4 Authorization: Ok</a>  
                              </div>
                               
                              : 
                              <div className='cm-msg-text'> 
                                <a href="#!" style={{color:"red"}} onClick={ ()=>{nextStep()}} >4 Authorize Payment</a>  
                              </div>
                              
                          }
                          {
                            steps.consultation === true ? 
                            <div className='cm-msg-text'> 
                              <a href="#!">5 Telecon: Ok</a>    
                            </div>
                             
                            : 
                            <div className='cm-msg-text'> 
                              <a href="#!" style={{color:"red"}} onClick={()=>goToClientWaitingRoom()} >5 Telecon: Queued</a>    
                            </div>
                            
                          }
                          {
                            steps.prescription === true ? 
                            <div className='cm-msg-text'> 
                              <a href="#!">6 Prescription: Ok</a>
                            </div>
                            : 
                            <div className='cm-msg-text'> 
                              <a href="#!" style={{color:"red"}}>6 Prescription: not done</a>  
                            </div>
                            
                          }
                          
                          {
                            steps.payment === true ? 
                            <div className='cm-msg-text'> 
                              <a href="#!">7 Payment: Ok</a>
                            </div>
                             
                            : 
                            <div className='cm-msg-text'> 
                              <a href="#!" style={{color:"red"}}>7 Payment: not done</a>
                            </div>
                            
                          }
                          <div className='cm-msg-text'> 
                            <Button type="submit" style={{backgroundColor:"#23b241"}} id="chat-submit" onClick = {() => { clearSession() }} >
                              Cancel Session
                            </Button>    
                          </div>
                        </div>
                      </div>
                      
            </div>
        </div>
                  
      </div>
     
      </div>
      
    </div> 
   
  </div>
  	  
  )
}

export default Redirect

