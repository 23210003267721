//pk_test_51IYt8KETPcdxiUHqTJ708KWSGcFdJf5H7MYaiKRoHoqO3wBiTQ5DLglYL3S73fMwPkzvUbYDd1Nu0Dsz0NIyZir400tra1e50d

import React from 'react';

import {loadStripe} from '@stripe/stripe-js';
import Payment from './payment';
import {

  Elements

} from '@stripe/react-stripe-js';

const public_key = "pk_test_51IYt8KETPcdxiUHqTJ708KWSGcFdJf5H7MYaiKRoHoqO3wBiTQ5DLglYL3S73fMwPkzvUbYDd1Nu0Dsz0NIyZir400tra1e50d";
const stripeTestPromise = loadStripe(public_key);

const  StripeContainer = (props) => {
 

  return (
    <Elements stripe={stripeTestPromise}>
        <Payment id = {props.id} 
        showStripe = {props.showStripe} 
        clientid = {props.clientid} 
        userData = {props.userData} 
        setShowStripe = {props.setShowStripe}
        
        step = {props.step}
        setStep = {props.setStep}
        />
    </Elements>
  );
}
export default StripeContainer
