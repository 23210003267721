
import React, { useEffect, useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from "react-router-dom"
import { API, graphqlOperation } from 'aws-amplify';

import { genKey } from '../../utils/initialstate';
import AppContext from '../../AppContext'
import './medical.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function ViewMedical(props) {
    const [pdf, setPdf] = useState();
    const [show, setShow] = useState(false);
    const params = useParams();
    const context = useContext(AppContext);
    const [numPages, setNumPages] = useState();

    useEffect(() => {

        if (props.pdf) {
            setPdf(props.pdf);
        }

        if (params.id && context.clientInfo.id) {

        }

    }, [props.pdf]);

    useEffect(() => {

        setShow(props.show);

    }, [props.show]);


    function onDocumentLoadSuccess({ numPages }) {
        if (numPages) {
            setNumPages(numPages);
        }
    }

    const hideThis = (e) => {
        if(props.setShow){
            props.setShow(false);
        }else{
            setShow(false);
        }
        
    }

    return (
        <React.Fragment>
            {
                show === true && props.modal ?
                    <div className="modalHygeiaBackground" >
                        <div className="modalHygeiaDialog">
                            <div class="grid-example col s12 m6 offset-m3">
                                <div style={{margin:"20px"}}>
                                    <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "marginTop": "30px" }} onClick={(e) => { hideThis(e) }}>Close</a>
                                </div>  
                            </div>
                            
                            <div class="row">
                                <div class="grid-example col s12 m6 offset-m3">
                                    <Document file={pdf} scale={1} onLoadSuccess={(e) => onDocumentLoadSuccess(e)} style={{width:"630px"}}>
                                        {
                                            Array.apply(null, Array(numPages))
                                                .map((x, i) => i + 1)
                                                .map(page =>

                                                    <div className="pageBorderTop" key={genKey()} >
                                                        <Page key={genKey()} pageNumber={page} size="A4" style={{ textAlign: 'center' }}  />
                                                    </div>

                                                )
                                        }
                                    </Document>
                                  
                                </div>
                            </div>   
                                
                            
                            
                        </div>
                    </div>
                    :         
                    null  
            }

            {
                show === true && !props.modal ?
                    <div class="row">
                        <div class="grid-example col s12 m6 offset-m3">
                            <div style={{margin:"20px"}}>
                                <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "marginTop": "30px" }} onClick={(e) => { hideThis(e) }}>Close</a>
                            </div>  
                        </div>
                        <div class="grid-example col s12 m6 offset-m3" style={{"overflow":"scroll"}}>
                           
                            <Document file={pdf} scale={1} onLoadSuccess={(e) => onDocumentLoadSuccess(e)} style={{width:"630px"}}>
                                {
                                    Array.apply(null, Array(numPages))
                                        .map((x, i) => i + 1)
                                        .map(page =>

                                            <div className="pageBorderTop" key={genKey()} >
                                                <Page key={genKey()} pageNumber={page} size="A4" style={{ textAlign: 'center' }}  />
                                            </div>

                                        )
                                }
                            </Document>
                           
                        </div>
                    </div> 
                : 
                null
            }
        </React.Fragment>

    )
}

export default ViewMedical