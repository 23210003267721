import 'materialize-css';
import "../../css/materialize.css";
import { Auth } from 'aws-amplify';
//import { Navbar, NavItem, Icon,Dropdown } from 'react-materialize';
import { useHistory, useLocation } from "react-router-dom";
import hygeialogo from '../../assets/imgs/logo_64.png';
import React,{useEffect,useState} from "react";
import AppContext from '../../AppContext';
import M from 'materialize-css';

//import {userCredentials} from '../../utils/initialstate.js'

//import ReactDOM from 'react-dom';

const MainNav = () => {
  const appContext = React.useContext(AppContext);
  const history = useHistory()
  const location = useLocation()

  const [options, setOptions] = useState({
    draggable: true,
    edge: 'right',
    inDuration: 250,
    onCloseEnd: null,
    onCloseStart: null,
    onOpenEnd: null,
    onOpenStart: null,
    outDuration: 200,
    preventScrolling: true
  })

  const [options1, setOptions1] = useState({
    draggable: true,
    edge: 'left',
    inDuration: 250,
    onCloseEnd: null,
    onCloseStart: null,
    onOpenEnd: null,
    onOpenStart: null,
    outDuration: 200,
    preventScrolling: true
  })

  //console.log("userState", appContext.userState);
  const go = (p_path, p_from) =>{
    let el = document.getElementById("idSideNav"); 
    let instance = M.Sidenav.getInstance(el);  
    instance.close();

    let el1 = document.getElementById("idSideNav1"); 
    let instance1 = M.Sidenav.getInstance(el1);  
    instance1.close();

    if(p_path === '/profile') {
      sessionStorage.session_p = '';
    }
    
    history.push(p_path, { from: location.pathname });
  }
  useEffect(() => {
    let el = document.getElementById("idSideNav"); 
    let el1 = document.getElementById("idSideNav1"); 
    M.Sidenav.init(el, options);
    M.Sidenav.init(el1, options1);

  },[])

  
  
  const clickSideNav = (p_location) =>{
    if(p_location==='right'){
      let el = document.getElementById("idSideNav"); 
      let instance = M.Sidenav.getInstance(el);  
      instance.open();
    }
    if(p_location==='left'){
      let el = document.getElementById("idSideNav1"); 
      let instance = M.Sidenav.getInstance(el);  
      instance.open();
    }
    
  }

  const signOutUser = async() => {
    sessionStorage.removeItem("chatLogs","");
    sessionStorage.removeItem("chatLogsHistory","");
    sessionStorage.removeItem(appContext.clientInfo.id + 'session');
    sessionStorage.removeItem(appContext.clientInfo.id);
    sessionStorage.removeItem(appContext.clientInfo.id + 'steps');
    sessionStorage.removeItem(appContext.clientInfo.id + 'session');

    Auth.signOut(); 
    //go("/signout");

    const location = {
      pathname: `/signout`,
      state: { from: '/'}
    }
    history.push(location);
  }

  return (
  <div>
        <div className="hygeialineheader" ></div>
        <nav>
          <div className="nav-wrapper">
            <ul>
            <li>
            <a href="#!" data-target="idSideNav1" className="hide-on-large-and-up sidenav-trigger green-text" onClick={()=>{clickSideNav('left')}}><i className="material-icons">menu</i></a>
            </li>
            </ul>
            <a href="#!" className="brand-logo center"><img src={hygeialogo} alt="Hygeia"/></a>
            {
              appContext.userState.authenticated!=='yes' ? 
              <ul className="right hide-on-med-and-down">
                <li><a href="#! " onClick={() => {Auth.federatedSignIn()}}>Sign In</a></li>
              </ul>
              :           
              <ul className="right hide-on-med-and-down">
                <li><a href="#! " className = "green-text" onClick={()=>{clickSideNav('right')}}>My Account</a></li>
              </ul>
            }    
          </div>
          
        </nav>
        <div className="sidenav" id="idSideNav">
            {appContext.userState.authenticated==='yes' ? 
            <div>
            <div className="user-view">
              <div>
                <img src={hygeialogo} alt="HygeiaLogo"/>
              </div>
             
            </div>
            <hr></hr>
            <label className="custombutton" onClick={()=>{ go("/"); }}>Home</label>
            <label className="custombutton" onClick={()=>{ go("/profile"); }}>Profile</label>
            <label className="custombutton" onClick={()=>{ go("/mainclient"); }}>myGlobalChart</label>
            <label className="custombutton" onClick={()=>{ go("/provider"); }}>Provider</label>
            <label className="custombutton" onClick={()=>{ go("/billing"); }}>Billing</label>
            <label className="custombutton" onClick={()=>{ go("/chime"); }}>i2i</label>
            
            <hr></hr>
            { appContext.userState.authenticated==="yes" && appContext.userState.role==="admin"? 
              <label className="custombutton" onClick={()=>{ go("/user"); }}>Admin</label>
            : ''
            }
            <label className="custombutton" onClick={()=>signOutUser()}>Sign Out</label>
            </div>
            :
            <label className="custombutton" onClick={() => {Auth.federatedSignIn()}}>Sign In</label>
            }
        </div>
        <div className="sidenav" id="idSideNav1">
            {appContext.userState.authenticated==='yes' ? 
            <div>
            <div className="user-view">
              <div>
                <img src={hygeialogo} alt="HygeiaLogo"/>
              </div>
             
            </div>
            <hr></hr>
            <label className="custombutton" onClick={()=>{ go("/"); }}>Home</label>
            <label className="custombutton" onClick={()=>{ go("/profile"); }}>Profile</label>
            <label className="custombutton" onClick={()=>{ go("/mainclient"); }}>myGlobalChart</label>
            <label className="custombutton" onClick={()=>{ go("/provider"); }}>Provider</label>
            <label className="custombutton" onClick={()=>{ go("/billing"); }}>Billing</label>
            <label className="custombutton" onClick={()=>{ go("/chime"); }}>i2i</label>
            
            <hr></hr>
            { appContext.userState.authenticated==="yes" && appContext.userState.role==="admin"? 
              <label className="custombutton" onClick={()=>{ go("/user"); }}>Admin</label>
            : ''
            }
            <label className="custombutton" onClick={()=>{signOutUser()}}>Sign Out</label>
            </div>
            :
            <label className="custombutton" onClick={() => {Auth.federatedSignIn()}}>Sign In</label>
            }
        </div>
  </div>      
  )
}

export default MainNav

