/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClients = /* GraphQL */ `
  mutation CreateClients(
    $input: CreateClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    createClients(input: $input, condition: $condition) {
      id
      first_name
      last_name
      date_of_birth
      city_of_birth
      nickname
      middle_name
      gender
      guardian_id_1
      guardian_id_2
      pcp_id
      ocp_id
      other_physicians
      other_name
      agree_terms_of_use
      date_agreed_terms_of_use
      agree_privacy_policy
      date_agreed_privacy_policy
      consent_of_telehealth
      date_consented_of_telehealth
      is_provider
      unique_identifier
      user_action
      created_date
      created_by
      last_update_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClients = /* GraphQL */ `
  mutation UpdateClients(
    $input: UpdateClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    updateClients(input: $input, condition: $condition) {
      id
      first_name
      last_name
      date_of_birth
      city_of_birth
      nickname
      middle_name
      gender
      guardian_id_1
      guardian_id_2
      pcp_id
      ocp_id
      other_physicians
      other_name
      agree_terms_of_use
      date_agreed_terms_of_use
      agree_privacy_policy
      date_agreed_privacy_policy
      consent_of_telehealth
      date_consented_of_telehealth
      is_provider
      unique_identifier
      user_action
      created_date
      created_by
      last_update_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClients = /* GraphQL */ `
  mutation DeleteClients(
    $input: DeleteClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    deleteClients(input: $input, condition: $condition) {
      id
      first_name
      last_name
      date_of_birth
      city_of_birth
      nickname
      middle_name
      gender
      guardian_id_1
      guardian_id_2
      pcp_id
      ocp_id
      other_physicians
      other_name
      agree_terms_of_use
      date_agreed_terms_of_use
      agree_privacy_policy
      date_agreed_privacy_policy
      consent_of_telehealth
      date_consented_of_telehealth
      is_provider
      unique_identifier
      user_action
      created_date
      created_by
      last_update_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientsProfile = /* GraphQL */ `
  mutation CreateClientsProfile(
    $input: CreateClientsProfileInput!
    $condition: ModelClientsProfileConditionInput
  ) {
    createClientsProfile(input: $input, condition: $condition) {
      id
      client_id
      sub
      email_address
      mobile_country_code
      mobile_phone_number
      street_address_line_1
      street_address_line_2
      city
      state_code
      zip_code_1
      zip_code_2
      country_code
      country
      user_name
      internal_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientsProfile = /* GraphQL */ `
  mutation UpdateClientsProfile(
    $input: UpdateClientsProfileInput!
    $condition: ModelClientsProfileConditionInput
  ) {
    updateClientsProfile(input: $input, condition: $condition) {
      id
      client_id
      sub
      email_address
      mobile_country_code
      mobile_phone_number
      street_address_line_1
      street_address_line_2
      city
      state_code
      zip_code_1
      zip_code_2
      country_code
      country
      user_name
      internal_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientsProfile = /* GraphQL */ `
  mutation DeleteClientsProfile(
    $input: DeleteClientsProfileInput!
    $condition: ModelClientsProfileConditionInput
  ) {
    deleteClientsProfile(input: $input, condition: $condition) {
      id
      client_id
      sub
      email_address
      mobile_country_code
      mobile_phone_number
      street_address_line_1
      street_address_line_2
      city
      state_code
      zip_code_1
      zip_code_2
      country_code
      country
      user_name
      internal_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProvidersInfo = /* GraphQL */ `
  mutation CreateProvidersInfo(
    $input: CreateProvidersInfoInput!
    $condition: ModelProvidersInfoConditionInput
  ) {
    createProvidersInfo(input: $input, condition: $condition) {
      id
      client_id
      work_street_address_line_1
      work_street_address_line_2
      work_city
      work_state_code
      work_zip_code_first5
      work_zip_code_last4
      work_country_code
      taxonomy
      enumeration_type
      npi_id
      npi_status
      credential_status
      status
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProvidersInfo = /* GraphQL */ `
  mutation UpdateProvidersInfo(
    $input: UpdateProvidersInfoInput!
    $condition: ModelProvidersInfoConditionInput
  ) {
    updateProvidersInfo(input: $input, condition: $condition) {
      id
      client_id
      work_street_address_line_1
      work_street_address_line_2
      work_city
      work_state_code
      work_zip_code_first5
      work_zip_code_last4
      work_country_code
      taxonomy
      enumeration_type
      npi_id
      npi_status
      credential_status
      status
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProvidersInfo = /* GraphQL */ `
  mutation DeleteProvidersInfo(
    $input: DeleteProvidersInfoInput!
    $condition: ModelProvidersInfoConditionInput
  ) {
    deleteProvidersInfo(input: $input, condition: $condition) {
      id
      client_id
      work_street_address_line_1
      work_street_address_line_2
      work_city
      work_state_code
      work_zip_code_first5
      work_zip_code_last4
      work_country_code
      taxonomy
      enumeration_type
      npi_id
      npi_status
      credential_status
      status
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSessionQueues = /* GraphQL */ `
  mutation CreateSessionQueues(
    $input: CreateSessionQueuesInput!
    $condition: ModelSessionQueuesConditionInput
  ) {
    createSessionQueues(input: $input, condition: $condition) {
      id
      client_id
      provider_id
      date_queued
      session_start
      session_end
      session_total_time
      client_log
      provider_log
      main_concern
      symptoms
      classification
      specialization_a
      specialization_b
      state
      city
      payment_process
      amount
      meeting_id
      payment_transaction_number
      payment_session_id
      payment_status
      steps_history
      chat_history
      status
      user_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSessionQueues = /* GraphQL */ `
  mutation UpdateSessionQueues(
    $input: UpdateSessionQueuesInput!
    $condition: ModelSessionQueuesConditionInput
  ) {
    updateSessionQueues(input: $input, condition: $condition) {
      id
      client_id
      provider_id
      date_queued
      session_start
      session_end
      session_total_time
      client_log
      provider_log
      main_concern
      symptoms
      classification
      specialization_a
      specialization_b
      state
      city
      payment_process
      amount
      meeting_id
      payment_transaction_number
      payment_session_id
      payment_status
      steps_history
      chat_history
      status
      user_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSessionQueues = /* GraphQL */ `
  mutation DeleteSessionQueues(
    $input: DeleteSessionQueuesInput!
    $condition: ModelSessionQueuesConditionInput
  ) {
    deleteSessionQueues(input: $input, condition: $condition) {
      id
      client_id
      provider_id
      date_queued
      session_start
      session_end
      session_total_time
      client_log
      provider_log
      main_concern
      symptoms
      classification
      specialization_a
      specialization_b
      state
      city
      payment_process
      amount
      meeting_id
      payment_transaction_number
      payment_session_id
      payment_status
      steps_history
      chat_history
      status
      user_account_type
      created_date
      created_by
      last_updated_date
      last_updated_by
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingGraphQl = /* GraphQL */ `
  mutation CreateMeetingGraphQl(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeetingGraphQL(input: $input, condition: $condition) {
      meetingId
      title
      data
      session_queue_id
      provider_id
      tick_time_stamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeetingGraphQl = /* GraphQL */ `
  mutation DeleteMeetingGraphQl(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeetingGraphQL(input: $input, condition: $condition) {
      meetingId
      title
      data
      session_queue_id
      provider_id
      tick_time_stamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttendeeGraphQl = /* GraphQL */ `
  mutation CreateAttendeeGraphQl(
    $input: CreateAttendeeInput!
    $condition: ModelAttendeeConditionInput
  ) {
    createAttendeeGraphQL(input: $input, condition: $condition) {
      attendeeId
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttendeeGraphQl = /* GraphQL */ `
  mutation DeleteAttendeeGraphQl(
    $input: DeleteAttendeeInput!
    $condition: ModelAttendeeConditionInput
  ) {
    deleteAttendeeGraphQL(input: $input, condition: $condition) {
      attendeeId
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMedicalHistory = /* GraphQL */ `
  mutation CreateMedicalHistory(
    $input: CreateMedicalHistoryInput!
    $condition: ModelMedicalHistoryConditionInput
  ) {
    createMedicalHistory(input: $input, condition: $condition) {
      id
      client_id
      provider_id
      session_id
      revision
      signed
      share_history
      date_signed
      data
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicalHistory = /* GraphQL */ `
  mutation UpdateMedicalHistory(
    $input: UpdateMedicalHistoryInput!
    $condition: ModelMedicalHistoryConditionInput
  ) {
    updateMedicalHistory(input: $input, condition: $condition) {
      id
      client_id
      provider_id
      session_id
      revision
      signed
      share_history
      date_signed
      data
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicalHistory = /* GraphQL */ `
  mutation DeleteMedicalHistory(
    $input: DeleteMedicalHistoryInput!
    $condition: ModelMedicalHistoryConditionInput
  ) {
    deleteMedicalHistory(input: $input, condition: $condition) {
      id
      client_id
      provider_id
      session_id
      revision
      signed
      share_history
      date_signed
      data
      created_date
      created_by
      last_updated_date
      last_updated_by
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMedicalShareHistory = /* GraphQL */ `
  mutation CreateMedicalShareHistory(
    $input: CreateMedicalShareHistoryInput!
    $condition: ModelMedicalShareHistoryConditionInput
  ) {
    createMedicalShareHistory(input: $input, condition: $condition) {
      id
      medical_record_id
      client_id
      provider_id
      npi_id
      session_id
      revision
      granted
      start_date
      end_date
      created_date
      created_by
      last_updated_date
      last_updated_by
      provider_email_address
      provider_phone_number
      provider_name
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      provider_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicalShareHistory = /* GraphQL */ `
  mutation UpdateMedicalShareHistory(
    $input: UpdateMedicalShareHistoryInput!
    $condition: ModelMedicalShareHistoryConditionInput
  ) {
    updateMedicalShareHistory(input: $input, condition: $condition) {
      id
      medical_record_id
      client_id
      provider_id
      npi_id
      session_id
      revision
      granted
      start_date
      end_date
      created_date
      created_by
      last_updated_date
      last_updated_by
      provider_email_address
      provider_phone_number
      provider_name
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      provider_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicalShareHistory = /* GraphQL */ `
  mutation DeleteMedicalShareHistory(
    $input: DeleteMedicalShareHistoryInput!
    $condition: ModelMedicalShareHistoryConditionInput
  ) {
    deleteMedicalShareHistory(input: $input, condition: $condition) {
      id
      medical_record_id
      client_id
      provider_id
      npi_id
      session_id
      revision
      granted
      start_date
      end_date
      created_date
      created_by
      last_updated_date
      last_updated_by
      provider_email_address
      provider_phone_number
      provider_name
      client_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      provider_info {
        id
        first_name
        last_name
        date_of_birth
        city_of_birth
        nickname
        middle_name
        gender
        guardian_id_1
        guardian_id_2
        pcp_id
        ocp_id
        other_physicians
        other_name
        agree_terms_of_use
        date_agreed_terms_of_use
        agree_privacy_policy
        date_agreed_privacy_policy
        consent_of_telehealth
        date_consented_of_telehealth
        is_provider
        unique_identifier
        user_action
        created_date
        created_by
        last_update_date
        last_updated_by
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
