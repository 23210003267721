import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from "react-router-dom";


const SignOut = () => {
    const history = useHistory();
   


    useEffect(() => {
        console.log("LOGOUT TRIGGERED");
        sessionStorage.removeItem("chatLogs");
        sessionStorage.removeItem("chatLogsHistory");
        Auth.signOut();
    
        const location = {
            pathname: `/`,
            state: { from: '/signout'}
        }
        history.push(location);
        
        return () => {
        
        }
    }, [])
    
    return (
        <div>
                        
        </div>
    )
}

export default SignOut
