import React, { useState, useEffect } from 'react'
import 'materialize-css';
import { TextInput, Select, Tabs, Tab,Modal, Table, Preloader } from 'react-materialize';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import {initialLoginDataState, userCredentials} from '../../utils/initialstate.js'
import * as queries from '../../graphql/queries';
import logo from '../../assets/imgs/logo_64.png'

const User = (props) => {
	
  let loggedUser = initialLoginDataState;

  const [filter, setFilter] = useState('');
  const [searchInput, setSearchInput] = useState('');
  //const [role, setRole] = useState('');
  const [userList, setUserList] = useState([]);
  const [userListProvider, setUserListProvider] = useState([]);
  const history = useHistory();
  //const [ userLogState, setUserState ] = useState(initialLoginDataState);
  const [msgBox, setMsgBox] = useState({msg: "",header: "", show:false});
  //const [providersCount, setProvidersCount] = useState(0);
  
  const [loader, setLoader] = useState(false);
  const [proxyId, setProxyId] = useState("");
  const [action, setAction] = useState("");

  
  /*
  * GO BACK
  * 
  */
  const goBack = () => {
    if(typeof history.location.state !== 'undefined'){
      if(typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    }else{
      goHome();
    }
  }

  const goHome = () =>{
    history.push("/", { from: "/user" });
  }

  // const showMessage = (p_message,p_header) =>{  
  //   setMsgBox({msg: p_message,header: p_header, show: true})
  // }

  let nextToken
  const searchUsers = async(limit,filter,searchtext) => {
    setLoader(true);
    let apiName = 'AdminQueries';
    let path = '/listUsersWithFilter';
    let myInit = { 
        queryStringParameters: {
          "limit": limit,
          "searchfilter": filter,
          "searchtext": searchtext,
          "token": nextToken
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
    nextToken = NextToken;
    setLoader(false);
    return rest;
  }
  
  const enableDisableUser = async(p_username, p_option) => {
    let apiName = 'AdminQueries';
    let path = '';
    
    if(p_option === 'disableuser')
      path = '/disableUser'
    if(p_option === 'enableuser')
      path = '/enableUser'

    if(path === '') return

    let myInit = { 
        body: {
          "username": p_username
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    
    const rest  =  await API.post(apiName, path, myInit);
  
    return rest;
  }

  let tmpToken;

  const getInProcessProviders =  async () =>{
    setLoader(true);

    // console.log("checking providers...");
    let filter = document.getElementById("selFilter").value;
    
    // API.get('apiHygeia','/items/provider/inprocess',{
      
    //   queryStringParameters:{
    //     filterProvider: filter
    //   }
    
    // }).then((results)=>{
    //   console.log(results);
    //   setUserListProvider(results);
    // }).catch(err => {alert(err);}); 
   
    let respProviders = await API.graphql(
      graphqlOperation(queries.listProvidersInfos, {status: { eq: filter }, limit: 10, tmpToken})
    );

    if(respProviders.data.listProvidersInfos.items.length>0){
      setUserListProvider(respProviders.data.listProvidersInfos.items);
    }
    setLoader(false);
  }



  const handleClick = async () => {
    let searchFilter = filter;
    let searchText = searchInput;
    let res = await searchUsers(10,searchFilter,searchText);
    
    setUserList(res);
  }

  function findValueByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i].Value;
        }
    }
    return null;
  }
  
  const selectAction=async (e)=>{
    e.preventDefault();

    console.log(e.target.value);

    if(e.target.value ==='viewprofile'){
         console.log("Found");
         if(e.target.id ===""){
          alert("Profile not found")
          return;
         }

         sessionStorage.session_p = e.target.id;
         setProxyId(e.target.id);
    }

    if(e.target.value === 'disableuser'){
      let r = window.confirm("Are you sure to disable user! Yes (Press OK) / No (Press Cancel)");
      if (r === true) {
        e.target.getAttribute('clientusername')
        let username = e.target.getAttribute('clientusername')
        let result = await enableDisableUser(username,'disableuser')
        alert(result.message);
      
      } 
    }

    if(e.target.value === 'enableuser'){
      let r = window.confirm("Are you sure to enable user! Yes (Press OK) / No (Press Cancel)");
      if (r === true) {
        e.target.getAttribute('clientusername')
        let username = e.target.getAttribute('clientusername')
        let result = await enableDisableUser(username,'enableuser');
        alert(result.message);
       
        
      } 
    }
  } 


  const selectActionProvider1=(e)=>{
    sessionStorage.session_p = e.target.id;
    setProxyId(e.target.id);
      
  } 

  const renderTableData = () => {
    if(!userList.Users)return;
    return userList.Users.map((user, index) => {
      
      let resEmail =  findValueByKey(user.Attributes,"Name","email");
      let resFirstName =  findValueByKey(user.Attributes,"Name","given_name");
      let resLastName =  findValueByKey(user.Attributes,"Name","family_name");
      let resRole =  findValueByKey(user.Attributes,"Name","custom:role");
      let resClientID =  findValueByKey(user.Attributes,"Name","custom:clientid");
      
      console.log(resEmail);
      return (
        <tr key={user.Username}>
          <td>{user.Username}</td>    
          <td>{resLastName}</td>    
          <td>{resFirstName}</td>    
          <td>{resEmail}</td>    
          <td>{resRole}</td>   
          <td>{resClientID}</td>    
          <td>
          
          <Select
            id={resClientID}
            clientusername = {user.Username}
            multiple={false}
            noLayout={true}
			      name="Select Action"
			      className="input-field col s12"
            options={{
                classes: '',
                dropdownOptions: {
                alignment: 'left',
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                coverTrigger: true,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250
                }
            }}
            value = {action}
            onChange = {(e) =>{ selectAction(e) }}
            >
            <option value="">Select</option>
            <option value="disableuser">Disable</option>
            <option value="enableuser">Enable</option>
            <option value="viewprofile">View Profile</option>
          </Select>  

          </td> 

        </tr>
      )
   })
  }
  

  const renderTableDataProvider = () => {
    
    return userListProvider.map((user, index) => {
      
      if(user.client_info.is_provider==="true"){
      return (
     
        <tr key={user.client_id}>
          
          <td>{user.client_info.last_name}</td>    
          <td>{user.client_info.first_name}</td>    
          <td>{user.client_info.middle_name}</td>    
          
          <td>
          <span id ={user.client_id} style={{"cursor":"pointer"}} className="green-text" onClick ={(e)=>{ selectActionProvider1(e) }}> View Profile</span>

          
          </td> 

        </tr>
      )
    }
   })
  }

  useEffect( () => {
      const getCreds = async () => {
        sessionStorage.session_p = '';  
        loggedUser = await userCredentials('FROM PROFILE');
        //setUserState({...loggedUser}); 
        
        if(loggedUser.role!=='admin') history.push("/");
      }
      setAction("");
      getCreds()
    }, [])


    useEffect( () => {
      if(proxyId!==""){
         history.push({
           pathname: '/profile',
           state: { proxy: proxyId, frompath: '/user'}
         })
      }
    }, [proxyId] )

  return (
    <div>
    

    <div className="white">
      <nav>
        <div className="nav-wrapper hygeiabackground">
        <div>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goHome() }}>Home</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}} onClick={() => { goBack() }}>Back</a>
            <a href="#!" className="breadcrumb" style={{"paddingLeft": "10px"}}>Admin</a>
        </div>
        </div>
      </nav>
    
    {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}

	  <div style={{"margin":"20px"}}>
          <Tabs className="tab-demo z-depth-1">
            <Tab options={{ duration: 300,onShow: null,responsiveThreshold: Infinity,swipeable: false}}title="Clients">
              <div style={{"margin" : "10px"}}>
              <Select id="selCritera" label="Critera" multiple={false} name="criteria" 
			        className="input-field col s12"
              options={{
                  classes: '',
                  dropdownOptions: {
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250
                  }
              }}
              value=""
			        onChange={(e)=>{ setFilter(e.target.value)}}
              >
              <option value="">Select</option>
              <option value="email">Email</option>
              <option value="name">Name(Client ID)</option>
              <option value="phone_number">Phone Number</option>
              <option value="given_name">Given Name</option>
              <option value="family_name">Family Name</option>
              <option value="username">User Name (Case sensitive)</option>
              </Select>
		          <TextInput id="txtSearch" label="Search" onChange={(e) => {setSearchInput(e.target.value)}}/>
              <br></br>
            
              <a href="#!" className="smallbutton green" onClick={() => {  handleClick() }}>Search</a> 
              <br></br>
              <Table responsive={true} centered={false} className="left">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Legal Last Name</th>
                  <th>Legal First Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Client ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>	
                      {
                          renderTableData()
                      }
              </tbody>
              </Table>
              </div>
              
            </Tab>

            <Tab active options={{duration: 300,onShow: null,responsiveThreshold: Infinity,swipeable: false}}title="Providers">
              <div style={{"margin" : "10px"}}>
              <Select
             
              id="selFilter"
              label="Search in-process providers"
              multiple={false}
              name="user_role"
              className="input-field col s12"
              options={{
                  classes: '',
                  dropdownOptions: {
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250
                  }
              }}
              value=""
              onChange={(e)=>{ 
                //setRole(e.target.value) 
              }}
              >
              <option value="NEW">NEW</option>
              <option value="INACTIVE">INACTIVE</option>
              <option value="REJECTED">REJECTED</option>
              </Select>
              
              <br></br>
         
              <a href="#!" className="smallbutton green" onClick={() => {  getInProcessProviders() }}>Search</a> 
              <br></br>
              <div style={{"marginTop": "24px"}}>
              <Table responsive={true} centered={false} className="left">
              <thead>
                <tr>
                  <th>Legal Last Name</th>
                  <th>Legal First Name</th>
                  <th>Middle Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>	
                      {
                          renderTableDataProvider()
                      }
              </tbody>
              </Table>
              </div>
              
              </div>
            </Tab>
          </Tabs>
        
		
    
	  
	</div>
	</div>

          <Modal
            actions={[
              <a href="#!" className="smallbutton green" style={{"margin":"8px"}} onClick={() => { setMsgBox({msg:"",header:"", show: false}) }}>Close</a>
            ]}
            bottomSheet={false}
            fixedFooter={false}
           
            id="msgBox"
            open={msgBox.show}
            options={{
              dismissible: false,
              endingTop: '5%',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: '4%'
            }}>
            <img src={logo} alt="Hygeia"/>
            <div className="hygeiafontH1">{msgBox.header}</div>
            <h6> {msgBox.msg} </h6>
            
          </Modal>
  </div>
  )
}

export default User

